import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import Table from 'app/components/table';

import {OAuth2AccessTokenOperations} from './../../../state/ducks/oauth2/accessToken';

const UserSecurity = ( { user } ) => {

    /**
     * Take dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Search for access tokens
     */
    const search = useCallback( query => dispatch( OAuth2AccessTokenOperations.search( 'accessTokens', query) ), [ dispatch ] );

    /**
     * Get accessTokens blrf object
     */
    const accessTokens = useSelector( state => state.app.blrf.objects.accessTokens || state.app.blrf.defaultObject );

    /**
     * Get data and meta from accessTokens blrf object
     */
    const { data, meta } = useMemo( () => {
        return {
            data: accessTokens.data,
            meta: accessTokens.meta
        };
    }, [ accessTokens ]);

    /**
     * We have to change the query ONLY when user.id changes or query does not include user_id field.
     */
    const query = useMemo( () => {
        if (accessTokens.query.query === undefined || accessTokens.query.query.user_id !== user.id) {
            return { ...accessTokens.query, refs: 1, query: { ...accessTokens.query.query, user_id: user.id } };
        }
        return accessTokens.query;
    }, [ user.id, accessTokens.query ]);

    const fields = [
        {
            label: 'Id',
            name: 'id',
            width: 1,
        },
        {
            label: 'Client id',
            name: 'client_id',
            width: 2,
        },
        {
            label: 'User',
            name: 'user_id',
            width: 2
        },
        {
            label: 'Expiry datetime',
            name: 'expiry_datetime',
            searchable: false,
            width: 2
        },
        {
            label: 'Created by',
            name: 'created_by',
            width: 2
        }
    ];

    const renderRow = ({ id, client_id, User, expiry_datetime, created_by}, i) => ({
        key: id,
        cells: [
            `${id.substring(0, 8)}...${id.slice(-8)}`,
            client_id,
            `${User.name} (${User.id})`,
            expiry_datetime,
            created_by
        ]
    });

    return (
        <Table
            fields={fields}
            renderRow={renderRow}
            search={search}
            data={data}
            meta={meta}
            query={query} />
    );
};

UserSecurity.propTypes = {
    user: PropTypes.object.isRequired
};

export default UserSecurity;
