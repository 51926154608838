import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { default as MuiModal } from '@material-ui/core/Modal';

import Paper from '@material-ui/core/Paper';

import Draggable from 'react-draggable';

const DraggablePaper = props => {
    return (
        <Draggable
            handle='.dragHandle'>
            <Paper {...props}  style={{minWidth: '40%'}}/>
        </Draggable>
    );
};


const Modal = (
    {
        /**
         * Modal properties
         */
        keepMounted = false,
        /**
         * Function to call when modal is closed
         */
        onClose,
        /**
         * Content and content props of the modal
         */
        content,
        contentProps,
        /**
         * Button component to open the modal and its props
         */
        buttonComponent,
        buttonProps 
    }
) => {

    const [ open, setOpen ] = useState(false);

    const Content = content;
    const Button = buttonComponent;

    const changeOpenState = useCallback( () => setOpen( o => !o ), [ setOpen ] );

    const handleClose = useCallback( () => {
        setOpen( false );
        if (onClose) {
            onClose();
        }
    }, [ setOpen, onClose ])

    return (
        <Fragment>
            <Button {...buttonProps} onClick={changeOpenState} />
            <MuiModal
                style={{display:'flex',alignItems:'center',justifyContent:'center', minWidth: 500}}
                keepMounted={keepMounted}
                open={open}
                closeAfterTransition={true}
                onClose={handleClose}>
                <DraggablePaper>
                    <Content {...contentProps} closeModal={handleClose} />
                </DraggablePaper>
            </MuiModal>
        </Fragment>
    );
}

Modal.propTypes = {
    keepMounted: PropTypes.bool,
    onClose: PropTypes.func,
    content: PropTypes.func.isRequired,
    contentProps: PropTypes.object.isRequired,
    buttonComponent: PropTypes.object,
    buttonProps: PropTypes.object.isRequired
};

Modal.defaultProps = {
    keepMounted: false,
    onClose: null
};

export default Modal;
