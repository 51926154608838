import React from 'react'

const OrderUsersPage = () => {

    return (
        <h1>Users</h1>
    );
};

export default OrderUsersPage;
