import React, { lazy }  from 'react';
import Module from './../../app/module';
import utils from './../../app/utils';

import { pmWsMiddleware } from './state/middleware';

import SettingsIcon from '@material-ui/icons/SettingsApplications';
import PeopleIcon from '@material-ui/icons/People';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import GavelIcon from '@material-ui/icons/Gavel';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SecurityIcon from '@material-ui/icons/Security';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import WorkIcon from '@material-ui/icons/Work';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import CodeIcon from '@material-ui/icons/Code'
import LockIcon from '@material-ui/icons/Lock'

const baseBreadcrumb = {
    title: 'Admin',
    path: utils.getFullPath('admin')
};

const usersPath = utils.getFullPath('admin/users');
const baseUsersBreadcrumb = {
    title: 'Users',
    path: usersPath
}
const usersPage = {
    component: lazy(() => import('./views/pages/users')),
    title: 'Users',
    icon: <PeopleIcon />,
    path: usersPath,
    breadcrumbs: [
        baseBreadcrumb,
        baseUsersBreadcrumb
    ]
};

const userPage = {
    component: lazy(() => import('./views/pages/user')),
    title: 'User',
    icon: <PeopleIcon />,
    path: utils.getFullPath('admin/users/:action/:uid?'),
    breadcrumbs: [
        baseBreadcrumb,
        baseUsersBreadcrumb,
        {
            title: 'User'
        }
    ]
};

const realmsPath = utils.getFullPath('admin/realms');
const baseRealmsBreadcrumb  = {
    title: 'Realms',
    path: realmsPath
};
const realmsPage = {
    component: lazy(() => import('./views/pages/realms')),
    title: 'Realms',
    icon: <PermIdentityIcon />,
    path: realmsPath,
    breadcrumbs: [
        baseBreadcrumb,
        baseRealmsBreadcrumb
    ]
};

const realmPage = {
    component: lazy(() => import('./views/pages/realm')),
    title: 'Realm',
    icon: <PermIdentityIcon />,
    path: utils.getFullPath('admin/realms/:action/:rid?'),
    breadcrumbs: [
        baseBreadcrumb,
        baseRealmsBreadcrumb,
        {
            title: 'Realm'
        }
    ]
};

const rolesPath = utils.getFullPath('admin/roles');
const baseRolesBreadcrumb = {
    title: 'Roles',
    path: rolesPath
};
const rolesPage = {
    component: lazy(() => import('./views/pages/roles')),
    title: 'Roles',
    icon: <GavelIcon />,
    path: rolesPath,
    breadcrumbs: [
        baseBreadcrumb,
        baseRolesBreadcrumb
    ]
};

const rolePage = {
    component: lazy(() => import('./views/pages/role')),
    title: 'Role',
    icon: <GavelIcon />,
    path: utils.getFullPath('admin/roles/:action/:rid?'),
    breadcrumbs: [
        baseBreadcrumb,
        baseRolesBreadcrumb,
        {
            title: 'Role'
        }
    ]
};

const groupsPath = utils.getFullPath('admin/groups');
const baseGroupsBreadcrumb = {
    title: 'Groups',
    path: groupsPath
};
const groupsPage = {
    component: lazy(() => import('./views/pages/groups')),
    title: 'Groups',
    icon: <SupervisedUserCircleIcon />,
    path: groupsPath,
    breadcrumbs: [
        baseBreadcrumb,
        baseGroupsBreadcrumb
    ]
};

const groupPage = {
    component: lazy(() => import('./views/pages/group')),
    title: 'Group',
    icon: <SupervisedUserCircleIcon />,
    path: utils.getFullPath('admin/groups/:action/:gid?'),
    breadcrumbs: [
        baseBreadcrumb,
        baseGroupsBreadcrumb,
        {
            title: 'Group'
        }
    ]
};

const pmTasksPath = utils.getFullPath('admin/pm/tasks');

const basePmBreadcrumb = {
    title: 'PM',
    path: pmTasksPath
};

const pmTasksPage = {
    component: lazy(() => import('./views/pages/pm/tasks')),
    title: 'Tasks',
    icon: <ViewAgendaIcon />,
    path: pmTasksPath,
    breadcrumbs: [
        baseBreadcrumb,
        basePmBreadcrumb,
        {
            title: 'Tasks',
            path: pmTasksPath
        }
    ]
};

const pmTaskPage = {
    component: lazy(() => import('./views/pages/pm/task')),
    title: 'Task',
    icon: <ViewAgendaIcon />,
    path: utils.getFullPath('admin/pm/tasks/:action/:tid?'),
    breadcrumbs: [
        baseBreadcrumb,
        basePmBreadcrumb,
        {
            title: 'Task'
        }
    ]
};

const pmProcessesPath = utils.getFullPath('admin/pm/processes');
const basePmProcessesBreadcrumb = {
    title: 'Processes',
    path: pmProcessesPath
};
const pmProcessesPage = {
    component: lazy(() => import('./views/pages/pm/processes')),
    title: 'Processes',
    icon: <WorkIcon />,
    path: pmProcessesPath,
    breadcrumbs: [
        baseBreadcrumb,
        basePmBreadcrumb,
        basePmProcessesBreadcrumb
    ]
};

//OAuth2

// AccessToken
const oauth2AccessTokenPath = utils.getFullPath('admin/accessToken');
const baseOAuth2Breadcrumb = {
    title: 'OAuth2',
    path: oauth2AccessTokenPath
};

const OAuth2AccessTokensPage = {
    component: lazy(() => import('./views/pages/oauth2/accessTokens')),
    title: 'Access Tokens',
    icon: <VpnKeyIcon />,
    path: oauth2AccessTokenPath,
    breadcrumbs: [
        baseBreadcrumb,
        baseOAuth2Breadcrumb,
        {
            title: 'Access Tokens',
            path: oauth2AccessTokenPath
        }
    ]
};

const OAuth2AccessTokenPage = {
    component: lazy(() => import('./views/pages/oauth2/accessToken')),
    title: 'Access Token',
    icon: <VpnKeyIcon />,
    path: utils.getFullPath('admin/oauth2/accessToken/:action/:atid?'),
    breadcrumbs: [
        baseBreadcrumb,
        baseOAuth2Breadcrumb,
        {
            title: 'Access Token'
        }
    ]
};

//Refresh Token
const oauth2RefreshTokenPath = utils.getFullPath('admin/refreshToken');
const OAuth2RefreshTokensPage = {
    component: lazy(() => import('./views/pages/oauth2/refreshTokens')),
    title: 'Refresh Tokens',
    icon: <AutorenewIcon />,
    path: oauth2RefreshTokenPath,
    breadcrumbs: [
        baseBreadcrumb,
        baseOAuth2Breadcrumb,
        {
            title: 'Refresh Tokens',
            path: oauth2RefreshTokenPath
        }
    ]
};

const OAuth2RefreshTokenPage = {
    component: lazy(() => import('./views/pages/oauth2/refreshToken')),
    title: 'Refresh Token',
    icon: <AutorenewIcon />,
    path: utils.getFullPath('admin/oauth2/refreshToken/:action/:rtid?'),
    breadcrumbs: [
        baseBreadcrumb,
        baseOAuth2Breadcrumb,
        {
            title: 'Refresh Token'
        }
    ]
};

//Scope
const oauth2ScopePath = utils.getFullPath('admin/scope');

const OAuth2ScopesPage = {
    component: lazy(() => import('./views/pages/oauth2/scopes')),
    title: 'Scopes',
    icon: <GpsNotFixedIcon />,
    path: oauth2ScopePath,
    breadcrumbs: [
        baseBreadcrumb,
        baseOAuth2Breadcrumb,
        {
            title: 'Scopes',
            path: oauth2ScopePath
        }
    ]
};

const OAuth2ScopePage = {
    component: lazy(() => import('./views/pages/oauth2/scope')),
    title: 'Scope',
    icon: <GpsNotFixedIcon />,
    path: utils.getFullPath('admin/oauth2/scope/:action/:sid?'),
    breadcrumbs: [
        baseBreadcrumb,
        baseOAuth2Breadcrumb,
        {
            title: 'Scope'
        }
    ]
};

//Client
const oauth2ClientPath = utils.getFullPath('admin/oauth2/client');

const OAuth2ClientsPage = {
    component: lazy(() => import('./views/pages/oauth2/clients')),
    title: 'Clients',
    icon: <FingerprintIcon />,
    path: oauth2ClientPath,
    breadcrumbs: [
        baseBreadcrumb,
        baseOAuth2Breadcrumb,
        {
            title: 'Clients',
            path: oauth2ClientPath
        }
    ]
};

const OAuth2ClientPage = {
    component: lazy(() => import('./views/pages/oauth2/client')),
    title: 'Client',
    icon: <FingerprintIcon />,
    path: utils.getFullPath('admin/oauth2/client/:action/:cid?'),
    breadcrumbs: [
        baseBreadcrumb,
        baseOAuth2Breadcrumb,
        {
            title: 'Client'
        }
    ]
};

//Client
const oauth2AuthCodePath = utils.getFullPath('admin/oauth2/authCode');

const OAuth2AuthCodesPage = {
    component: lazy(() => import('./views/pages/oauth2/authCodes')),
    title: 'Auth Codes',
    icon: <CodeIcon />,
    path: oauth2AuthCodePath,
    breadcrumbs: [
        baseBreadcrumb,
        baseOAuth2Breadcrumb,
        {
            title: 'Auth Codes',
            path: oauth2AuthCodePath
        }
    ]
};

const OAuth2AuthCodePage = {
    component: lazy(() => import('./views/pages/oauth2/authCode')),
    title: 'Auth Code',
    icon: <CodeIcon />,
    path: utils.getFullPath('admin/oauth2/authCode/:action/:acid?'),
    breadcrumbs: [
        baseBreadcrumb,
        baseOAuth2Breadcrumb,
        {
            title: 'Auth Code'
        }
    ]
};

export default class adminModule extends Module {

    getReducer() {
        return null;
    }

    getMiddleware(){
        return pmWsMiddleware;
    }

    getDefaultUri() {
        return this.getUri() + '/users';
    }

    getNavbar(user) {
        if (user && user.access.admin) {
            return [
                {
                    title: baseBreadcrumb.title,
                    icon: <SettingsIcon />,
                    to: baseBreadcrumb.path
                },
            ];
        }
        return [];
    }

    getSidebar(user) {
        const ret = [];
        if (user && user.access.admin) {
            const { admin } = user.access;

            const authItems = [];

            if (admin.user) {
                authItems.push({
                    title: usersPage.title,
                    icon: usersPage.icon,
                    to: usersPage.path
                });
            }
            if (admin.realm) {
                authItems.push({
                    title: realmsPage.title,
                    icon: realmsPage.icon,
                    to: realmsPage.path
                });
            }
            if (admin.role) {
                authItems.push({
                    title: rolesPage.title,
                    icon: rolesPage.icon,
                    to: rolesPage.path
                });
            }
            if (admin.group) {
                authItems.push({
                    title: groupsPage.title,
                    icon: groupsPage.icon,
                    to: groupsPage.path
                });
            }
            if (authItems.length > 0) {
                ret.push({
                    title: 'Auth',
                    icon: <SecurityIcon />,
                    items: authItems
                });
            }

            const pmItems = [];
            if (admin['pm/task']) {
                pmItems.push({
                    title: pmTasksPage.title,
                    icon: pmTasksPage.icon,
                    to: pmTasksPage.path
                })
            }
            if (admin['pm/worker']) {
                pmItems.push({
                    title: pmProcessesPage.title,
                    icon: pmProcessesPage.icon,
                    to: pmProcessesPage.path
                })
            }

            if (pmItems.length > 0) {
                ret.push({
                    title: 'Process Mgr.',
                    icon: <BusinessCenterIcon />,
                    items: pmItems
                });
            }

            const oauth2Items = [];
            if (admin['oauth2/client']) { 
                oauth2Items.push({
                    title: OAuth2ClientsPage.title,
                    icon: OAuth2ClientsPage.icon,
                    to: OAuth2ClientsPage.path
                })
            }
            if (admin['oauth2/scope']) { 
                oauth2Items.push({
                    title: OAuth2ScopesPage.title,
                    icon: OAuth2ScopesPage.icon,
                    to: OAuth2ScopesPage.path
                })
            }
            if (admin['oauth2/authcode']) {
                oauth2Items.push({
                    title: OAuth2AuthCodesPage.title,
                    icon: OAuth2AuthCodesPage.icon,
                    to: OAuth2AuthCodesPage.path
                })
            }
            if (admin['oauth2/accesstoken']) {
                oauth2Items.push({
                    title: OAuth2AccessTokensPage.title,
                    icon: OAuth2AccessTokensPage.icon,
                    to: OAuth2AccessTokensPage.path
                })
            }
            if (admin['oauth2/accesstoken']) {
                oauth2Items.push({
                    title: OAuth2RefreshTokensPage.title,
                    icon: OAuth2RefreshTokensPage.icon,
                    to: OAuth2RefreshTokensPage.path
                })
            }
            
            if (oauth2Items.length > 0) {
                ret.push({
                    title: 'OAuth2',
                    icon: <LockIcon />,
                    items: oauth2Items
                });
            }
            

        }
        return ret;
    }

    getRoutes() {
        return [
            usersPage,
            userPage,
            realmsPage,
            realmPage,
            rolesPage,
            rolePage,
            groupsPage,
            groupPage,
            pmTasksPage,
            pmTaskPage,
            pmProcessesPage,
            OAuth2AccessTokensPage,
            OAuth2AccessTokenPage,
            OAuth2RefreshTokensPage,
            OAuth2RefreshTokenPage,
            OAuth2ScopesPage,
            OAuth2ScopePage,
            OAuth2ClientsPage,
            OAuth2ClientPage,
            OAuth2AuthCodesPage,
            OAuth2AuthCodePage
        ];
    }
};
