import React, { Fragment, useContext, useMemo } from 'react';

import OrderWizardContext from './context';

import { Link } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import Button from 'app/components/button';

import * as orderConst from './../../../../const/order';
import utils from 'app/utils';

const OrderWizardFinishStep = () => {

    const ctx = useContext( OrderWizardContext );

    const {
        loading,
        previousStep,
        order,
        processOrder,
        unprocessOrder
    } = ctx;

    return(
        <Card>
            <CardHeader
                title='Final step' />
            <CardContent>
                <Button
                    variant='contained'
                    color='primary'
                    disabled={order.status !== orderConst.S_WAIT}
                    onClick={ () => processOrder( order.id ) }>
                    Process order
                </Button>
                <Button
                    variant='outlined'
                    color='primary'
                    disabled={order.status !== orderConst.S_DONE}
                    onClick={ () => unprocessOrder( order.id ) }>
                    Unprocess order
                </Button>
            </CardContent>
            <CardActions>
                <Button
                    component={Link}
                    to={utils.getFullPath(`coin/orders/view/${order.id}`)}
                    color='primary'
                    variant='outlined'
                    disabled={loading}>
                    View order
                </Button>
            </CardActions>
        </Card>
    );
}

export default OrderWizardFinishStep;