import { default as types } from './types';
import { createReducer } from './../../utils';

const actionsMap = {

    /**
     * Tap into connected-react-router location change action to detect when
     * initial render was finished.
     */
    '@@router/LOCATION_CHANGE' : ( state, { type, payload } ) => {

        let wasFirstRender = state.wasFirstRender;
        if (wasFirstRender === false && payload.isFirstRendering === true) {
            wasFirstRender = true;
        }

        return {
            ...state,
            wasFirstRender
        };
    },

    /**
     * Started initializing authentication module
     */
    [ types.INIT ]: ( state ) => {
        return {
            ...state, 
            initializing: false
        };

    },

    [ types.USER_OK ]: ( state, { payload: { data } }) => {
        const user = Array.isArray(data) ? data.find(v => v) : undefined;
        if (user && user.id && user.id > 0) {
            return {
                ...state,
                user,
                authenticated: true,
                authenticating: false,
                error: null,
            }
        }
        return {
            ...state,
            user: null,
            authenticating: false,
            authenticated: false
        }
    },

    /**
     * We have started login action
     */
    [ types.LOGIN ]: ( state ) =>
        (
            {
                ...state,
                authenticating: true,
                error: null
            }
        ),

    /**
     * Login/Refresh OK action handler
     *
     * Payload will hold the following keys:
     *
     * token_type: Bearer
     * expires_in: How soon will the token expire (in seconds)
     * access_token: Access token it self
     * refresh_token: Refresh token
     * user: Whole user object, with notable properties as: 
     *  id: User Id
     *  name: Full name
     *  username: Username
     *  ... lot's of other user information ...
     */
    [ types.LOGIN_OK ]: ( state, { payload } ) => {
        const { token_type, expires_in, access_token, refresh_token, user } = payload;
        const expires = new Date();
        expires.setTime(expires.getTime() + payload.expires_in * 1000);

        window.localStorage.setItem('token_type', token_type);
        window.localStorage.setItem('expires_in', expires_in);
        window.localStorage.setItem('expires', expires.getTime());
        window.localStorage.setItem('access_token', access_token);
        window.localStorage.setItem('refresh_token', refresh_token);

        return {
            ...state, 
            authenticated: true,
            authenticating: false,
            user: user,
            error: null
        };
    },

    /**
     * There was an error while we were authenticating
     *
     */
    [ types.LOGIN_ERROR ]: ( state, { payload } ) => {
        window.localStorage.removeItem('token_type');
        window.localStorage.removeItem('expires_in');
        window.localStorage.removeItem('expires');
        window.localStorage.removeItem('access_token');
        window.localStorage.removeItem('refresh_token');
        return {
            ...state,
            authenticating: false,
            authenticated: false,
            user: null,
            error: (payload.response && payload.response.message ? payload.response.message : payload.message)
        };
    },

    [ types.LOGOUT_OK ]: ( state ) => {
        window.localStorage.removeItem('token_type');
        window.localStorage.removeItem('expires_in');
        window.localStorage.removeItem('expires');
        window.localStorage.removeItem('access_token');
        window.localStorage.removeItem('refresh_token');
        return {
            ...state,
            authenticating: false,
            authenticated: false,
            user: null,
            error: null
        };
    },

    [ types.PASSWORD_OK ]: ( state, { payload } ) => {
        return state;
    },

    [ types.PASSWORD_ERROR ]: ( state, { payload } ) => {
        return (
            {
                ...state,
                error: payload
            }
        );
    }

};

const initialState = {
    /**
     * Was isFirstRendering = true received by @@router/LOCATION_CHANGE action
     */
    wasFirstRender: false,
    /**
     * Are we initializing authentication ?
     */
    initializing: true,
    /**
     * Are we currently doing any 
     * authentication action (everything but login)
     */
    authenticating: false,
    /**
     * Are we authenticated?
     */
    authenticated: false,
    /**
     * User information
     */
    user: null,
    /**
     * Error message if any
     */
    error: null
};

export default createReducer( initialState ) ( actionsMap );