import React, { Fragment, useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { groupOperations, groupComponents } from './../../state/ducks/group';
import { userComponents } from './../../state/ducks/user';

import { Table } from 'app/components';

import { Link } from 'react-router-dom';

import Fab from '@material-ui/core/Fab';

import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';

import { openSnackbar } from 'app/components/snackbar';

import utils from 'app/utils';

const GroupsPage = () => {

    /**
     * Take dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Search for groups via Api
     */
    const search = useCallback( query => dispatch( groupOperations.search( 'groups', query ) ), [ dispatch ] );

    /**
     * Remove group via Api
     */
    const remove = useCallback( id => dispatch( groupOperations.remove( 'group', id ) ), [ dispatch ] );

    /**
     * Groups blrf object
     */
    const groupsObject = useSelector( state => state.app.blrf.objects.groups || state.app.blrf.defaultObject );

    const { data, meta } = useMemo( () => {
        return {
            data: groupsObject.data,
            meta: groupsObject.meta
        }
    }, [ groupsObject ]);

    const query = useMemo( () => {
        /**
         * When object is first created query will only contain { refs: 0, page: 1 }
         *
         * We want refs, so we change that. And we want to have default order.
         * All other aspects of query object is controlled via
         * Table component.
         */
         const hasOrder = Array.isArray(groupsObject.query.order) && groupsObject.query.order.length > 0;
         if (groupsObject.query.refs === 0 || !hasOrder) {
            return { ...groupsObject.query, refs: ['Owner', 'Parent'], order: (hasOrder ? [...groupsObject.query.order ] : ['name ASC'] ) };
         }
         return groupsObject.query;
    }, [ groupsObject.query ] );

    const {
        loading,
        error
    } = groupsObject;

    const fields = [
        {
            label: 'Id',
            name: 'id',
            width: 1,
            type: 'number'
        },
        {
            label: 'Name',
            name: 'name',
            width: 3,
        },
        {
            label: 'Description',
            name: 'description',
            width: 4,
            searchable: false
        },
        {
            label: 'Owner',
            name: 'owner_id',
            width: 4, 
            component: userComponents.usersSelectField,
        },
        {
            label: 'Parent',
            name: 'parent_id',
            searchable: true,
            width: 4,
            component: groupComponents.groupsSelectField,
            select: {
                multiple: false
            },
            sortable: false
        },
    ];

    const renderRow = ({id, name, description, Owner, Parent, }, i) => ({
        key: id,
        cells: [
            id,
            name,
            description,
            Owner ? Owner.name : '',
            Parent ? Parent.name : '',
        ]
    });

    const actions = [
        {
            icon: <DescriptionIcon />,
            component: Link,
            to: utils.getFullPath('admin/groups/view/:id')
        },
        {
            icon: <CreateIcon />,
            component: Link,
            to: utils.getFullPath('admin/groups/edit/:id')
        },
        {
            icon: <DeleteIcon />,
            onClick: ( data ) => removeGroup(data.id),
            confirm: {
                content: 'Are you sure you want to delete group :name?'
            }
        }
    ];

    const removeGroup = async ( id ) => {
        const ret = await remove( id );
        if (ret.type.indexOf('error') > 0) {
            openSnackbar('Failed to delete group', 'error');
        } else {
            openSnackbar('Group deleted', 'success');
            search( query );
        }
    }

    return (
        <Fragment>
            <Table
                fields={fields}
                renderRow={renderRow}
                actions={actions}
                loading={loading}
                search={search}
                query={query}
                data={data}
                meta={meta}
                error={error}
                />
            <Fab color='primary' style={{ float: 'right' }} variant='extended' component={Link} to={utils.getFullPath('admin/groups/add')}>
                <AddIcon />
                Add group
            </Fab>
        </Fragment>
    );
};

export default GroupsPage;
