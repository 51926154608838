import { default as moduleTypes } from './../ducks/module/types';
import { default as authTypes } from './../ducks/auth/types';

import { refresh as refreshToken, user as loadUser, authQueueProcess, authQueueFlush } from './../ducks/auth/operations';

import { initModules } from './../ducks/module/utils';

let userAuthenticated = false;

const appMiddleware = ( store ) => ( next ) => async ( action ) => {

    if (store.getState().app.auth.authenticated !== userAuthenticated) {
        userAuthenticated = store.getState().app.auth.authenticated;
        if (userAuthenticated) {
            store.dispatch({ type: authTypes.LOGIN_DONE });
        }
    }

    const n = new Date().getTime();
    const e = localStorage.getItem( 'expires' );
    const t = localStorage.getItem( 'refresh_token' );

    if (e !== null && t !== null && n >= e) {
        localStorage.removeItem( 'access_token' );
        localStorage.removeItem( 'expires' );
        localStorage.removeItem( 'refresh_token' );
        /**
         * When we refresh token, BLRF Api will start to queue all the actions
         * that require authentication. So basically refreshToken()
         * will set the app.auth.authenticating state to true and BLRF Api will
         * check this, queue actions and return Promise()'s so actions will wait for
         * completition. Once we know that refresh token was updated (action LOGIN_OK), 
         * we notify BLRF Api (all middlewares acctually), that queue is ready for processing.
         * Queue is processed by running the action it self and resolving the stored Promise
         * that was returned to the action. (see middleware/blrf.js)
         */
        const ret = await store.dispatch(refreshToken(t));
        if (ret.type === authTypes.LOGIN_OK) {
            store.dispatch( authQueueProcess() );
        } else {
            store.dispatch( authQueueFlush() );
        }
    }

    if (action.type) {
        if (action.type === moduleTypes.INIT) {
            initModules( store.getState().app.module.all );
        }
        if (action.type === authTypes.INIT) {
            if (window.localStorage.getItem( 'access_token' )) {
                await store.dispatch( loadUser() );
            }
        }
    }
    return next( action );
};

export default appMiddleware;
