import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';

import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';

import AppSidebarModuleItem from './moduleItem';
import AppSidebarModuleDropdown from './moduleDropdown';

const UserAvatar = ( { user } ) => {

    const initials = useMemo( () => {

        if (user && user.name) {
             const tmp = user.name.split(' ').map( n => n.charAt(0).toUpperCase()).join('');
            return tmp;
        } else {
            return '';
        }

    }, [ user ]);

    const title = useMemo( () => {
        return user && user.name ? user.name : '';
    }, [ user ] );

    return (
        <Tooltip title={title}>
            <Avatar>{initials}</Avatar>
        </Tooltip>
    );

}

const DefaultSidebarModule = ({module, user, classes, currentUri}) => (
    <Fragment>
        <div className={classes.toolbar} style={{ height: '64px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <UserAvatar user={user} />
        </div>
        <Divider />
        <List dense>
        {module ? 
            module.getSidebar(user).map((item, key) => (
                Array.isArray(item.items) ? 
                    <AppSidebarModuleDropdown key={key} currentUri={currentUri} {...item} />:
                    <AppSidebarModuleItem key={key} currentUri={currentUri} {...item} />
            )
        ) 
        : null}
        </List>
    </Fragment>
);

const AppSidebarModule = ({module, user, classes, currentUri}) => {
    const CustomSidebar = module && module.getCustomSidebar(user);
    return CustomSidebar === null ? 
        <DefaultSidebarModule
            module={module}
            user={user}
            classes={classes}
            currentUri={currentUri}
            /> :
        <CustomSidebar
            module={module}
            user={user}
            classes={classes}
            currentUri={currentUri}
            />;
}

AppSidebarModule.propTypes = {
    module: PropTypes.object,
    user: PropTypes.object,
    classes: PropTypes.object.isRequired
};

AppSidebarModule.defaultProps = {
    module: null,
    user: null,
};

export default AppSidebarModule;
