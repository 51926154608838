import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { authOperations } from 'app/state/ducks/auth'

import Grid from '@material-ui/core/Grid';

import AuthLogin from './auth/login';
import AuthControl from './auth/control';
import AuthState from './auth/state';

const AuthPage = () => {

    /**
     * Dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Our main loading indicator
     */
    const loading = useSelector( state => state.app.loading > 0);
    /**
     * Our application auth object
     */
    const authObject = useSelector( state => state.app.auth );

    /**
     * Get current user from OAuth2
     */
    const getUser = useCallback( () => dispatch( authOperations.user() ), [ dispatch ] );

    /**
     * Refresh token
     */
    const refreshToken = useCallback( () => {
        const t = window.localStorage.getItem('refresh_token') || null;
        if (t) {
            return dispatch( authOperations.refresh(t) );
        }
    }, [ dispatch ] );

    return (
        <Grid container spacing={2}>
            <Grid item md={6}>
                <AuthLogin
                    authObject={authObject}
                    loading={loading}
                />
            </Grid>
            <Grid item md={6}>
                <AuthControl
                    authObject={authObject}
                    loading={loading}
                    getUser={getUser}
                    refreshToken={refreshToken}
                />
            </Grid>
            <Grid item xs={12}>
                <AuthState />
            </Grid>
        </Grid>
    );
}

export default AuthPage;
