import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { pingOperations } from './../../../state/ducks/ping';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Slider from '@material-ui/core/Slider';

import { ResponsiveLine } from '@nivo/line'

import { openSnackbar } from 'app/components/snackbar';
import ErrorAlert from 'app/components/error';
import Button from 'app/components/button';
import useInterval from 'app/hooks/useInterval';

const PingChart = ( { t1Data, t2Data } ) => {
        return (
            <ResponsiveLine
                margin={{ top: 30, right: 50, bottom: 60, left: 50 }}
                data={[
                    {
                        id: 'T1',
                        data: t1Data
                    }, 
                    {
                        id: 'T2',
                        data: t2Data
                    }
                ]}
                xScale={{ type: 'time', format: 'native' }}
                yScale={{ type: 'linear', max: 2 }}
                axisTop={{
                    format: '%H:%M',
                    tickValues: 'every 1 minutes',
                }}
                axisBottom={{
                    format: '%H:%M',
                    tickValues: 'every 1 minutes',
                    legendPosition: 'middle',
                    legendOffset: 46,
                }}
                axisRight={{}}
                enablePoints={false}
                enableGridX={true}
                curve="monotoneX"
                animate={false}
                motionStiffness={120}
                motionDamping={50}
                isInteractive={false}
                enableSlices={false}
                useMesh={true}
                theme={{
                    axis: { ticks: { text: { fontSize: 14 } } },
                    grid: { line: { stroke: '#ddd', strokeDasharray: '1 2' } },
                }}
                />
        );
}

/**
 * Should provide single ping and ping with chart
 */
const MultiplePing = ({ auth = false }) => {

    /**
     * Dispatch from react-redux
     */
    const dispatch = useDispatch();

    const [ pingDelay, setPingDelay ] = useState(1000);

    /**
     * Timer
     */
    const [ running, setRunning ] = useState(false);

    const [ t1Data, setT1Data ] = useState([]);

    const [ t2Data, setT2Data ] = useState([]);

    const key = auth ? 'multiplePingAuth' : 'multiplePing';

    /**
     * Call ping api method
     */
    const ping = useCallback( ( id ) => dispatch( pingOperations.ping( key ) ), [ dispatch, key ] );
    const pingAuth = useCallback( ( id ) => dispatch( pingOperations.pingAuth( key ) ), [ dispatch, key ] );

    /**
     * Fetch ping blrf object. If object is not yet available return default blrf object stored in state.
     */
    const pingObject = useSelector( state => state.app.blrf.objects[key] || state.app.blrf.defaultObject );

    const {
        error
    } = pingObject;


    const sendPing = () => {

        const pingStart = new Date().getTime();

        const p = (auth ? pingAuth() : ping());

        p.then(ret => {
            if (ret.type) {
                if (ret.type.indexOf('error') > 0) {
                    openSnackbar(`Ping failed: ${ret.payload.error.message}`, 'error');
                    setRunning(false);
                } else {

                    const { pong } = ret.payload.data;
                    const totalTime = pong - (pingStart/1000);
                    const localTime = (new Date().getTime() - pingStart)/1000;


                    setT1Data( d => [ ...d.slice(-100), { x: new Date(), y: totalTime } ] );
                    setT2Data( d => [ ...d.slice(-100), { x: new Date(), y: localTime } ] );
                }
            }
        });
    }

    useInterval( () => {
        sendPing();
    }, ( running ? pingDelay : null) );

    const sliderValueText = ( value ) => {
        return `${value} ms`;
    };

    return (
        <Card variant='outlined'>
            <CardHeader title={`Send multiple pings ${auth ? 'with auth' :''}`} />
            <CardContent style={{ height: '400px'}}>
                <PingChart t1Data={t1Data} t2Data={t2Data} />
            </CardContent>
            {error && <CardContent><ErrorAlert error={error} /></CardContent>}
            <CardActions>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Button
                            onClick={() => setRunning(!running)}>
                            {running === false ? 'Start' : 'Stop'}
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Slider
                            value={pingDelay}
                            onChange={(ev, val) => setPingDelay(val)}
                            getAriaValueText={sliderValueText}
                            valueLabelDisplay='auto'
                            step={100}
                            marks={[
                                {
                                    value: 100,
                                    label: '100ms'
                                },
                                {
                                    value: 500,
                                    label: '500ms'
                                },
                                {
                                    value: 1000,
                                    label: '1s',
                                },
                                {
                                    value: 2000,
                                    label: '2s'
                                },
                                {
                                    value: 5000,
                                    label: '5s'
                                },
                            ]}
                            min={100}
                            max={5000}
                            />
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
}

MultiplePing.propTypes = {
    auth: PropTypes.bool
};

MultiplePing.defaultProps = {
    auth: false
};

export default MultiplePing;
