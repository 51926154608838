import React, { Fragment, useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { currencyOperations } from './../../state/ducks/currency';

import { Table } from 'app/components';

import { default as MuiLink } from '@material-ui/core/Link';

import { Link } from 'react-router-dom';

import Fab from '@material-ui/core/Fab';

import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';

import { openSnackbar } from 'app/components/snackbar';

import utils from 'app/utils';
import coinUtils from '../../utils';

const currenciesPage = () => {

    /**
     * Take dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Search for currencies via Api
     */
    const search = useCallback( query => dispatch( currencyOperations.search( 'currencies', query ) ), [ dispatch ] );

    /**
     * Remove currency via Api
     */
    const remove = useCallback( id => dispatch( currencyOperations.remove( 'currency', id ) ), [ dispatch ] );

    /**
     * currencies blrf object
     */
    const currenciesObject = useSelector( state => state.app.blrf.objects.currencies || state.app.blrf.defaultObject );

    const { data, meta } = useMemo( () => {
        return {
            data: currenciesObject.data,
            meta: currenciesObject.meta
        }
    }, [ currenciesObject ]);

    const query = useMemo( () => {
        /**
         * When object is first created query will only contain { refs: 0, page: 1 }
         *
         * We want refs, so we change that. And we want to have default currency.
         * All other aspects of query object is controlled via
         * Table component.
         */
         const hasOrder = Array.isArray(currenciesObject.query.order) && currenciesObject.query.order.length > 0;
         if (currenciesObject.query.refs !== 0 || !hasOrder) {
            return { ...currenciesObject.query, refs: 0, order: (hasOrder ? [...currenciesObject.query.currency ] : ['name DESC'] ) };
         }
         return currenciesObject.query;
    }, [ currenciesObject.query ] );

    const {
        loading,
        error
    } = currenciesObject;

    const fields = [
        {
            label: 'Id',
            name: 'id',
            width: 1,
            type: 'number'
        },
        {
            label: 'Sid',
            name: 'sid',
            width: 1
        },
        {
            label: 'Name',
            name: 'name',
            width: 4
        },
        {
            label: 'Symbol',
            name: 'symbol',
            width: 1
        },
        {
            label: 'Decimals',
            name: 'decimals',
            width: 1,
            type: 'number'
        }
    ];

    const renderRow = ({ id, sid, name, symbol, decimals }, i) => ({
        key: i,
        cells: [
            id,
            <MuiLink component={Link} to={utils.getFullPath(`coin/balance/currency/${id}`)}>{`${sid}`}</MuiLink>,
            name,
            symbol,
            decimals
        ]
    });

    return (
        <Table
            fields={fields}
            renderRow={renderRow}
            loading={loading}
            search={search}
            query={query}
            data={data}
            meta={meta}
            error={error}
            />
    );
};

export default currenciesPage;
