import React, { Fragment, useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { userOperations, userComponents } from './../../state/ducks/user';
import { roleComponents } from './../../state/ducks/role';
import { realmComponents } from './../../state/ducks/realm';

import { Table } from 'app/components';

import { Link } from 'react-router-dom';

import Fab from '@material-ui/core/Fab';

import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';

import { openSnackbar } from 'app/components/snackbar';

import utils from 'app/utils';

const UsersPage = () => {

    /**
     * Take dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Search for users via Api
     */
    const search = useCallback( query => dispatch( userOperations.search( 'users', query ) ), [ dispatch ] );

    /**
     * Remove user via Api
     */
    const remove = useCallback( id => dispatch( userOperations.remove( 'user', id ) ), [ dispatch ] );

    /**
     * Users blrf object
     */
    const usersObject = useSelector( state => state.app.blrf.objects.users || state.app.blrf.defaultObject );

    const { data, meta } = useMemo( () => {
        return {
            data: usersObject.data,
            meta: usersObject.meta
        }
    }, [ usersObject ]);

    const query = useMemo( () => {
        /**
         * When object is first created query will only contain { refs: 0, page: 1 }
         *
         * We want refs, so we change that. And we want to have default order.
         * All other aspects of query object is controlled via
         * Table component.
         */
         const hasOrder = Array.isArray(usersObject.query.order) && usersObject.query.order.length > 0;
         if (usersObject.query.refs === 0 || !hasOrder) {
            return { ...usersObject.query, refs: ['Realm', 'Roles', 'Parent', 'Role'], order: (hasOrder ? [...usersObject.query.order ] : ['name ASC'] ) };
         }
         return usersObject.query;
    }, [ usersObject.query ] );

    const {
        loading,
        error
    } = usersObject;

    const fields = [
        {
            label: 'Id',
            name: 'id',
            width: 1,
            type: 'number'
        },
        {
            label: 'Name',
            name: 'name',
            width: 2,
        },
        {
            label: 'Username',
            name: 'username',
            width: 2, 
        },
        {
            label: 'Realm',
            name: 'realm_id',
            width: 2,
            component: realmComponents.realmsSelectField,
            sortable: false
        },
        {
            label: 'Roles',
            name: 'role_id',
            width: 2,
            component: roleComponents.rolesSelectField,
            sortable: false,
        },
        {
            label: 'Parent',
            name: 'parent_id',
            width: 3,
            component: userComponents.usersSelectField,
        },
    ];

    const renderRow = ({id, name, username, Realm, Roles, Parent}, i) => ({
        key: id,
        cells: [
            id,
            name,
            username,
            Realm ? Realm.name : '',
            Roles ? Roles.map(r => r.Role.name).join(', ') : '',
            Parent ? Parent.name : '',
        ]
    });

    const removeUser = async ( id ) => {
        const ret = await remove( id );
        if (ret.type) {
            if (ret.type.indexOf('error') > 0) {
                openSnackbar('Failed to delete user', 'error');
            } else {
                openSnackbar('User deleted', 'success');
                search( query );
            }
        }
    }

    const actions = [
        {
            icon: <DescriptionIcon />,
            component: Link,
            to: utils.getFullPath('admin/users/view/:id')
        },
        {
            icon: <CreateIcon />,
            component: Link,
            to: utils.getFullPath('admin/users/edit/:id')
        },
        {
            icon: <DeleteIcon />,
            onClick: ( data ) => removeUser(data.id),
            confirm: {
                content: 'Are you sure you want to delete user :name?'
            }
        }
    ];

    return (
        <Fragment>
            <Table
                fields={fields}
                renderRow={renderRow}
                actions={actions}
                loading={loading}
                search={search}
                query={query}
                data={data}
                meta={meta}
                error={error}
                />
            <Fab color='primary' style={{ float: 'right' }} variant='extended' component={Link} to={utils.getFullPath('admin/users/add')}>
                <AddIcon />
                Add user
            </Fab>
        </Fragment>
    );
};

export default UsersPage;
