import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import * as Yup from 'yup';

import { openSnackbar } from 'app/components/snackbar';

import { orderOperations } from './../../../state/ducks/order';
import * as orderConst from './../../../const/order';

import ViewEdit from 'app/components/viewedit';

import Grid from '@material-ui/core/Grid';

import ErrorAlert from 'app/components/error';
import LinearProgress from '@material-ui/core/LinearProgress';

import utils from 'app/utils';
import coinUtils from '../../../utils';

import OrderUsersPage from './view/users';
import OrderTransactionsPage from './view/transactions';


/**
 * Extract action and oid from react-router match prop
 */
const ViewOrderPage = ( { order, loading } ) => {

    const fields = [
            {
                label: 'Id',
                name: 'id',
                disabled: true,
                value: order.id,
                width: 1
            },
            {
                label: 'Type',
                name: 'type',
                width: 5,
                value: orderConst.orderType[order.type].name
            },
            {
                label: 'Status',
                name: 'status',
                width: 6,
                value: orderConst.orderStatus[order.status].name
            },

            {
                label: 'Src. Exchange',
                name: 's_exchange_id',
                width: 4,
                value: order && order.SourceExchange ? order.SourceExchange.name : ''
            },

            {
                label: 'Src. Currency',
                name: 's_currency_id',
                width: 4,
                value: order && order.SourceCurrency ? order.SourceCurrency.name : ''
            },
            {
                label: 'Src. Amount',
                name: 's_amount',
                width: 4,
                value: order && order.SourceCurrency ? coinUtils.formatCurrency(order.SourceCurrency, order.s_amount) : 0
            },

            {
                label: 'Dst. Exchange',
                name: 'd_exchange_id',
                width: 4,
                value: order && order.DestinationExchange ? order.DestinationExchange.name : ''
            },

            {
                label: 'Dst. Currency',
                name: 'd_currency_id',
                width: 4,
                value: order && order.DestinationCurrency ? order.DestinationCurrency.name : ''
            },
            {
                label: 'Dst. Amount',
                name: 'd_amount',
                width: 4,
                value: order && order.DestinationCurrency ? coinUtils.formatCurrency(order.DestinationCurrency, order.d_amount) : 0
            },

            {
                label: 'Comment',
                name: 'comment',
                width: 12,
                value: order.comment
            },

            {
                label: 'Created at',
                name: 'created_at',
                value: order.created_at,
                width: 3,
                edit: false
            },
            {
                label: 'Created by',
                name: 'created_by',
                value: order.created_by,
                width: 3,
                edit: false,
            },
            {
                label: 'Updated at',
                name: 'updated_at',
                value: order.updated_at,
                width: 3,
                edit: false,
            },
            {
                label: 'Updated by',
                name: 'updated_by',
                value: order.updated_by,
                width: 3,
                edit: false
            }
        ];

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ViewEdit
                    header='View order'
                    fields={fields}
                    loading={loading}
                    edit={false}
                    />
            </Grid>
            <Grid item xs={12}>
                <OrderUsersPage order={order} />
            </Grid>
            <Grid item xs={12}>
                <OrderTransactionsPage order={order} />
            </Grid>
        </Grid>
    );
}

ViewOrderPage.propTypes = {
    order: PropTypes.object.isRequired
};

export default ViewOrderPage;
