import React from 'react';

import { userComponents } from './../../../../../state/ducks/user';
import { orderOperations } from './../../../../../state/ducks/order';

import ViewEdit from 'app/components/viewedit';
import CloseIcon from '@material-ui/icons/Close';

import { openSnackbar } from 'app/components/snackbar';

import * as Yup from 'yup';

import coinUtils from './../../../../../utils';

const formValidation = Yup.object().shape({
    order_id: Yup.number().required(),
    user_id: Yup.array().of(Yup.number()).min(1)
});

const OrderUsersFill = ({ order, fillOrderUsers, closeModal }) => {

    const fields = [
        {
            id: 'order_id',
            name: 'order_id',
            label: 'Order',
            value: order.id,
            disabled: true,
            width: 1
        },
        {
            id: 'user_id',
            name: 'user_id',
            label: 'User',
            component: userComponents.usersSelectField,
            select: {
                multiple: true
            },
            value: 0,
            width: 11
        }
    ]

    const onSubmit = (values) => {
        const p = fillOrderUsers(values.order_id, values.user_id);
        p.then(ret => {
            if (ret.type.indexOf('error') > 0) {
                openSnackbar('Error while adding users to order', 'error');
            } else {
                openSnackbar('User added to order', 'success');
                closeModal();
            }
        } );
        return p;
    }

    return (
        <ViewEdit
            header={{ className: 'dragHandle', style: { cursor: 'move' }, title: 'Add users'}}
            fields={fields}
            onSubmit={onSubmit}
            edit={true}
            validationSchema={formValidation}
            backButton={{
                onClick: closeModal,
                content: 'Close',
                startIcon: <CloseIcon />
            }}
        />
    );
}

export default OrderUsersFill;
