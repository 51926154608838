import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import * as operations from './operations';

import { connectedSelectField } from './../../../../../app/components/form/field/select';

function rolesSelectImplementation ( WrappedComponent ) {
    return class extends Component {

        onSearchEvent = ( searchQuery ) => {
            const { search } = this.props;
            return search({
                refs: 0,
                query: {
                    name: `${searchQuery}*`
                }
            }, false);
        };

        map = ( role ) => ({ key: role.id, value: role.id, label: role.name });

        render( ) {
            const { select, placeholder='Search roles by name ...', ...props } = this.props;
            const default_select = {
                map: this.map,
                multiple: false,
                onSearchEvent: this.onSearchEvent,
                ...select
            };
            return <WrappedComponent
                        select={default_select}
                        placeholder={placeholder}
                        {...props}
                    />;
        }
    };
}

const mapDispatchToProps =  ( dispatch, ownProps ) => {
    const { blrfKey = 'roles_select' } = ownProps;
    return bindActionCreators(
        {
            search: (query) => operations.search(blrfKey, query),
        }, dispatch);
};

const mapStateToProps = ( state, ownProps ) => {
    const { blrfKey = 'roles_select' } = ownProps;
    const { loading = false, error = false, query, data = []} = state.app.blrf.objects[blrfKey] || state.app.blrf.defaultObject;
    return {
        loading, 
        query, 
        data,
        error: (error !== false ? error && error.message : false)
    }
};

const rolesSelectField = connect(mapStateToProps, mapDispatchToProps)(rolesSelectImplementation(connectedSelectField()));

export {
    rolesSelectField
};



