import React, { Fragment, useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { OAuth2AccessTokenOperations } from './../../../state/ducks/oauth2/accessToken';
import { userComponents } from './../../../state/ducks/user';
import { OAuth2ClientComponents } from './../../../state/ducks/oauth2/client';

import { Table } from 'app/components';

import { Link } from 'react-router-dom';

import Fab from '@material-ui/core/Fab';

import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';

import { openSnackbar } from 'app/components/snackbar';

import utils from 'app/utils';

const OAuth2AccessTokenspage = () => {

    /**
     * Take dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Search for accessTokens via Api
     */
    const search = useCallback( query => dispatch( OAuth2AccessTokenOperations.search( 'accessTokens', query ) ), [ dispatch ] );

    /**
     * Remove accessTokens via Api
     */
    const remove = useCallback( id => dispatch( OAuth2AccessTokenOperations.remove( 'accessToken', id ) ), [ dispatch ] );

    /**
     * OAuth2AccesssTokens blrf object
     */
    const accessTokensObject = useSelector( state => state.app.blrf.objects.accessTokens || state.app.blrf.defaultObject );

    const { data, meta } = useMemo( () => {
        return {
            data: accessTokensObject.data,
            meta: accessTokensObject.meta
        }
    }, [ accessTokensObject ]);

    const query = useMemo( () => {
        /**
         * When object is first created query will only contain { refs: 0, page: 1 }
         *
         * We want refs, so we change that. And we want to have default order.
         * All other aspects of query object is controlled via
         * Table component.
         */
        const hasOrder = Array.isArray(accessTokensObject.query.order) && accessTokensObject.query.order.length > 0;
        if (!Array.isArray(accessTokensObject.query.refs) || !hasOrder) {
            return { ...accessTokensObject.query, refs: ['Client', 'User'], order: (hasOrder ? [...accessTokensObject.query.order ] : ['expiry_datetime DESC'] ) };
        }
        return accessTokensObject.query;
    }, [ accessTokensObject.query ] );

    const {
        loading,
        error
    } = accessTokensObject;

    const fields = [
        {
            label: 'Id',
            name: 'id',
            width: 1,
        },
        {
            label: 'Client id',
            name: 'client_id',
            component: OAuth2ClientComponents.clientsSelectField,
            width: 3,
        },
        {
            label: 'User',
            name: 'user_id',
            component: userComponents.usersSelectField,
            width: 3
        },
        {
            label: 'Expiry datetime',
            name: 'expiry_datetime',
            searchable: false,
            width: 2
        },
        {
            label: 'Created by',
            name: 'created_by',
            width: 2
        }
    ];

    const renderRow = ({ id, Client, User, expiry_datetime, created_by }, i) => ({
        key: id,
        cells: [
            id.substring(0, 10) + '...' + id.slice(id.length - 10),
            Client.name,
            User.name,
            expiry_datetime,
            created_by
        ]
    });

    const actions = [
        {
            icon: <DescriptionIcon />,
            component: Link,
            to: utils.getFullPath('admin/oauth2/accessToken/view/:id')
        },
        {
            icon: <CreateIcon />,
            component: Link,
            to: utils.getFullPath('admin/oauth2/accessToken/edit/:id')
        },
        {
            icon: <DeleteIcon />,
            onClick: ( data ) => removeAccessToken(data.id),
            confirm: {
                content: 'Are you sure you want to delete Access Token :name ?'
            }
        }
    ];

    const removeAccessToken = async( id ) => {
        const ret = await remove( id );
        if (ret.type.indexOf('error') > 0) {
            openSnackbar('Failed to delete Access Token', 'error');
        } else {
            openSnackbar('AccessToken deleted', 'success');
            search( query );
        }
    }

    return (
        <Fragment>
            <Table
                fields={fields}
                renderRow={renderRow}
                actions={actions}
                loading={loading}
                search={search}
                query={query}
                data={data}
                meta={meta}
                error={error}
            />
            <Fab color='primary' style={{ float: 'right' }} variant='extended' component={Link} to={utils.getFullPath('admin/oauth2/accessToken/add')}>
                <AddIcon />
                Add Access Token
            </Fab>
        </Fragment>
    );
};

export default OAuth2AccessTokenspage;
