import React, { Fragment } from 'react';

import Alert from '@material-ui/lab/Alert';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import RefreshIcon from '@material-ui/icons/Refresh';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import Button from 'app/components/button';

const AuthObjectInfo = ( { authObject } ) => {

    return (
        <Table size='small'>
            <TableHead>
                <TableRow>
                    <TableCell>Key</TableCell>
                    <TableCell>Value</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>Initializing</TableCell>
                    <TableCell>{authObject.initializing ? 'Yes' : 'No'}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Was first render</TableCell>
                    <TableCell>{authObject.wasFirstRender ? 'Yes' : 'No'}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Authenticating</TableCell>
                    <TableCell>{authObject.authenticating ? 'Yes' : 'No'}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Authenticated</TableCell>
                    <TableCell>{authObject.authenticated ? 'Yes' : 'No'}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Error</TableCell>
                    <TableCell>{authObject.error}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell>{authObject.user ? `${authObject.user.name} (${authObject.user.id})` : 'n/a'}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );

};

const AuthControl = ({
    authObject,
    loading,
    getUser,
    refreshToken,
    logout
}) => {

    const { authenticated } = authObject;

    return (
        <Card>
            <CardHeader title='Authenticated actions' />
            { authenticated 
                ?   <Fragment>
                        <CardContent>
                            <AuthObjectInfo authObject={authObject} />
                        </CardContent>
                        <CardActions style={{ justifyContent: 'center' }}>
                            <Button
                                startIcon={<AccountCircleIcon />}
                                variant='outlined'
                                onClick={getUser}
                                loading={loading}>
                                User info
                            </Button>
                            <Button
                                startIcon={<RefreshIcon />}
                                variant='outlined'
                                onClick={refreshToken}
                                loading={loading}>
                                Refresh token
                            </Button>
                            <Button
                                startIcon={<ExitToAppIcon />}
                                variant='outlined'
                                onClick={logout}
                                loading={loading}>Logout
                            </Button>
                        </CardActions>
                    </Fragment>
                :
                    <CardContent>
                        <Alert severity='warning'>You are not logged in!</Alert>
                    </CardContent>
            }
        </Card>
    );

};

export default AuthControl;
