import React, { useMemo } from 'react';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ViewEdit from 'app/components/viewedit';
import Table from 'app/components/table';

import coinUtils from '../../../../../utils';

const ExchangeData = ( { currency, data: { exchange, data, total } } ) => {

    const eur = {
        sid: 'EUR',
        decimals: 2
    };

    const fields = [
        {
            id: 'amount',
            name: 'amount',
            label: 'Amount',
            value: coinUtils.formatCurrency(currency, total.amount)
        },
        {
            id: 'amount_eur',
            name: 'amount_eur',
            label: 'Amount EUR',
            value: coinUtils.formatCurrency(eur, total.amount_eur)
        },
        {
            id: 'rate_eur',
            name: 'rate_eur',
            label: 'Rate EUR',
            value: coinUtils.formatCurrency(eur, total.rate_eur)
        },
        {
            id: 'rate_eur_24h',
            name: 'rate_eur_24h',
            label: 'Rate EUR (24h)',
            value: coinUtils.formatCurrency(eur, total.rate_eur_24h)
        },
        {
            id: 'rate_eur_change_24h',
            name: 'rate_eur__change24h',
            label: 'Rate Change EUR (24h)',
            value: coinUtils.formatPercent(total.rate_eur_change_24h)
        }
    ];

    const tableFields = [
        {
            id: 'user_id',
            name: 'user_id',
            label: 'User'
        },
        {
            id: 'amount',
            name: 'amount',
            label: 'Amount'
        },
        {
            id: 'amount_eur',
            name: 'amount_eur',
            label: 'Amount EUR'
        }
    ];

    const renderRow = ({ User, amount, amount_eur }, i) => ({
        key: i,
        cells: [
            User.name,
            coinUtils.formatCurrency(currency, amount),
            coinUtils.formatCurrency(eur, amount_eur)
        ]
    });

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        title={exchange.name}/>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <ViewEdit
                                    variant='outlined'
                                    fields={fields}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Table
                                    variant='outlined'
                                    data={data}
                                    fields={tableFields}
                                    renderRow={renderRow}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}


const CurrencyDataExchange = ({ currency, data }) => {

    console.log(' >>> data: ', data);

    const d = useMemo( () => {

        const exData = [];

        data.forEach( d => {
            const exId = d.Exchange.id;
            if (!exData[exId]) {
                exData[exId] = {
                    exchange: d.Exchange,
                    data: [],
                    total: {
                        amount: 0,
                        amount_eur: 0,
                        amount_eur_24h: 0,
                        rate_eur: d.rate_eur,
                        rate_eur_24h: d.rate_eur_24h,
                        rate_eur_change_24h: d.rate_eur_change_24h
                    }
                };
            }
            exData[exId].data.push(d);
            exData[exId].total.amount += parseFloat(d.amount);
            exData[exId].total.amount_eur += parseFloat(d.amount_eur);
            exData[exId].total.amount_eur_24h += parseFloat(d.amount_eur_24h);
        });

        return exData;

    }, [ data ] );

    return d.map( d => <ExchangeData currency={currency} data={d} key={d.exchange.id} /> );
}

export default CurrencyDataExchange;
