import React, { useMemo } from 'react';

import Tabs from 'app/components/tabs';

import CurrencyDataExchange from './data/exchange';
import CurrencyDataUser from './data/user';

const CurrencyData = ({ currency, data }) => {

    const panes = useMemo( () => {
        return [
            {
                tab: { label: 'Exchange' },
                content:
                    <CurrencyDataExchange
                        currency={currency}
                        data={data}
                    />
            },
            {
                tab: { label: 'User' },
                content:
                    <CurrencyDataUser
                        currency={currency}
                        data={data}
                    />
            }
        ];
    }, [ currency, data ]);

    return (
        <Tabs
            panes={panes}
        />
    );
}

export default CurrencyData;
