import types from './types';

import { BLRF_API } from 'app/state/middleware/blrf';

const objectToQuerystring = (obj) => (
    Object.keys(obj).reduce(function (str, key, i) {
        var delimiter, val;
        delimiter = (i === 0) ? '?' : '&';
        key = encodeURIComponent(key);
        val = encodeURIComponent(obj[key]);
        return [str, delimiter, key, '=', val].join('');
    }, '') 
);

export default {

    get: ( { key, endpoint = '', id = '', data = {}, props = {}, auth = true, method = 'get' } ) => ( {
        [ BLRF_API ] : {
            key, 
            endpoint: `${endpoint}/${id}${objectToQuerystring(data)}`,
            method: 'get',
            auth, 
            props, 
            types: [ types.GET, types.GET_OK, types.GET_ERROR ]
        }
    } ),

    create: ( { key, endpoint = '', data = {}, props= {}, auth = true, method = 'post' } ) => ( {
        [ BLRF_API ] : {
            key,
            endpoint,
            method,
            data,
            props,
            auth,
            types: [ types.CREATE, types.CREATE_OK, types.CREATE_ERROR ]
        }
    } ),

    update: ( { key, endpoint = '', data = {}, props = {}, auth = true, method = 'put' } ) => ( {
        [ BLRF_API ] : {
            key,
            endpoint,
            method,
            data,
            props, 
            auth, 
            types: [ types.UPDATE, types.UPDATE_OK, types.UPDATE_ERROR ]
        }
    } ),

    remove: ( { key, endpoint = '', auth = true, method = 'delete' } ) => ( {
        [ BLRF_API ] : {
            key,
            endpoint,
            method,
            auth,
            types: [ types.REMOVE, types.REMOVE_OK, types.REMOVE_ERROR ]
        }
    } ),

    search: ( { key, endpoint = '', data = {}, props= {}, auth = true, method = 'post' } ) =>  ( {
        [ BLRF_API ]: {
            key, 
            endpoint,
            method,
            data,
            props,
            auth,
            types: [ types.SEARCH, types.SEARCH_OK, types.SEARCH_ERROR ]
        }
    }),

    updateSingle: ( key, data, prop = 'id' ) => ({
        type: types.UPDATE_SINGLE,
        payload: {
            key, 
            data,
            prop
        }
    }),

    clear: ( key ) => ({
        type: types.CLEAR,
        payload: {
            key
        }
    }),

};