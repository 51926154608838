import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { AppNavbarModules, AppNavbarTheme, AppNavbarUser, AppNavbarWsConnections } from './navbar/index';

import Breadcrumbs from './navbar/breadcrumbs';

export default class AppNavbar extends Component {

    static propTypes = {
        modules: PropTypes.array,
        module: PropTypes.object,
        user: PropTypes.object,
        wsConnections: PropTypes.object,
        logout: PropTypes.func.isRequired,
        loading: PropTypes.number,
        authenticating: PropTypes.bool.isRequired,
        handleDrawerToggle: PropTypes.func.isRequired
    };

    static defaultProps = {
        module: null,
        modules: null,
        wsConnections: {},
        user: null,
        loading: 0,
    };

    // {module && capitalize(module.getName())}

    render() {
        const { classes, handleDrawerToggle, modules, module, wsConnections, user, authenticating, logout, loading } = this.props;
        return (
            <AppBar className={classes.appBar} position='static'>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}
                        className={classes.navIconHide}
                    >
                        <MenuIcon />
                    </IconButton>

                    <AppNavbarModules modules={modules} user={user} />

                    <Typography variant="h6" color="inherit" noWrap style={{ flex: 1 }}>
                        <Breadcrumbs module={module} />
                    </Typography>

                    { loading > 0 && <CircularProgress thickness={6} color="inherit" size={20} />}
                    
                    <AppNavbarWsConnections connections={wsConnections} />

                    <AppNavbarUser user={user} logout={logout} authenticating={authenticating} />

                    <AppNavbarTheme />
                </Toolbar>
            </AppBar>
        );
    }
}
