import types from './types';

export default {

    load: () =>  ({
        type: types.LOAD
    }),

    init: () => ({
        type: types.INIT
    })

};
