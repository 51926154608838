import React, { Component, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';

import { Route, Redirect, Switch } from 'react-router-dom';

import LinearProgress from '@material-ui/core/LinearProgress';

import routes from './../../routes';
import utils from './../../utils';
import config from './../../../config';

const loginPage = lazy(() => import('./../pages/login'));
const notFoundPage = lazy(() => import('./../pages/notfound'));

export default class AppRoutes extends Component {

    static propTypes = {
        authenticating: PropTypes.bool.isRequired,
        module: PropTypes.object,
        user: PropTypes.object,
        currentUri: PropTypes.string,
    };

    static defaultProps = {
        module: null,
        user: null,
        currentUri: null
    };

    getRoutes() {
        const { module } = this.props;

        if (module !== null) {
            return routes.concat(module.getRoutes());
        }
        else {
            return routes;
        }
    }

    renderRedirectRoute() {
        const { currentUri, authenticating, wasFirstRender } = this.props;
        if (wasFirstRender === true && currentUri !== null && authenticating === false && config.defaultUri && currentUri === config.uri) {
            return <Redirect from={config.uri} to={utils.getFullPath(config.defaultUri)} />;
        }
        return null;
    }

    renderModuleRedirectRoute() {
        const { authenticating, module, currentUri, wasFirstRender } = this.props;
        if (wasFirstRender === true && currentUri !== null && authenticating === false && module !== null && currentUri === module.getUri() && module.getUri() !== module.getDefaultUri()) {
            return <Redirect from={module.getUri()} to={module.getDefaultUri()} />
        }
        return null;
    }

    render() {
        const { user, wasFirstRender } = this.props;
        return (
            <Suspense fallback={<LinearProgress />}>
                <Switch>
                    {this.renderRedirectRoute()}
                    {this.renderModuleRedirectRoute()}
                    {this.getRoutes().map( ({auth = true, exact = true, breadcrumb, ...route}) => 
                        {
                            if (auth === false || user) {
                                return (<Route key={route.path} exact={exact} {...route} />);
                            }
                            return (
                                <Route key='login' path={route.path} exact component={loginPage} />
                            );
                        }
                        )
                    }
                    { wasFirstRender ? <Route component={notFoundPage} /> : <LinearProgress /> }
                </Switch>
            </Suspense>
        );
    }
}
