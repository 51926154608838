import React from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import SinglePing from './ping/single';
import MultiplePing from './ping/multiple';

/**
 * Should provide single ping and ping with chart
 */
const PingPage = () => {

    return (
        <Card>
            <CardHeader title='Ping API' />
            <CardContent>
                <SinglePing />
            </CardContent>
            <CardContent>
                <MultiplePing />
            </CardContent>
            <CardContent>
                <MultiplePing auth={true} />
            </CardContent>
        </Card>
    );
}

export default PingPage;
