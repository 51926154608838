/**
 * app/module/* actions
 */
export default {

    /**
     * Load all modules
     */
    LOAD: 'app/module/load',
    /**
     * After load we call init to load modules' reducers
     * into store.
     */
    INIT: 'app/module/init'

};
