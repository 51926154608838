import { blrfOperations } from 'app/state/ducks/blrf';

const { clear } = blrfOperations;

const get = ( key, roleId ) => ( dispatch ) => {

    dispatch ( blrfOperations.get( {
        endpoint: `admin/resource/${roleId}`,
        key,
    }) );
    
};

const update = ( key, roleId, data ) => async ( dispatch ) => {

    return await dispatch ( blrfOperations.update( {
        endpoint: `admin/resource/${roleId}`,
        key,
        data
    }) );

};


export {
    get, 
    update,
    clear
};
