import React from 'react';

import { Table } from 'app/components';
import { Field, selectField } from 'app/components/form';

const ResourceOperations = ({resource}) => {

    const fields = [
        {
            label: 'Operation',
            name: 'operation',
        },
        {
            label: 'Description',
            name: 'description'
        },
        {
            label: 'ACL',
            name: 'acl'
        }
    ];

    const selectData = [
        { id: 'I', key: 'I', value: 'I', label: 'Inherit' },
        { id: 'Y', key: 'Y', value: 'Y', label: 'Yes' },
        { id: 'N', key: 'N', value: 'N', label: 'No' },
    ];

    const renderRow = (d, i) => {
        const getAclValue = allow => ( allow === null ? 'I' : (allow === true ? 'Y' : 'N') );
        const name = `${d.module}[${d.resource}][${d.operation}]`;
        return {
            key: i,
            cells: [
                d.operation,
                d.description,
                <Field 
                    component={selectField} 
                    name={name}
                    value={getAclValue(d.allowed)} 
                    select={{ defaultOptions: selectData, defaultValue: getAclValue(d.allowed) }}
                    isClearable={false}
                    isSearchable={false} />
            ]
        }
    };

    return (
        <Table
            fields={fields}
            renderRow={renderRow}
            data={resource.operations}
            />

    );
}

export default ResourceOperations;