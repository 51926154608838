import React from 'react'

import Button from 'app/components/button'
import SendIcon from '@material-ui/icons/Send';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { withAppForm, Field, TextField } from './../../components/form';


const LoginForm = (props) => {
    return (
        <Card>
            <CardHeader title='Login' />
                <CardContent>
                    <Field
                        label='Username'
                        id='username'
                        name='username'
                        component={TextField}
                        placeholder='Username'
                        autoFocus
                        autoComplete='username' />
                    <Field
                        label='Password'
                        id='password'
                        name='password'
                        component={TextField}
                        placeholder='Password'
                        type='password'
                        autoComplete='current-password' />
                    {props.status && <Typography align='center' color={props.status.type}>{props.status.message}</Typography>}
                </CardContent>
                <CardActions style={{ justifyContent: 'center' }}>
                    <Button loading={props.isSubmitting} disabled={!props.isValid} variant="contained" color="primary" type="submit">
                        <SendIcon />
                        Login
                    </Button>
                </CardActions>
        </Card>
    );
};

export default withAppForm(LoginForm);
