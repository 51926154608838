import React from 'react';

import Table from 'app/components/table';

import DeleteIcon from '@material-ui/icons/Delete';

import coinUtils from './../../../../../utils';

const OrderUsersTable = ({ order, removeOrderUser }) => {

    const fields = [
        {
            label: 'User',
            id: 'user_id',
            name: 'user_id'
        },
        {
            label: 'Percent',
            id: 'percent',
            name: 'percent'
        },
        {
            label: 'Src. amount',
            id: 'source_amount',
            name: 'source_amount'
        },
        {
            label: 'Dst. amount',
            id: 'destination_amount',
            name: 'destination_amount'
        },
        {
            label: 'Fee amount',
            id: 'fee_amount',
            name: 'fee_amount'
        }
    ];

    const renderRow = ({ User, percent, source_amount, destination_amount, fee_amount}, i) => ({
        key: i,
        cells: [
            User.name,
            coinUtils.formatPercent(percent),
            coinUtils.formatCurrency(order.SourceCurrency, source_amount),
            coinUtils.formatCurrency(order.DestinationCurrency, destination_amount),
            coinUtils.formatCurrency(order.FeeCurrency, fee_amount)
        ]
    });

    const actions = [
        {
            icon: <DeleteIcon />,
            onClick: ( data ) => removeOrderUser(order.id, data.User.id),
            confirm: {
                content: 'Are you sure you want to delete user :User.name?'
            }
        }
    ];

    return (
        <Table
            data={order.OrderUsers}
            fields={fields}
            actions={actions}
            renderRow={renderRow}
        />
    );
}

export default OrderUsersTable;
