import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Button from 'app/components/button';

import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';

import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles(theme => ({
    toolbar: {
        minHeight: '32px'
    },
}));

const TableMeta = ({meta, search, query, loading, ...props}) => {

    const classes = useStyles();

    const refresh = useCallback( () => search( query ), [ search, query ] );

    const currentPage = useMemo( () => meta ? Math.ceil( meta.offset / meta.limit ) : 1, [ meta ] );

    const renderMeta = useMemo( ( ) => {
        if (meta) {
            return (
                <Typography variant='caption' color='textSecondary' style={{paddingLeft: 10}}>
                    {meta.offset+1}-{(meta.offset+meta.limit > meta.total ? meta.total : meta.offset+meta.limit)}/{meta.total}
                    {meta.time ? ` (${meta.time.toFixed(2)}s)`: ''}
                    <Tooltip
                        title='Refresh table data'>
                        <Button
                            loading={loading}
                            size='small'
                            onClick={refresh}>
                            <RefreshIcon
                                fontSize='small'
                                color='disabled' />
                        </Button>
                    </Tooltip>
                </Typography>
            );
        }
        return null;
    }, [ meta, loading, refresh ] );

    const onPageChange = ( e, activePage ) => {
        query.page = ++activePage;
        search(query);
    };

    return (
        <Grid container direction="row" alignItems="center" justify="space-between">
            <Grid item xs={6}>
                {renderMeta}
            </Grid>
            <Grid item xs={6}>
                <TablePagination
                    classes={classes}
                    backIconButtonProps={{ size: 'small' }}
                    nextIconButtonProps={{ size: 'small' }}
                    component="span"
                    rowsPerPageOptions={[]}
                    count={meta ? meta.total : 0}
                    rowsPerPage={meta ? meta.limit : 0}
                    page={meta ? currentPage : 0}
                    onChangePage={onPageChange}
                />
            </Grid>
        </Grid>
    );
};

TableMeta.propTypes = {
    meta: PropTypes.object,
    search: PropTypes.func, 
    query: PropTypes.object
};

TableMeta.defaultProps = {
    meta: null,
    search: null,
    query: null
};

export default TableMeta;
