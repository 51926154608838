import * as utils from './utils';

const loadingReducer = (state = 0, action ) => {

    if (action.type) {
        if  (utils.isActionStart(action.type)) {
            return state + 1;
        }
        if (utils.isActionStop(action.type)) {
            return state - 1;
        }
    }
    return state;

};

export default loadingReducer;