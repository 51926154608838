import { default as types } from './types';
import { createReducer } from './../../utils';

import blue700 from '@material-ui/core/colors/blue';

const storedThemeType = window.localStorage.getItem('themeType');
const defaultThemeType = (storedThemeType === null ? 'light' : storedThemeType);

const actionsMap = {

    /**
     * Load all modules
     */
    [ types.SWITCH_TYPE ] : ( state ) => {
        const newType = (state.palette.type === 'light' ? 'dark' : 'light');
        window.localStorage.setItem('themeType', newType);
        return { ...state, palette: { ...state.palette, type: (state.palette.type === 'light' ? 'dark' : 'light') } };
    },

};

const initialState = {
    /**
     * Default theme object
     */
    palette: {
        type: defaultThemeType,
        primary: blue700,
    },
    typography: {
        fontFamily: 'Open Sans',
    },

};

export default createReducer( initialState ) ( actionsMap );