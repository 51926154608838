import React, { useState, useMemo, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { default as MuiTabs } from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

/**
 * A Stateful Mui Tabs implementation
 */

const useStyles = makeStyles( theme => ({
    tabContent: {
        backgroundColor: theme.palette.background.paper,
        padding: '24px'
    },
}) );


const Tabs = ({
    value,
    panes, 
    keepMounted,
    /**
     * Mui-Tabs props
     */
    variant,
    ...restProps
}) => {

    const classes = useStyles();

    const [ stateValue, setStateValue ] = useState( (
            value === undefined ? (
                panes.length > 0 ? (
                    panes[0].tab.value ? panes[0].tab.value : 0
                ) :
                false
            ) :
            value
        )
    );

    const handleChange = ( ev, value ) => setStateValue( value );

    const renderPanes = useMemo( () => {

        let foundActive = false;

        const ret = panes.map( ( { tab, content }, key) => {
            const tabValue = ( tab.value === undefined ? key : tab.value );

            /**
             * If keepMounted === true we will render content
             * everytime, but will set style.display to 'none'.
             */
            if (keepMounted) {
                const style = {
                    display: (tabValue === stateValue ? 'block' : 'none')
                }
                if (tabValue === stateValue && foundActive === false) {
                    foundActive = true;
                }
                return <Typography key={tabValue} component="div" style={style} className={classes.tabContent}>{content}</Typography>;
            } else {
                /**
                 * Otherwise, if this is not a current tab, we return null
                 * so it will get unmounted.
                 */
                if (tabValue === stateValue) {
                    foundActive = true;
                    return <Typography key={tabValue} component="div" className={classes.tabContent}>{content}</Typography>;
                }
            }

            return null;

        });

        /**
         * If no active pane was found, move to the first/last one
         * This would be the case when the active pane was removed.
         *
         * @todo In non-numeric (tabs with values), figure out how to move to the
         *       next one.
         *
         */
        if (foundActive === false) {
            /* move to last
            if (panes.length > 0) {
                const lastPane = panes[ panes.length -1 ];
                if (lastPane.tab.value === undefined) {
                    setStateValue( panes.length -1 );
                } else {
                    setStateValue( lastPane.tab.value );
                }
            } else {
                setStateValue( false );
            }
            */
            /* move to first */
            if (panes.length > 0) {
                const firstPane = panes[ 0 ];
                if (firstPane.tab.value === undefined) {
                    setStateValue( 0 );
                } else {
                    setStateValue( firstPane.tab.value );
                }
            } else {
                setStateValue( false );
            }
        }

        return ret;
    },
    [ panes, stateValue, keepMounted, classes.tabContent ]);

    const renderTabs = useMemo( () => {
        return panes.map( ( { tab }, key ) => <Tab key={key} {...tab} /> );
    },
    [ panes ]);

    /**
     * We can simply change value and it will switch to another
     * tab.
     */
    useEffect( () => {
        setStateValue( value );
    }, [ value ])

    return (
        <Fragment>
            <MuiTabs
                value={stateValue}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant={variant}
                {...restProps}
            >
                {renderTabs}
            </MuiTabs>
            {renderPanes}
        </Fragment>
    );
}

Tabs.propTypes = {
    value: PropTypes.any,
    panes: PropTypes.array.isRequired,
    keepMounted: PropTypes.bool,
    /**
     * Material-UI Tabs props
     */
    variant: PropTypes.oneOf(['standard', 'scrollable', 'fullWidth'])
};

Tabs.defaultProps = {
    value: undefined,
    keepMounted: true,
    /**
     * Material-Ui props
     */
    variant: 'scrollable'
};

export default Tabs;
