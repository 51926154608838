import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { connect } from 'react-redux'

import { pmTaskOperations } from './../../../state/ducks/pm/task';

import { Table } from 'app/components';

import Fab from '@material-ui/core/Fab';

import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';

import { openSnackbar } from 'app/components/snackbar';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';

import utils from 'app/utils';

class pmTasksPage extends Component {

    static propTypes = {
        loading: PropTypes.bool.isRequired,
        query: PropTypes.object.isRequired,
        meta: PropTypes.object,
        data: PropTypes.array.isRequired,
        search: PropTypes.func.isRequired,
        remove: PropTypes.func.isRequired,
    };

    static defaultProps = {
        meta: null
    };

    fields = [
        {
            label: 'Id',
            name: 'id',
            type: 'number',
            width: 1,
        },
        {
            label: 'Name',
            name: 'name',
            width: 3,
        },
        {
            label: 'Routing key',
            name: 'routing_key',
            width: 2
        },
        {
            label: 'Command',
            name: 'command',
            width: 2
        },
        {
            label: 'Enabled',
            name: 'enabled',
            width: 2,
            searchable: false
        },
        {
            label: 'Max processes/Per host',
            name: 'max_processes',
            width: 1,
            searchable: false
        }
    ];

    renderRow = ({ id, name, routing_key, command, enabled, max_processes, max_processes_per_host }, i) => ({
        key: id,
        cells: [
            id,
            name,
            routing_key,
            command,
            { padding: 'checkbox', style: { textAlign: 'center' }, content: (enabled ? <CheckIcon /> : <CancelIcon color='error' />)},
            `${max_processes}/${max_processes_per_host}`
        ]
    });

    actions = [
        {
            icon: <DescriptionIcon />,
            component: Link,
            to: utils.getFullPath('admin/pm/tasks/view/:id')
        },
        {
            icon: <CreateIcon />,
            component: Link,
            to: utils.getFullPath('admin/pm/tasks/edit/:id')
        },
        {
            icon: <DeleteIcon />,
            onClick: ( data ) => this.removeTask(data.id),
            confirm: {
                content: 'Are you sure you want to delete task :name ?'
            }
        }
    ];

    async removeTask ( id ) {
        const { remove, search, query } = this.props;
        const ret = await remove( id );
        if (ret.type.indexOf('error') > 0) {
            openSnackbar('Failed to delete task', 'error');
        } else {
            openSnackbar('Task deleted', 'success');
            search( query );
        }
    }

    render() {
        const { loading, ...props } = this.props;
        return (
            <Fragment>
                <Table
                    fields={this.fields}
                    renderRow={this.renderRow}
                    actions={this.actions}
                    loading={loading}
                    {...props}
                    />
                <Fab color='primary' style={{ float: 'right' }} variant='extended' component={Link} to={utils.getFullPath('admin/pm/tasks/add')}>
                    <AddIcon />
                    Add task
                </Fab>
            </Fragment>
        );
    }
}

const mapStateToProps = ( state, ownProps ) => {

    const {
        loading = false,
        query = { refs: 1, page: 1 },
        data = [],
        meta = null,
        error = false
    } = state.app.blrf.objects.pmTasks || {};

    return {
        loading, 
        query, 
        data,
        meta,
        error
    }
};

const mapDispatchToProps = {
    search: ( query ) => pmTaskOperations.search( 'pmTasks', query ),
    remove: ( id ) => pmTaskOperations.remove( 'pmTasks', id ),
};

export default connect( mapStateToProps, mapDispatchToProps )( pmTasksPage );
