import React from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import ViewEdit from 'app/components/viewedit';

const CurrencyInfo = ({ currency }) => {

    const fields = [
        {
            id: 'name',
            name: 'name',
            label: 'Name',
            value: currency.name,
            width: 3
        },
        {
            id: 'sid',
            name: 'sid',
            label: 'Sid',
            value: currency.sid,
            width: 3
        },
        {
            id: 'symbol',
            name: 'symbol',
            label: 'Symbol',
            value: currency.symbol,
            width: 3
        },
        {
            id: 'decimals',
            name: 'decimals',
            label: 'Decimals',
            value: currency.decimals,
            width: 3
        }
    ]

    return (
        <ViewEdit
            fields={fields} />
    );

}

export default CurrencyInfo;
