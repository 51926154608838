import React, { Fragment, useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { orderOperations } from './../../state/ducks/order';
import { exchangeComponents } from './../../state/ducks/exchange';
import { currencyComponents } from './../../state/ducks/currency';

import * as orderConst from './../../const/order';

import { Table } from 'app/components';

import { Link } from 'react-router-dom';

import Fab from '@material-ui/core/Fab';

import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';

import { openSnackbar } from 'app/components/snackbar';

import utils from 'app/utils';
import coinUtils from '../../utils';

const ordersPage = () => {

    /**
     * Take dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Search for orders via Api
     */
    const search = useCallback( query => dispatch( orderOperations.search( 'orders', query ) ), [ dispatch ] );

    /**
     * Remove order via Api
     */
    const remove = useCallback( id => dispatch( orderOperations.remove( 'order', id ) ), [ dispatch ] );

    /**
     * orders blrf object
     */
    const ordersObject = useSelector( state => state.app.blrf.objects.orders || state.app.blrf.defaultObject );

    const { data, meta } = useMemo( () => {
        return {
            data: ordersObject.data,
            meta: ordersObject.meta
        }
    }, [ ordersObject ]);

    const query = useMemo( () => {
        /**
         * When object is first created query will only contain { refs: 0, page: 1 }
         *
         * We want refs, so we change that. And we want to have default order.
         * All other aspects of query object is controlled via
         * Table component.
         */
         const hasOrder = Array.isArray(ordersObject.query.order) && ordersObject.query.order.length > 0;
         if (ordersObject.query.refs === 0 || !hasOrder) {
            return { ...ordersObject.query, refs: ['SourceExchange','SourceCurrency', 'DestinationExchange','DestinationCurrency', 'FeeCurrency'], order: (hasOrder ? [...ordersObject.query.order ] : ['id DESC'] ) };
         }
         return ordersObject.query;
    }, [ ordersObject.query ] );

    const {
        loading,
        error
    } = ordersObject;

    const fields = [
        {
            label: 'Id',
            name: 'id',
            width: 1,
            type: 'number'
        },
        {
            label: 'Datetime',
            name: 'created_at',
            width: 1,
            searchable: false
        },
        {
            label: 'Type',
            name: 'type',
            width: 1,
            select: {
                defaultOptions: orderConst.orderTypeOptions
            }
        },
        {
            label: 'Status',
            name: 'status',
            width: 1,
            select: {
                defaultOptions: orderConst.orderStatusOptions
            }
        },
        {
            label: 'Src. Exchange',
            name: 's_exchange_id',
            width: 2,
            component: exchangeComponents.exchangesSelectField,
            select: {
                blrfKey: 'o_s_exchange_id_select'
            }
        },
        {
            label: 'Src. Currency',
            name: 's_currency_id',
            width: 2,
            component: currencyComponents.currenciesSelectField,
            select: {
                blrfKey: 'o_s_currency_id_select'
            }
        },
        {
            label: 'Src. Amount',
            name: 's_amount',
            width: 1,
            searchable: false
        },
        {
            label: 'Dst. Exchange',
            name: 'd_exchange_id',
            width: 2,
            component: exchangeComponents.exchangesSelectField,
            select: {
                blrfKey: 'o_d_exchange_id_select'
            }
        },
        {
            label: 'Dst. Currency',
            name: 'd_currency_id',
            width: 2,
            component: currencyComponents.currenciesSelectField,
            select: {
                blrfKey: 'o_d_currency_id_select'
            }
        },
        {
            label: 'Dst. Amount',
            name: 'd_amount',
            width: 1,
            searchable: false
        },
        {
            label: 'Fee',
            name: 'fee_amount',
            width: 1,
            searchable: false
        }
    ];

    const renderRow = ({ id, created_at, type, status, SourceExchange, SourceCurrency, s_amount, DestinationExchange, DestinationCurrency, d_amount, FeeCurrency, fee_amount }, i) => ({
        key: i,
        variant: (status == orderConst.S_WAIT ? 'warning' : undefined ),
        cells: [
            id,
            created_at,
            type ? orderConst.orderType[type].name : type,
            status ? orderConst.orderStatus[status].name : status,
            SourceExchange.name,
            SourceCurrency.sid,
            coinUtils.formatCurrency(SourceCurrency, s_amount),
            DestinationExchange.name,
            DestinationCurrency.name,
            coinUtils.formatCurrency(DestinationCurrency, d_amount),
            coinUtils.formatCurrency(FeeCurrency, fee_amount)
        ]
    });

    const actions = [
        {
            icon: <DescriptionIcon />,
            component: Link,
            to: utils.getFullPath('coin/orders/view/:id')
        },
        {
            icon: <CreateIcon />,
            component: Link,
            to: utils.getFullPath('coin/orders/edit/:id')
        },
        {
            icon: <DeleteIcon />,
            onClick: ( data ) => removeorder(data.id),
            confirm: {
                content: 'Are you sure you want to delete order :id?'
            }
        }
    ];

    const removeorder = async ( id ) => {
        const ret = await remove( id );
        if (ret.type.indexOf('error') > 0) {
            openSnackbar('Failed to delete order', 'error');
        } else {
            openSnackbar('Order deleted', 'success');
            search( query );
        }
    }

    return (
        <Fragment>
            <Table
                fields={fields}
                renderRow={renderRow}
                actions={actions}
                loading={loading}
                search={search}
                query={query}
                data={data}
                meta={meta}
                error={error}
                />
            <Fab color='primary' style={{ float: 'right' }} variant='extended' component={Link} to={utils.getFullPath('coin/orders/add')}>
                <AddIcon />
                Add order
            </Fab>
        </Fragment>
    );
};

export default ordersPage;
