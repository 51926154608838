import React from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import AppNavbarLink from './link';

const AppNavbarModuleButton = ({title, icon, to, close}) => {

    return (
        <MenuItem component={AppNavbarLink} to={to} onClick={close}>
            {icon ? 
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                : null
            }
            <ListItemText primary={title} />
        </MenuItem>
    );
};

AppNavbarModuleButton.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.node,
    to: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired
};
AppNavbarModuleButton.defaultProps = {
    title: null,
    icon: null,
};

export default AppNavbarModuleButton;
