import React from 'react';

/**
 * Wait status
 */
const S_WAIT        = 'WAIT';
/**
 * Status Process
 */
const S_PROCESS     = 'PROCESS';
/**
 * Status Done
 */
const S_DONE    = 'DONE';


const transactionStatus = {

    [ S_WAIT ]: {
        id: S_WAIT,
        name: 'Wait'
    },

    [ S_PROCESS ]: {
        id: S_PROCESS,
        name: 'Process'
    },

    [ S_DONE ]: {
        id: S_DONE,
        name: 'Done'
    }

};

const transactionStatusOptions = Object.keys( transactionStatus ).map( k => ({
    value: transactionStatus[k].id, label: transactionStatus[k].name
}));

export {

    S_WAIT,
    S_PROCESS,
    S_DONE,

    transactionStatus,
    transactionStatusOptions

};
