
import React, { Fragment, useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { realmOperations } from './../../state/ducks/realm';

import { Table } from 'app/components';

import { Link } from 'react-router-dom';

import Fab from '@material-ui/core/Fab';

import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';

import { openSnackbar } from 'app/components/snackbar';

import utils from 'app/utils';

const RolesPage = () => {

    /**
     * Take dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Search for realms via Api
     */
    const search = useCallback( query => dispatch( realmOperations.search( 'realms', query ) ), [ dispatch ] );

    /**
     * Remove realm via Api
     */
    const remove = useCallback( id => dispatch( realmOperations.remove( 'realms', id ) ), [ dispatch ] );

    /**
     * Roles blrf object
     */
    const realmsObject = useSelector( state => state.app.blrf.objects.realms || state.app.blrf.defaultObject );

    const { data, meta } = useMemo( () => {
        return {
            data: realmsObject.data,
            meta: realmsObject.meta
        }
    }, [ realmsObject ]);

    const query = useMemo( () => {
        /**
         * When object is first created query will only contain { refs: 0, page: 1 }
         *
         * We want refs, so we change that. And we want to have default order.
         * All other aspects of query object is controlled via
         * Table component.
         */
         const hasOrder = Array.isArray(realmsObject.query.order) && realmsObject.query.order.length > 0;
         if (realmsObject.query.refs === 1 || !hasOrder) {
            return { ...realmsObject.query, refs: 0, order: (hasOrder ? [...realmsObject.query.order ] : ['name ASC'] ) };
         }
         return realmsObject.query;
    }, [ realmsObject.query ] );

    const {
        loading,
        error
    } = realmsObject;

    const fields = [
        {
            label: 'Id',
            name: 'id',
            type: 'numeric',
            width: 1,
        },
        {
            label: 'Type',
            name: 'type',
            width: 2,
        },
        {
            label: 'Name',
            name: 'name',
            width: 3,
        },
    ];

    const renderRow = ({ id, type, name }, i) => ({
        key: id,
        cells: [
            id,
            type,
            name
        ]
    });

    const actions = [
        {
            icon: <DescriptionIcon />,
            component: Link,
            to: utils.getFullPath('admin/realms/view/:id')
        },
        {
            icon: <CreateIcon />,
            component: Link,
            to: utils.getFullPath('admin/realms/edit/:id')
        },
        {
            icon: <DeleteIcon />,
            onClick: ( data ) => removeRealm(data.id),
            confirm: {
                content: 'Are you sure you want to delete realm :name ?'
            }
        }
    ];

    const removeRealm = async ( id ) => {
        const ret = await remove( id );
        if (ret.type.indexOf('error') > 0) {
            openSnackbar('Failed to delete realm', 'error');
        } else {
            openSnackbar('Realm deleted', 'success');
            search( query );
        }
    }

    return (
        <Fragment>
            <Table
                fields={fields}
                renderRow={renderRow}
                actions={actions}
                loading={loading}
                search={search}
                query={query}
                data={data}
                meta={meta}
                error={error}
                />
            <Fab color='primary' style={{ float: 'right' }} variant='extended' component={Link} to={utils.getFullPath('admin/realms/add')}>
                <AddIcon />
                Add realm
            </Fab>
        </Fragment>
    );
};

export default RolesPage;

