import React from 'react';
import PropTypes from 'prop-types';

import { ResponsiveLine } from '@nivo/line';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

/*

*/

/**
 * Dashboard chart line for datetime grouped values
 */
const DashboardChartDaily = ( { data, theme, colors } ) => {

    console.log('chartData: ', data);

    return (
        <Card>
            <CardContent style={{height: '250px'}}>
                <ResponsiveLine
                    colors={colors}
                    margin={{ top: 10, right: 120, bottom: 50, left: 60 }}
                    yScale={{ type: 'linear', stacked: false, min: 'auto', max: 'auto' }}
                    xScale={{
                      type: 'time',
                      format: '%Y-%m-%d',
                      useUTC: false,
                      precision: 'day',
                    }}
                    Format="time:%Y-%m-%d"
                    axisTop={null}
                    axisRight={null}
                    enableArea={true}
                    axisBottom={{
                        format: '%Y-%m-%d',
                        orient: 'bottom',
                        tickSize: 5,
                        tickValues: 6,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendOffset: 36,
                        legendPosition: 'middle'
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickValues: 6,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendOffset: -40,
                        legendPosition: 'middle'
                    }}
                    enableGridX={false}
                    enableGridY={true}
                    isInteractive={true}
                    pointLabel="y"
                    enableSlices="x"
                    data={[
                        {
                            id: 'Value',
                            data
                        }
                    ]}
                    theme={theme} />
            </CardContent>
        </Card>
    );
};

DashboardChartDaily.propTypes = {
    /**
     * Data ready for Nivo line chart
     */
    data: PropTypes.array,
    /**
     * Nivo chart theme
     */
    theme: PropTypes.object.isRequired,
    /**
     * Nivo colors
     */
    colors: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.arrayOf(PropTypes.string)
    ])
};

DashboardChartDaily.defaultProps = {
    data: [],
    colors: { scheme: 'paired' }
}

export default DashboardChartDaily;