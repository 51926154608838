import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Table } from 'app/components';
import { Field, selectField } from 'app/components/form';
import Button from 'app/components/button';

import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const ResourceActions = ({module, resource, setFieldValue}) => {

    const fields = [
        {
            label: 'Operation',
            name: 'operation',
        },
        {
            label: 'Description',
            name: 'description'
        },
        {
            label: 'ACL',
            name: 'acl'
        }
    ];

    const selectData = [
        { id: 'I', key: 'I', value: 'I', label: 'Inherit' },
        { id: 'Y', key: 'Y', value: 'Y', label: 'Yes' },
        { id: 'N', key: 'N', value: 'N', label: 'No' },
    ];

    const renderRow = (d, i) => {
        const getAclValue = allow => ( allow === null ? 'I' : (allow === true ? 'Y' : 'N') );
        const name = `${module.key}[${resource.key}][${d.key}]`;
        return {
            key: i,
            cells: [
                <b>{d.name}</b>,
                d.description,
                (d.authorization ?
                    <Field 
                        component={selectField} 
                        name={name}
                        value={getAclValue(d.allowed)} 
                        select={{ defaultOptions: selectData, defaultValue: getAclValue(d.allowed) }}
                        isClearable={false}
                        isSearchable={false} />
                    :
                    <Typography color='textSecondary'>No authorization required</Typography>
                )
            ]
        }
    };

    const setAllFields = (value) => {
        resource.actions.map( d => {
            const name = `${module.key}[${resource.key}][${d.key}]`;
            setFieldValue(name, value, false);
            return name;
        });
    }

    return (
        <Fragment>
            <Typography variant="overline" align='center' display='block'>{resource.description}</Typography>
            <Table
                fields={fields}
                renderRow={renderRow}
                data={resource.actions}
                />
            <Grid
                container
                direction='row'
                justify='flex-end'
                alignItems='center'
            >
                <Button
                    startIcon={<CheckCircleOutlineIcon />}
                    onClick={() => setAllFields('Y')}>
                    Allow all
                </Button>
                <Button
                    startIcon={<HighlightOffIcon />}
                    onClick={() => setAllFields('N')}>
                    Deny all
                </Button>
                <Button
                    startIcon={<RemoveCircleOutlineIcon />}
                    onClick={() => setAllFields('I')}>
                    Inherit all
                </Button>
            </Grid>
        </Fragment>
    );
}

export default ResourceActions;