/**
 * app/theme/* actions
 */
export default {

    /**
     * Initialize theme
     */
    SWITCH_TYPE: 'app/theme/switchType',

};
