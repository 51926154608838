import React, { useContext, useMemo } from 'react';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';

import OrderWizardContext from './wizard/context';

import OrderWizardGeneralStep from './wizard/general_step';
import OrderWizardUsersStep from './wizard/users_step';
import OrderWizardFinishStep from './wizard/finish_step';

import * as orderConst from './../../../const/order';

const OrderWizard = () => {

    const ctx = useContext( OrderWizardContext );

    const {
        step,
        order
    } = ctx;

    const steps = useMemo(() => {
        return [
            order.id > 0 ? `General: ${order.id} (${orderConst.orderStatus[order.status].name})` : 'General',
            `Users (${order.OrderUsers ? order.OrderUsers.length : 0})`,
            'Finish'
        ];
    }, [ order.id, order.status, order.OrderUsers ]);

    const renderSteps = useMemo( () => {

        const getStepContent = (index) => {
            switch (index) {
                case 0:
                    return step === index ? <OrderWizardGeneralStep /> : null
                case 1:
                    return step === index ? <OrderWizardUsersStep /> : null
                case 2:
                    return step === index ? <OrderWizardFinishStep /> : null
                default:
                    return null;
            }
        };
        return steps.map((label, index) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent>
                        {getStepContent(index)}
                    </StepContent>
                </Step>
            ));
    }, [ steps, step ])

    return(
        <Stepper activeStep={step} orientation="vertical">
            {renderSteps}
        </Stepper>
    );
}

export default OrderWizard;