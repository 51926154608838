import React from 'react';

import Switch from '@material-ui/core/Switch';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
/**
 * This is Material UI Form.Field wrapped into formik field
 *
 * Since we cannot have boolean values, we'll convert true = '1' and false = '0' by default.
 * Note that if you have value comming out of RESTFul or something Boolean true/false, convert it to 
 * '0' or '1'.
 */

const switchField = ({
    /**
     * Props for Material-UI
     */
    fullWidth = true,
    margin = 'normal',
    autoFocus = false,
    variant = 'standard',

    /**
     * Our fields
     */
    id = undefined,
    label = undefined,
    help = undefined,
    disabled = false,
    loading = false,

    /**
     * Switch configuration
     */
    switchConfig,
    /**
     * Formik form
     */
    form, 
    /**
     * Formik field
     */
    field,
    /**
     * Other props are passed to Input component
     */
    ...props
}) => {

    const touched = form.touched[field.name]
    const invalid = form.errors[field.name] ? true : false;

    const cfg = {
        value: (switchConfig.value ? switchConfig.value : ( checked ) => checked === true ? '1' : '0' ),
        checked: (switchConfig.checked ? switchConfig.checked : ( value ) => value === '1' ? true : false )
    };

    const onSwitchChange = (event, checked) => {
        const newValue = cfg.value(checked);
        form.setFieldValue(field.name, newValue);
    };

    const onSwitchBlur = (event) => {
        form.setFieldTouched(field.name, true);
    }

    return (
        <FormControl error={touched && invalid} fullWidth={fullWidth} margin={margin} variant={variant}>
            <FormControlLabel
                label={label}
                onChange={onSwitchChange}
                onBlur={onSwitchBlur}
                checked={cfg.checked(field.value)}
                value={field.value}
                type='checkbox'
                {...props} 
                control={
                    <Switch 
                        color='primary'
                        />
                }
            />
            { help && <FormHelperText>{help}</FormHelperText>}
        </FormControl>
    );
};

export default switchField;