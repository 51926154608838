import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { default as MuiTableRow } from '@material-ui/core/TableRow';

import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';

import TableCells from './cells';
import TableAction from './action';

const useStyles = makeStyles(theme => ({
    root: {
        height: 35,
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    success: {
        height: 35,
        backgroundColor: green[100],
    },
    error: {
        height: 35,
        backgroundColor: (theme.palette.type === 'dark' ? red[800] : red[100]),
    },
    info: {
        height: 35,
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        height: 35,
        backgroundColor: (theme.palette.type === 'dark' ? amber[800] : amber[100]),
    }
}) );

const TableRow = ( { actions, data, cells, className, variant, ...props } ) => {

    const classes = useStyles();

    const renderActions = useMemo( () => (
        <div style={{ display: 'flex'}}> { 
            actions.map(
                ( action, key ) => {
                    if (typeof action === 'function') {
                        const ret = action( data );
                        if (React.isValidElement(ret)) {
                            return ret;
                        }
                        return <TableAction key={key} data={data} {...ret}/>;
                    }
                    return <TableAction key={key} data={data} {...action}/>;
                })
            }
        </div>
    ), [ actions, data ] );

    const _cells = useMemo( () => {

        let ret = [];

        if (Array.isArray(cells)) {

            cells.forEach( ( cell, key ) => {

                /**
                 * Cell is not an object and not null and not valid react element, Let's copy content
                 * to content.
                 */
                if ( cell === null || typeof cell !== 'object' || React.isValidElement(cell) ) {
                    ret.push( { key: key, content: cell } );
                } else {
                    // cell is an object
                    if (!cell.key) {
                        cell.key = key;
                    }
                    ret.push( cell );
                }         

            } );
        }

        if ( actions && actions.length > 0 ) {
            ret.push(
                {
                    key: 'actions',
                    padding: 'none',
                    content: renderActions,
                }
            );
        }

        return ret;
    }, [ actions, cells, renderActions ] );

    return (
        <MuiTableRow hover={true} className={classNames(classes[variant], className)} {...props}>
            <TableCells cells={_cells} />
        </MuiTableRow>
    );
};

TableRow.propTypes = {
    className: PropTypes.string,
    actions: PropTypes.array,
    cells: PropTypes.array,
    variant: PropTypes.oneOf(['root', 'success', 'warning', 'error', 'info']),
};

TableRow.defaultProps = {
    variant: 'root'
};

export default TableRow;
