import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
});

class Accordion extends Component {

    static propTypes = {
        panes: PropTypes.array.isRequired,
        keepMounted: PropTypes.bool
    };

    static defaultProps = {
        keepMounted: true
    };

    state = {
        active: null
    };

    handleChange = panel => (event, active) => {
        this.setState({
            active: (active ? panel : null)
        });
    };

    render() {
        const { classes, panes } = this.props;
        const { active } = this.state;

        return (
            <div className={classes.root}>
                { panes.map( ( { summary, content } , key ) => 
                    <ExpansionPanel key={key} expanded={active === key} onChange={this.handleChange(key)}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>{summary}</ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{ display: 'block' }}>{content}</ExpansionPanelDetails>
                    </ExpansionPanel> ) 
                }
            </div>
        );
    }
}

export default withStyles(styles)(Accordion);
