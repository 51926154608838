import React, { useContext, useMemo } from 'react';

import * as Yup from 'yup';

import { withAppForm, Field, TextField, DateTimeField, selectField } from 'app/components/form';
import Button from 'app/components/button';
import { openSnackbar } from 'app/components/snackbar';

import Grid from '@material-ui/core/Grid';

import OrderWizardContext from './context';

import { exchangeComponents } from './../../../../state/ducks/exchange';
import { currencyComponents } from './../../../../state/ducks/currency';

import * as orderConst from './../../../../const/order';

const GeneralStepFromImpl = ( { order, loading, isSubmitting, isValid, ...props }) => {

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Field
                    id='type'
                    name='type'
                    label='Type'
                    component={selectField}
                    select={{
                        defaultOptions: orderConst.orderTypeOptions
                    }}
                    disabled={loading || isSubmitting}
                    />
            </Grid>
            <Grid item xs={6}>
                <Field
                    id='status'
                    name='status'
                    label='Status'
                    component={selectField}
                    select={{
                        defaultOptions: orderConst.orderStatusOptions
                    }}
                    disabled={true}
                    />
            </Grid>
            <Grid item xs={4}>
                <Field
                    id='s_exchange_id'
                    name='s_exchange_id'
                    label='Src. Exchange'
                    component={exchangeComponents.exchangesSelectField}
                    select={{
                        blrfKey: 'gs_s_exchange_id',
                        multiple: false,
                        defaultData: order && order.SourceExchange ? [order.SourceExchange] : null
                    }}
                    disabled={loading || isSubmitting}
                />
            </Grid>
            <Grid item xs={4}>
                <Field
                    id='s_currency_id'
                    name='s_currency_id'
                    label='Src. Currency'
                    component={currencyComponents.currenciesSelectField}
                    select={{
                        blrfKey: 'gs_s_currency_id',
                        multiple: false,
                        defaultData: order && order.SourceCurrency ? [order.SourceCurrency] : null
                    }}
                    disabled={loading || isSubmitting}
                />
            </Grid>
            <Grid item xs={4}>
                <Field
                    id='s_amount'
                    name='s_amount'
                    label='Src. Amount'
                    component={TextField}
                    type='number'
                    disabled={loading || isSubmitting}
                />
            </Grid>
            <Grid item xs={4}>
                <Field
                    id='d_exchange_id'
                    name='d_exchange_id'
                    label='Dst. Exchange'
                    component={exchangeComponents.exchangesSelectField}
                    select={{
                        blrfKey: 'gs_d_exchange_id',
                        multiple: false,
                        defaultData: order && order.DestinationExchange ? [order.DestinationExchange] : null
                    }}
                    disabled={loading || isSubmitting}
                />
            </Grid>
            <Grid item xs={4}>
                <Field
                    id='d_currency_id'
                    name='d_currency_id'
                    label='Dst. Currency'
                    component={currencyComponents.currenciesSelectField}
                    select={{
                        blrfKey: 'gs_d_currency_id',
                        multiple: false,
                        defaultData: order && order.DestinationCurrency ? [order.DestinationCurrency] : null
                    }}
                    disabled={loading || isSubmitting}
                />
            </Grid>
            <Grid item xs={4}>
                <Field
                    id='d_amount'
                    name='d_amount'
                    label='Dst. Amount'
                    component={TextField}
                    type='number'
                    disabled={loading || isSubmitting}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    id='fee_currency_id'
                    name='fee_currency_id'
                    label='Fee Currency'
                    component={currencyComponents.currenciesSelectField}
                    select={{
                        blrfKey: 'gs_fee_currency_id',
                        multiple: false,
                        defaultData: order && order.FeeCurrency ? [order.FeeCurrency] : null
                    }}
                    disabled={loading || isSubmitting}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    id='fee_amount'
                    name='fee_amount'
                    label='Fee Amount'
                    component={TextField}
                    type='number'
                    disabled={loading || isSubmitting}
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    id='comment'
                    name='comment'
                    label='Comment'
                    component={TextField}
                    disabled={loading || isSubmitting}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant='outlined'
                    disabled>Back</Button>
                <Button
                    variant='outlined'
                    type='submit'
                    color='primary'
                    disabled={!isValid || loading}
                    loading={loading || isSubmitting}
                >
                    Next
                </Button>
            </Grid>
        </Grid>
    );

}

const GeneralStepForm = withAppForm( GeneralStepFromImpl );

const generalStepValidation = Yup.object().shape({
    id: Yup.number(),
    type: Yup.string(),
    status: Yup.string(),
    s_exchange_id: Yup.number().min(1),
    s_currency_id: Yup.number().min(1),
    s_amount: Yup.number().required(),
    d_exchange_id: Yup.number().min(1),
    d_currency_id: Yup.number().min(1),
    d_amount: Yup.number().required(),
    fee_currency_id: Yup.number().min(1),
    fre_amount: Yup.number(),
    comment: Yup.string()
});

const OrderWizardGeneralStep = () => {

    const ctx = useContext( OrderWizardContext );

    const {
        nextStep,
        order,
        updateOrder,
        createOrder,
    } = ctx;

    const initialValues = useMemo( () => {
        return {
            id: order && order.id ? order.id : '',
            type: order && order.type ? order.type : orderConst.T_BUY,
            status: order && order.status ? order.status : orderConst.S_WAIT,
            s_exchange_id: order && order.s_exchange_id ? order.s_exchange_id : 0,
            s_currency_id: order && order.s_currency_id ? order.s_currency_id : 0,
            s_amount: order && order.s_amount ? order.s_amount : 0,
            d_exchange_id: order && order.d_exchange_id ? order.d_exchange_id : 0,
            d_currency_id: order && order.d_currency_id ? order.d_currency_id : 0,
            d_amount: order && order.d_amount ? order.d_amount : 0,
            fee_currency_id: order && order.fee_currency_id ? order.fee_currency_id : 0,
            fee_amount: order && order.fee_amount ? order.fee_amount : 0,
            comment: order && order.comment ? order.comment : ''
        }
    }, [ order ]);

    const onSubmit = ( values ) => {
        let p = null;
        if (values.id > 0) {
            p = updateOrder( values.id, values );
        } else {
            p = createOrder( values );
        }
        p.then( ret => {
            if (ret.type.indexOf('error') > 0) {
                openSnackbar('Order save failed');
            } else {
                nextStep();
            }
        });
        return p;
    }

    return (
        <GeneralStepForm
            order={order}
            initialValues={initialValues}
            validateOnMount
            validationSchema={generalStepValidation}
            onSubmit={onSubmit}
        />
    );
}

export default OrderWizardGeneralStep;