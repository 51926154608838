/**
 * Example configuration
 */
export default {

    /**
     * Our base URI (or URI path)
     *
     * This is the root of URL. eg: http://example.com{/uri}
     * It must always begin with '/' and must not end with '/'
     *
     * Examples:
     * - root: '/'
     * - app: '/app'
     */
    uri: '/',

    /**
     * Where to redirect user if he requests default uri
     */
    defaultUri: 'coin',

    /**
     * BLRF RESTful API configuration
     */
    api: {
        /**
         * BLRF API URL
         *
         * Where is our BLRF RESTFul API located
         */
        url: 'https://coin.blrf.net/api/v1/',
        /**
         * BLRF API Client Id
         */
        clientId: 'coin_ui',
        /**
         * BLRF API Client secret
         * You can generate secret using this command:
         * php -r 'echo base64_encode(random_bytes(32)), PHP_EOL;'
         */
        clientSecret: 'O5rlUYi5Z4lCOgv/gIPSconR0F1TOb2sztty0hRea50'
    },

    /**
     * Modules to load and each configuration (if any)
     */
    modules: [
        {
            name: 'admin',
            config: {}
        },
        {
            name: 'coin',
            config: {}
        }
    ],
};
