import React, { Fragment, useEffect, useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { balanceOperations } from './../../../state/ducks/balance';
import { exchangeComponents } from './../../../state/ducks/exchange';
import { currencyOperations } from './../../../state/ducks/currency';
import { userComponents } from './../../../state/ducks/user';

import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import ErrorAlert from 'app/components/error';
import LinearProgress from '@material-ui/core/LinearProgress';

import DescriptionIcon from '@material-ui/icons/Description';

import { Table } from 'app/components';

import utils from 'app/utils';
import coinUtils from '../../../utils';

import CurrencyInfo from './currency/info';
import CurrencyData from './currency/data';

const BalanceCurrencyPage = ( { match: { params: { cid } } } ) => {

    /**
     * Take dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Get currency info via Api
     */
    const get = useCallback( id => dispatch( currencyOperations.get( 'currency', id ) ) , [ dispatch ]);

    const clear = useCallback( () => dispatch( currencyOperations.clear( 'currency' ) ), [ dispatch ] );

    /**
     * Fetch order blrf object. If object is not yet available return default blrf object stored in state.
     */
    const currencyObject = useSelector( state => state.app.blrf.objects.currency || state.app.blrf.defaultObject );

    /**
     * Get order from blrf object data
     */
    const currency = useMemo( () => {
        return currencyObject.data.find(v => v) || undefined
    }, [ currencyObject.data ]);

    /**
     * Query balance via Api
     */
    const searchBalance = useCallback( query => dispatch( balanceOperations.search( 'balanceCurrency', query ) ), [ dispatch ] );

    const clearBalance = useCallback( () => dispatch( balanceOperations.clear( 'balanceCurrency' ) ), [ dispatch ] );

    /**
     * balance blrf object
     */
    const balanceCurrencyObject = useSelector( state => state.app.blrf.objects.balanceCurrency || state.app.blrf.defaultObject );

    const { data, meta } = useMemo( () => {
        return {
            data: balanceCurrencyObject.data,
            meta: balanceCurrencyObject.meta
        }
    }, [ balanceCurrencyObject ]);

    const query = useMemo( () => {
        /**
         * When object is first created query will only contain { refs: 0, page: 1 }
         *
         * We want refs, so we change that. And we want to have default balance.
         * All other aspects of query object is controlled via
         * Table component.
         */
         const hasOrder = Array.isArray(balanceCurrencyObject.query.balance) && balanceCurrencyObject.query.balance.length > 0;
         if (balanceCurrencyObject.query.refs === 0 || !hasOrder) {
            return { ...balanceCurrencyObject.query, refs: ['User', 'Exchange', 'Currency'], balance: (hasOrder ? [...balanceCurrencyObject.query.balance ] : ['amount DESC'] ) };
         }
         return balanceCurrencyObject.query;
    }, [ balanceCurrencyObject.query ] );

    const {
        loading,
        error
    } = balanceCurrencyObject;

    /**
     * Call get order from Api if oid changed
     *
     * And register unmount function that will clear the order object.
     * This is important because, when we later arrive to this page and
     * oid has changed child components will still see the old order and
     * we will get double queries. One for old and one for new and data
     * will most likely be incorrect.
     */
    useEffect( () => {
        if (cid > 0) {
            get( cid );
        }
        return clear;
    }, [ cid, get, clear ]);

    useEffect( () => {
        if (cid > 0) {
            searchBalance({
                ...query,
                query: {
                    currency_id: cid
                },
                groupBy: ['Exchange', 'User']
            })
        }
        return clearBalance;
    }, [ cid, get, clear ]);

    return (
        <Grid container spacing={2}>
            {error === false && loading && <LinearProgress />}
            {error !== false && <ErrorAlert error={error} />}
            {currency &&
                <Grid item xs={12}>
                    <CurrencyInfo currency={currency} />
                </Grid>
            }
            {data && currency && 
                <Grid item xs={12}>
                    <CurrencyData currency={currency} data={data} />
                </Grid>
            }
        </Grid>
    );
};

export default BalanceCurrencyPage;
