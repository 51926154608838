import { blrfOperations } from './../../../../../app/state/ducks/blrf';

const ping = ( key ) => ( dispatch ) => {

    return dispatch(
        blrfOperations.get( {
            key,
            endpoint: 'example/ping', 
            auth: false,
        } )
    );
}

const pingId = ( key, id ) => dispatch => {
    return dispatch(
        blrfOperations.get({
            key,
            id,
            endpoint: 'example/ping', 
            auth: false,
        })
    );
}

const pingAuth = ( key ) => ( dispatch ) => {

    return dispatch(
        blrfOperations.get( {
            key,
            endpoint: 'example/ping/auth', 
            auth: true,
        } )
    );
}

export {
    ping,
    pingId,
    pingAuth
};
