import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import * as Yup from 'yup';

import { openSnackbar } from 'app/components/snackbar';

import { groupOperations, groupComponents } from './../../state/ducks/group';
import { userComponents } from './../../state/ducks/user';

import ViewEdit from 'app/components/viewedit';
import ErrorAlert from 'app/components/error';
import LinearProgress from '@material-ui/core/LinearProgress';

import utils from 'app/utils';

const groupValidation = Yup.object().shape({
    name: Yup.string()
            .min(2, 'Name too short')
            .max(50, 'Name too long')
            .required('Name is required'),
    owner_id: Yup.number()
            .min(1, 'Owner is required')
            .typeError('Owner is required')
            .integer()
            .required('Owner is required'),
    description: Yup.string()
});

const emptyGroup = {
    id: null,
    parent_id: null,
    owner_id: null,
    name: '',
    description: '',
    created_at: null,
    created_by: null,
    updated_at: null,
    updated_by: null
};

/**
 * Extract action and gid from react-router match prop
 */
const GroupPage = ( { match: { params: { action, gid } } } ) => {

    /**
     * Are we editing
     */
    const edit = (action === 'add' || action === 'edit');

    /**
     * Dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Get group from Api
     */
    const get = useCallback( id => dispatch( groupOperations.get( 'group', id ) ) , [ dispatch ]);
    /**
     * Update group via Api
     */
    const update = useCallback( ( id, data ) => dispatch( groupOperations.update( 'group', id, data ) ), [ dispatch ] );
    /**
     * Create new group via Api
     */
    const create = useCallback( data => dispatch( groupOperations.create( 'group', data ) ) , [ dispatch ] );
    /**
     * Clean group blrf object
     */
    const clear = useCallback( () => dispatch( groupOperations.clear( 'group' ) ), [ dispatch ] );
    /**
     * Fetch group blrf object. If object is not yet available return default blrf object stored in state.
     */
    const groupObject = useSelector( state => state.app.blrf.objects.group || state.app.blrf.defaultObject );

    /**
     * Get group from blrf object data
     */
    const group = useMemo( () => {
        return ( action === 'add' ? emptyGroup : groupObject.data.find(v => v) || emptyGroup)
    }, [ groupObject.data, action ]);

    /**
     * Get loading and error from blrf group object
     */
    const { loading, error } = groupObject;

    /**
     * Call get group from Api if gid changed
     *
     * And register unmount function that will clear the group object.
     * This is important because, when we later arrive to this page and
     * gid has changed child components will still see the old group and
     * we will get double queries. One for old and one for new and data
     * will most likely be incorrect.
     */
    useEffect( () => {
        if (gid > 0) {
            get( gid );
        }
        return clear;
    }, [ gid, get, clear ]);

    const fields = [
            {
                label: 'Id',
                name: 'id',
                disabled: true,
                value: group.id,
                width: 1,
            },
            {
                label: 'Name',
                name: 'name',
                width: 6,
                value: group.name,
            },
            {
                label: 'Owner',
                name: 'owner_id',
                value: ( group.Owner ? ( edit ? group.owner_id : group.Owner.name ) : null ),
                width: 5,
                select: {
                    defaultData: group && group.Owner ? [ group.Owner, { id: 0, name: 'No owner' } ] : [  { id: 0, name: 'No owner' } ],
                },
                component: userComponents.usersSelectField,
            },
            {
                label: 'Parent',
                name: 'parent_id',
                value: ( group.Parent ? ( edit ? group.parent_id : group.Parent.name ) : null ),
                width: 6,
                select: {
                    multiple: false,
                    defaultData: group && group.Parent ? [ group.Parent, { id: 0, name: 'No parent group' } ] : [  { id: 0, name: 'No parent group' } ],
                },
                component: groupComponents.groupsSelectField,
            },
            {
                label: 'Description',
                name: 'description',
                value: group.description,
                width: 6,
            },
            {
                label: 'Created at',
                name: 'created_at',
                value: group.created_at,
                width: 3,
                edit: false
            },
            {
                label: 'Created by',
                name: 'created_by',
                value: group.created_by,
                width: 3,
                edit: false,
            },
            {
                label: 'Updated at',
                name: 'updated_at',
                value: group.updated_at,
                width: 3,
                edit: false,
            },
            {
                label: 'Updated by',
                name: 'updated_by',
                value: group.updated_by,
                width: 3,
                edit: false
            }
        ];

    const onSubmit = ( values ) => {
        let p = null;
        if (action === 'add') {
            p = create(values);
        } else {
            p = update( values.id, values );
        }
        p.then(ret => {
            if (ret.type.indexOf('error') > 0) {
                openSnackbar('Error while saving group', 'error');
            } else {
                openSnackbar('Group saved', 'success');
                window.history.go( -1 );
            }
        } );
        return p;
    }

    return (
        <Fragment>
            {error === false && loading && <LinearProgress />}
            {error !== false && <ErrorAlert error={error} />}
            {group &&
                <ViewEdit
                    header={{ title: (group.id > 0 ? 'Edit group' : 'Add group') }}
                    onSubmit={onSubmit}
                    fields={fields}
                    validationSchema={groupValidation}
                    loading={loading}
                    edit={edit}
                    editButton={{ component: Link, to: utils.getFullPath(`admin/groups/edit/${group.id}`) }}
                    />
            }
        </Fragment>
    );
}

GroupPage.propTypes = {
    match: PropTypes.object.isRequired
};

export default GroupPage;
