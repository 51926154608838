import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import AppSidebarModuleItem from './moduleItem';

const styles = theme => ({
    nested: {
        paddingLeft: theme.spacing(4)
    },
});

class AppSidebarModuleDropdown extends Component {

    static propTypes = {
        title: PropTypes.string.isRequired,
        icon: PropTypes.node,
        items: PropTypes.array.isRequired,
        classes: PropTypes.object.isRequired,
        currentUri: PropTypes.string.isRequired,
    };

    static defaultProps = {
        icon: null
    };

    state = {
        open: false
    };

    handleClick = () => {
        this.setState(state => ({ open: !state.open }));
    };

    render() {
        const { title, icon, items, classes, currentUri } = this.props;
        return (
            <Fragment>
                <MenuItem button onClick={this.handleClick}>
                    {icon ? 
                        <ListItemIcon style={{ color: 'white' }}>
                            {icon}
                        </ListItemIcon>
                        : null
                    }
                    <ListItemText 
                        primary={title} 
                        primaryTypographyProps={{
                            style: { color: 'white', fontWeight: 400 }
                        }}
                        />
                    {this.state.open ? <ExpandLess style={{ color: 'white' }} /> : <ExpandMore style={{ color: 'white' }} />}
                </MenuItem>
                <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                    {items.map((item, key) => <AppSidebarModuleItem key={key} className={classes.nested} currentUri={currentUri} {...item} />)}
                </Collapse>
            </Fragment>
        );
    }
}

export default withStyles(styles)(AppSidebarModuleDropdown);

/*
export default class AppSidebarModuleDropdown extends Component {

    static propTypes = {
        title: PropTypes.string,
        icon: PropTypes.string,
        items: PropTypes.array.isRequired
    };

    static defaultProps = {
        title: null,
        icon: 'dropdown'
    };

    constructor(props) {
        super(props);
        this.state = {
            active: false
        };
        this.onClick = this.onClick.bind(this);
    }

    onClick = () => this.setState({active: !this.state.active});

    render() {
        const { active } = this.state;
        const { title, items } = this.props;
        return (
            <Menu.Item>
                <Accordion.Title active={active} content={title} onClick={this.onClick} />
                <Accordion.Content active={active}>
                    <Menu vertical inverted fluid>
                        {items.map((item, key) => <AppSidebarModuleItem key={key} {...item} />)}
                    </Menu>
                </Accordion.Content>
            </Menu.Item>
        );
    }

}
*/

/*
const AppSidebarModuleDropdown = ({title, icon, items}) => (
    <Menu.Item>
        <Accordion.Title active={true} content={title} />
        <Accordion.Content active={true}>
            <Menu vertical inverted fluid>
                {items.map((item, key) => <AppSidebarModuleItem key={key} {...item} />)}
            </Menu>
        </Accordion.Content>
    </Menu.Item>
);

AppSidebarModuleDropdown.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    items: PropTypes.array.isRequired
};
AppSidebarModuleDropdown.defaultProps = {
    title: null,
    icon: 'dropdown'
};

export default AppSidebarModuleDropdown;
*/
