import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux'
import { Link } from 'react-router-dom';

import { openSnackbar } from 'app/components/snackbar';

import { pmTaskOperations } from './../../../state/ducks/pm/task';

import ViewEdit from 'app/components/viewedit';
import utils from 'app/utils';

class pmTaskPage extends Component {

    static propTypes = {
        /**
         * Are we loading pmTask?
         */
        loading: PropTypes.bool.isRequired,
        /**
         * Action
         */
        action: PropTypes.oneOf(['add', 'edit', 'view']),
        /**
         * Are we editing (add, edit)
         */
        edit: PropTypes.bool.isRequired,
        /**
         * pmTask Id
         */
        tid: PropTypes.number,
        /**
         * Load pmTask action
         */
        get: PropTypes.func.isRequired,
        /**
         * pmTask update action
         */
        update: PropTypes.func.isRequired,
        /**
         * pmTask create action
         */
        create: PropTypes.func.isRequired,
        /**
         * pmTask 
         */
        task: PropTypes.object,
    };

    static defaultProps = {
        tid: null,
        task: null
    };

    async componentWillMount() {
        const { tid, get } = this.props;
        if (tid > 0) {
            await get( tid );
        }
    }

    getFields() {
        const { task, edit } = this.props;

        const switchConfig = {
        };

        return [
            {
                label: 'Id',
                name: 'id',
                disabled: true,
                value: task.id,
                width: 1
            },
            {
                label: 'Name',
                name: 'name',
                width: 4,
                value: task.name,
            },
            {
                label: 'Routing key',
                name: 'routing_key',
                width: 5,
                value: task.routing_key,
            },
            {
                label: 'Enabled',
                name: 'enabled',
                value: (edit ? ( task.enabled ? '1' : '0' ) : ( task.enabled ? 'Yes' : 'No') ),
                width: 2,
                switchConfig
            },
            {
                label: 'Command',
                name: 'command',
                value: task.command,
                width: 12,
            },
            {
                label: 'Max processes',
                name: 'max_processes',
                value: task.max_processes,
                type: 'number',
                width: 3,
            },
            {
                label: 'Max processes per host',
                name: 'max_processes_per_host',
                value: task.max_processes_per_host,
                type: 'number',
                width: 3, 
            },
            {
                label: 'Last check at',
                name: 'last_check_at',
                value: task.last_check_at,
                width: 3,
                edit: false
            },
            {
                label: 'Last check by',
                name: 'last_check_by',
                value: task.last_check_by,
                width: 3, 
                edit: false
            },
            {
                label: 'Created at',
                name: 'created_at',
                value: task.created_at,
                width: 3,
                edit: false
            },
            {
                label: 'Created by',
                name: 'created_by',
                value: task.created_by,
                width: 3,
                edit: false,
            },
            {
                label: 'Updated at',
                name: 'updated_at',
                value: task.updated_at,
                width: 3,
                edit: false,
            },
            {
                label: 'Updated by',
                name: 'updated_by',
                value: task.updated_by,
                width: 3,
                edit: false
            },
        ];
    }

    onSubmit = ( values ) => {
        const { update, create, action } = this.props;
        let p = null;
        if (action === 'add') {
            p = create(values);
        } else {
            p = update( values.id, values );
        }
        p.then( ret => {
            if (ret.type.indexOf('error') > 0) {
                openSnackbar('Error while saving task', 'error');
            } else {
                openSnackbar('Task saved', 'success');
                window.history.go( -1 );
            }
        } );
        return p;
    }

    render() {
        const { task, loading, edit } = this.props;
        return (
            task && 
                <ViewEdit
                    header={{ title: (task.id > 0 ? (edit ? 'Edit task' : 'View task') : 'Add task') }}
                    onSubmit={this.onSubmit}
                    fields={this.getFields()}
                    loading={loading}
                    edit={edit}
                    editButton={{ component: Link, to: utils.getFullPath(`admin/pm/tasks/edit/${task.id}`) }}
                    />
        );
    }
}

const mapStateToProps = ( state, ownProps ) => {
    const { loading = false, data = [] } = state.app.blrf.objects.pmTask || {};
    const { action = 'view', tid = null } = ownProps.match.params;
    const empty_task = {
        id: null,
        name: '',
        routing_key: '',
        enabled: true,
        command: '',
        max_processes: 1,
        max_processes_per_host: 1,
        last_check_at: null,
        last_check_by: null,
        created_at: null,
        created_by: null,
        updated_at: null,
        updated_by: null
    };
    return {
        loading,
        action,
        edit: action === 'edit' || action === 'add',
        tid: parseInt(tid, 10),
        task: (action === 'add' ? empty_task : data.find( v => v )),
    }
};

const mapDispatchToProps = {
    get: ( id ) => pmTaskOperations.get( 'pmTask', id ),
    update: ( id, data ) => pmTaskOperations.update( 'pmTask', id, data ),
    create: ( data ) => pmTaskOperations.create( 'pmTask', data ),
};

export default connect(mapStateToProps, mapDispatchToProps)(pmTaskPage);
