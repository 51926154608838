import { blrfOperations } from 'app/state/ducks/blrf';

const { clear } = blrfOperations;

const search = ( key, data ) => ( dispatch ) => {

    return dispatch ( blrfOperations.search( {
        endpoint: 'coin/balance/search',
        key, 
        data
    }) );

};

export {
    clear,
    search
};
