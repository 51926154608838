import blrfWsTypes from './../ducks/blrf_ws/types';
import blrfWsActions from './../ducks/blrf_ws/actions';

import WebSocket from 'isomorphic-ws';

export const BLRF_WS_API = Symbol('BLRF WS API');

const connect = (store, url) => {
    const state = store.getState().app.blrf_ws;
    if (state.keys.has(url)) {
        return false;
    } else {
        const socket = new WebSocket(url);
        return socket;
    }
}

const blrfWsApi = ( store ) => ( next ) => async ( action ) => {

    if (action.type === blrfWsTypes.DISCONNECT_OK) {
        window.setTimeout(() => store.dispatch( blrfWsActions.reconnect( action.payload.api ) ), 10000);
        return;
    }

    const api = action[BLRF_WS_API];

    if (typeof api === 'undefined') {
        return next( action );
    } 

    // read data from api
    const { key, url = null, data = null, types } = api;
    // load action types
    const [ apiAction, apiSuccess, apiFail, ] = types;

    store.dispatch( { type: apiAction, payload: { api } } );

    let socket = null;

    switch (apiAction) {
        case blrfWsTypes.RECONNECT:
        case blrfWsTypes.CONNECT: 
            const tokenType = window.localStorage.getItem('token_type');
            const accessToken = window.localStorage.getItem('access_token');
            if (tokenType === null || accessToken === null) {
                store.dispatch( { type: apiFail, payload: { api, error: 'No tokenType or accessToken. Please login first' } } );
                /**
                 * If ws is setup correctly, it should reconnect on login!
                 */
                //window.setTimeout(() => store.dispatch( blrfWsActions.connect( api ) ), 10000);
                return;
            }
            socket = connect( store, url );
            if (socket === false) {
                return next( { type: apiFail, payload: { api, error: 'Connection already exists' } } );
            } else {
                socket.onopen = (evt) => {
                    socket.send(JSON.stringify({
                        type: 'Blrf\\Ws\\Message\\Auth',
                        data: {
                            tokenType,
                            accessToken
                        }
                    }));
                    next( { type: apiSuccess, payload: { api, socket, url, event: evt } } );
                };
                socket.onclose = (evt) => {
                    store.dispatch( { type: blrfWsTypes.DISCONNECT_OK, payload: { api, socket, event: evt } } );
                };
                socket.onerror = (evt) => {
                    store.dispatch( { type: apiFail, payload: { api, socket, event: evt } } );
                };
                /**
                 * On message event handler
                 *
                 * We expect message to be in JSON format and have "type" key
                 * So we form the action of type:
                 * blrf_ws/message/key/type
                 */
                socket.onmessage = (evt) => {
                    const msg = JSON.parse(evt.data);
                    const msgType = msg && msg.type;
                    if (msgType) {
                        store.dispatch( {
                            type: `blrf_ws/message/${key}/${msgType}`,
                            payload: { api, event: evt, message: msg }
                        } );
                    } else {
                        store.dispatch( blrfWsActions.disconnect( api.key ) );
                    }
                }
            }
            break;
        case blrfWsTypes.DISCONNECT:
            socket = store.getState().app.blrf_ws.connections[key].socket;
            if (socket && socket.readyState !== 3) {
                socket.close();
            } else {
                return next( { type: blrfWsTypes.DISCONNECT_OK, payload: { api } } );
            }
            break;
        case blrfWsTypes.SEND: 
            socket = store.getState().app.blrf_ws.connections[key].socket;
            socket.send(data);
            return next( { type: apiSuccess, payload: { api } } );
        default:
            return next( action );
    }
};

export default blrfWsApi;
