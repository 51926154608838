import React, { lazy }  from 'react';
import Module from './../../app/module';
import utils from './../../app/utils';

import GrainIcon from '@material-ui/icons/Grain';
import RepeatIcon from '@material-ui/icons/Repeat';
import LockIcon from '@material-ui/icons/Lock';

const baseBreadcrumb = {
    title: 'Example',
    path: utils.getFullPath('example')
};

const authPath = utils.getFullPath('example/auth');
const baseAuthBreadcrumb = {
    title: 'Auth',
    path: authPath
};

const authPage = {
    component: lazy( () => import('./views/pages/auth')),
    auth: false,
    title: 'Auth',
    icon: <LockIcon />,
    path: authPath,
    breadcrumbs: [
        baseBreadcrumb,
        baseAuthBreadcrumb
    ]
};

const pingPath = utils.getFullPath('example/ping');
const basePingBreadcrumb = {
    title: 'Ping',
    path: pingPath
};

const pingPage = {
    component: lazy(() => import('./views/pages/ping')),
    auth: false,
    title: 'Ping',
    icon: <RepeatIcon />,
    path: pingPath,
    breadcrumbs: [
        baseBreadcrumb,
        basePingBreadcrumb
    ]
};

export default class adminModule extends Module {

    getReducer() {
        return null;
    }

    getMiddleware(){
        return null;
    }

    getDefaultUri() {
        return this.getUri() + '/ping';
    }

    getNavbar(ping) {
        return [
            {
                title: 'Example',
                icon: <GrainIcon />,
                to: utils.getFullPath('example')
            },
            {
                title: 'Example dropdown',
                icon: <GrainIcon />,
                items: [
                    {
                        title: authPage.title,
                        icon: authPage.icon,
                        to: authPage.path
                    },
                    {
                        title: pingPage.title,
                        icon: pingPage.icon,
                        to: pingPage.path
                    },
                ]
            }
        ];
    }

    getSidebar(ping) {
        return [
            {
                title: authPage.title,
                icon: authPage.icon,
                to: authPage.path
            },
            {
                title: pingPage.title,
                icon: pingPage.icon,
                to: pingPage.path
            },
            {
                title: 'Dropdown',
                icon: <GrainIcon />,
                items: [
                    {
                        title: authPage.title,
                        icon: authPage.icon,
                        to: authPage.path
                    },
                    {
                        title: pingPage.title,
                        icon: pingPage.icon,
                        to: pingPage.path
                    },
                ]
            },
        ];
    }

    getRoutes() {
        return [
            pingPage,
            authPage
        ];
    }
};
