import React from 'react';
import { useSpring, animated } from 'react-spring'

const myFormat = (n, digits, format) => {
    if (format === undefined) {
        return n.toFixed(digits);
    }
    else {
        return format(n);
    }
}

const NumberSpring = ( { value, digits = 0, format = undefined } ) => {
    const spring = useSpring({
        number: value,
        from: { number: 0 },
    });
    return <animated.span>{spring.number.interpolate(n => myFormat(n, digits, format))}</animated.span>
}

export default NumberSpring;
