import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as operations from './operations';

import { connectedSelectField } from 'app/components/form/field/select';

function currenciesSelectImplementation ( WrappedComponent ) {
    return class extends Component {

        onSearchEvent = ( searchQuery ) => {
            const { search } = this.props;
            return search({
                refs: 0,
                query: {
                    name: `${searchQuery}*`
                }
            }, false);
        };

        map = ( currency ) => ({ key: currency.id, value: currency.id, label: `${currency.name} (${currency.sid})` });

        render( ) {
            const { select, ...rest } = this.props;
            const default_select = {
                map: this.map,
                multiple: true,
                onSearchEvent: this.onSearchEvent,
                ...select
            };

            return <WrappedComponent
                        placeholder='Search currencies by name ...'
                        select={default_select}
                        {...rest}
                    />;
        }
    };
}

const mapDispatchToProps =  ( dispatch, ownProps ) => {
    const { blrfKey = 'currencies_select' } = ownProps.select || {};
    return bindActionCreators(
        {
            search: (query) => operations.search(blrfKey, query),
        }, dispatch);
};

const mapStateToProps = ( state, ownProps ) => {
    const { blrfKey = 'currencies_select' } = ownProps.select || {};
    const { loading = false, error = false, query = { refs: 0, page: 1 }, data = []} = state.app.blrf.objects[blrfKey] || state.app.blrf.defaultObject;
    return {
        loading, 
        query, 
        data,
        error: (error !== false ? error && error.message : false)
    }
};

const currenciesSelectField = connect(mapStateToProps, mapDispatchToProps)(currenciesSelectImplementation(connectedSelectField()));

export {
    currenciesSelectField
};
