import { blrfWsOperations } from 'app/state/ducks/blrf_ws';

const connect = ( url ) => ( dispatch ) => {

    dispatch(
        blrfWsOperations.connect( {
            key: 'blrfPm',
            url
        } )
    );
}

const disconnect = ( ) => ( dispatch ) => {

    dispatch(
        blrfWsOperations.disconnect( {
            key: 'blrfPm',
        } )
    );
}

const send = ( data ) => ( dispatch ) => {
    dispatch(
        blrfWsOperations.send( {
            key: 'blrfPm', 
            data
        } )
    );
}

export {
    connect,
    disconnect,
    send
};
