/**
 * app/auth/* actions
 */
export default {

    /**
     * Initialize authentication
     */
    INIT: 'app/auth/init',

    /**
     * Login
     */
    LOGIN: 'app/auth/login/start',
    LOGIN_OK: 'app/auth/login/ok',
    LOGIN_ERROR: 'app/auth/login/error',
    LOGIN_DONE: 'app/auth/login/done',

    /**
     * When we're authenticating, BLRF Api will start to queue
     * the requests that require authentication. The appMiddleware will
     * send AUTH_QUEUE_PROCESS when auth was successfuly finished or
     * AUTH_QUEUE_FLUSH if auth failed and those actions cannot be fulfilled.
     */
    AUTH_QUEUE_PROCESS: 'app/auth/queue/process',
    AUTH_QUEUE_FLUSH: 'app/auth/queue/flush',

    /**
     * Fetch current user info
     */
    USER: 'app/auth/user/start',
    USER_OK: 'app/auth/user/ok',

    /**
     * Logout
     */
    LOGOUT: 'app/auth/logout/start',
    LOGOUT_OK: 'app/auth/logout/ok',
    LOGOUT_ERROR: 'app/auth/logout/error',

    /**
     * Change current users password
     */
    PASSWORD: 'app/auth/password/start',
    PASSWORD_OK: 'app/auth/password/ok',
    PASSWORD_ERROR: 'app/auth/password/error'

};
