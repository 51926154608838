import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as operations from './operations';

import { connectedSelectField } from 'app/components/form/field/select';

function pmTasksSelectImplementation ( WrappedComponent ) {
    return class extends Component {

        onSearchEvent = ( searchQuery ) => {
            const { search } = this.props;
            return search({
                refs: 0,
                query: {
                    name: `${searchQuery}*`
                }
            }, false);
        };

        map = ( task ) => ({ key: task.id, value: task.id, label: task.name });

        render( ) {
            const { select, ...rest } = this.props;
            const default_select = {
                map: this.map,
                onSearchEvent: this.onSearchEvent,
                ...select
            };

            return <WrappedComponent
                        placeholder='Search tasks by name ...'
                        select={default_select}
                        {...rest}
                    />;
        }
    };
}

const mapDispatchToProps =  ( dispatch, ownProps ) => {
    const { blrfKey = 'pmTasks_select' } = ownProps;
    return bindActionCreators(
        {
            search: (query) => operations.search(blrfKey, query),
        }, dispatch);
};

const mapStateToProps = ( state, ownProps ) => {
    const { blrfKey = 'pmTasks_select' } = ownProps;
    const { loading = false, error = false, query, data = []} = state.app.blrf.objects[blrfKey] || state.app.blrf.defaultObject;
    return {
        loading, 
        query, 
        data,
        error: (error !== false ? error && error.message : false)
    }
};

const pmTasksSelectField = connect(mapStateToProps, mapDispatchToProps)(pmTasksSelectImplementation(connectedSelectField()));

export {
    pmTasksSelectField
};
