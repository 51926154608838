import types from './types';
import { BLRF_WS_API } from './../../middleware/blrf_ws';

export default {

    connect: ( { key, url } ) => ({
        [ BLRF_WS_API ]: {
            key,
            url,
            types: [ types.CONNECT, types.CONNECT_OK, types.CONNECT_ERROR ]
        }
    }),

    reconnect: ( { key, url } ) => ({
        [ BLRF_WS_API ]: {
            key,
            url,
            types: [ types.CONNECT, types.CONNECT_OK, types.CONNECT_ERROR ]
        }
    }),

    disconnect: ( { key } ) => ({
        [ BLRF_WS_API ]: {
            key,
            types: [ types.DISCONNECT, types.DISCONNECT_OK, types.DISCONNECT_ERROR ]
        }
    }),

    send: ( { key, data } ) => ({
        [ BLRF_WS_API ]: {
            key, 
            data: JSON.stringify(data),
            types: [ types.SEND, types.SEND_OK, types.SEND_ERROR ]
        }
    }),

};
