import utils from './utils';
/**
 * All modules should extend this class
 */
export default class Module {

    constructor(name, config) {
        this.name = name;
        this.config = config;
    }

    getUri() {
        return utils.getFullPath(this.getName());
    }

    getDefaultUri() {
        return this.getUri();
    }

    getName() {
        if (this.name) {
            return this.name;
        }
        throw new Error('DEVELOPER ERROR: name not provided in constructor: ' + this.constructor.name)
    }
    
    getConfig() {
        return this.config;
    }

    getSidebarHeader() {
        return this.getName();
    }

    getRoutes() {
        return [];
    }

    getNavbar(user){
        return [];
    }

    getCustomSidebar(user){
        return null;
    }

    getSidebar(user){
        return [];
    }

    getReducer(){
        return null;
    }

    getMiddleware() {
        return null;
    }

}