import React from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import AppNavbarLink from './link';

/**
 * @todo Fix padding here!
 */
const AppNavbarModuleDropdownItem = ({title, icon, to, close, classes}) => (
        <MenuItem component={AppNavbarLink} to={to} onClick={close} activeClassName={classes.active}>
            {icon ? 
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                : null
            }
            <ListItemText inset primary={title} />
        </MenuItem>
);

AppNavbarModuleDropdownItem.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.node,
    to: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};
AppNavbarModuleDropdownItem.defaultProps = {
    title: null,
    icon: null,
};

export default AppNavbarModuleDropdownItem;
