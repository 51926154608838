import React, { Fragment, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';

import DotObject from 'dot-object';

import { TableMeta, TableHeader, TableBody, TableSearch } from './table/index.js';

import Loading from './loading';
import Fade from '@material-ui/core/Fade';

import { default as MuiTable } from '@material-ui/core/Table';

/**
 * Each field can have the following properties:
 *
 * - label: Display label
 * - id: Field id (for search)
 * - width: Width of field (for search)
 * - searchable: bool: Is field displayed in search form
 * - displayed: bool: Is field displayed in table as column
 * - sortable: bool: Can we sort by this field
 */
const Table = ( { 
    query,
    search,
    defaultValues,
    data,
    loading,
    meta,
    fields,
    renderRow,
    actions,
    error,
    /**
     * Can be outlined
     */
    variant = undefined
} ) => {

    /**
     * Get displayed and searchable fields and initialValues
     */
    const { displayFields, searchFields, initialValues } = useMemo( () => {
        const initialValues = {};
        const displayFields = [];
        const searchFields = [];

        const dot = new DotObject('.', false, true);

        fields.forEach( f => {
            if (f.displayed !== false) {
                displayFields.push(f);
            }
            if (f.searchable !== false) {
                searchFields.push(f);
                if (f.name) {
                    dot.str(f.name, f.value === undefined ? '' : f.value, initialValues);
                }

            }
        })
        return {
            initialValues,
            displayFields,
            searchFields
        }
    }, [ fields ]);


    const searchRender = useMemo( () => {
        if (search && searchFields.length > 0) {
            return (
                <TableSearch
                    error={error}
                    loading={loading}
                    search={search}
                    query={query}
                    fields={searchFields}
                    initialValues={initialValues}
                    defaultValues={defaultValues}
                    variant={variant} />
            );
        }
        return null;
    }, [ error, loading, search, query, searchFields, initialValues, defaultValues ])

    const metaRender = useMemo( () => {
        if (meta !== false) {
            return (<TableMeta loading={loading} meta={meta} search={search} query={query} />);
        }
        return null;
    }, [ meta, loading, search, query ]);

    const headerRender = useMemo( () => {
        return (<TableHeader meta={meta} search={search} query={query} fields={displayFields} actions={actions} />);
    }, [ meta, search, query, displayFields, actions ]);

    const dataRender = useMemo( () => {
        return (<TableBody data={data} renderRow={renderRow} actions={actions} />);
    }, [ data, renderRow, actions ]);

    const loadingRender = useMemo( () => {
        if (loading) {
            return (
                <Fade
                    in={loading}
                    style={{
                        transitionDelay: loading ? '800ms' : '0ms',
                    }}
                    unmountOnExit
                >
                    <div style={{ padding: 8, display: 'flex', position: 'absolute', left: '0%', right: '0%', justifyContent: 'center' }}>
                        <Loading loading={loading} color='primary' />
                    </div>
                </Fade>
            );
        }
        return null;
    }, [ loading ] );

    useEffect( () => {
        if (search) {
            search( query );
        }
    }, [ search, query ]);

    return (
        <Fragment>
            {searchRender}
            <Paper variant={variant} style={{marginTop: 8, marginBottom: 20, overflowX : 'auto' }}>
                {metaRender}
                <MuiTable padding='default' size='small'>
                    {headerRender}
                    {dataRender}
                </MuiTable>
                {loadingRender}
                {metaRender}
            </Paper>
        </Fragment>
    );
}

Table.propTypes = {
    /**
     * Last query
     */
    query: PropTypes.object,
    /**
     * Search action that accepts query
     */
    search: PropTypes.func,
    /**
     * Default values when we perform the search
     * form reset.
     */
    defaultValues: PropTypes.object,
    /**
     * Data from last search
     */
    data: PropTypes.array,
    /**
     * Are we loading table data?
     */
    loading: PropTypes.bool,
    /**
     * Meta from last search
     *
     * false= No meta available
     * object=Meta object
     */
    meta: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object
    ]),
    /**
     * All available fields
     *
     * Each field is an object with following properties: (@todo write all)
     *
     * - label (string): Name of field
     * - display (bool): Is field displayed in table 
     */
    fields: PropTypes.array.isRequired,
    /**
     * Function that will return row cells to render
     */
    renderRow: PropTypes.func.isRequired,
    /**
     * Array of actions
     */
    actions: PropTypes.array,
};

Table.defaultProps = {
    query: {},
    search: null,
    defaultValues: {},
    data: [],
    loading: false,
    meta: false,
    actions: []
}

export default Table;