import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import { default as MuiButton } from '@material-ui/core/Button';
import { default as MuiFab } from '@material-ui/core/Fab';

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'relative',
        display: 'inline',
    },
    wrapperPadding: {
        position: 'relative',
        display: 'inline',
        paddingRight: '5px'
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}) );

const Button = React.forwardRef( ({ loading, disabled, noPadding = false, ...rest }, ref)  => {

    const classes = useStyles();

    const renderProgress = useMemo(
        () => loading && <CircularProgress size={24} className={classes.buttonProgress} />,
        [  loading, classes ]
    );

    return (
        <div className={noPadding ? classes.wrapper : classes.wrapperPadding}>
            <MuiButton
                ref={ref}
                disabled={loading || disabled}
                {...rest}
            />
            {renderProgress}
        </div>
    );
});

Button.propTypes = {
    loading: PropTypes.bool,
    disabled: PropTypes.bool
};

Button.defaultProps = {
    loading: false,
    disabled: false
};

export default Button;

const Fab = ( { loading, disabled, ...rest } ) => {

    const classes = useStyles();

    const renderProgress = useMemo(
        () => loading && <CircularProgress size={24} className={classes.buttonProgress} />,
        [  loading, classes ]
    );

    return (
        <div className={classes.wrapper}>
            <MuiFab
                disabled={loading || disabled}
                {...rest}
            />
            {renderProgress}
        </div>
    );
}

Fab.propTypes = {
    loading: PropTypes.bool,
    disabled: PropTypes.bool
};

Fab.defaultProps = {
    loading: false,
    disabled: false
};

export {
    Fab
}
