/**
 * blrf_ws/.../... actions
 */
export default {
    /**
     * Reconnect to WS
     */
    RECONNECT: 'blrf_ws/reconnect/start',
    /**
     * Connect to WS
     */
    CONNECT: 'blrf_ws/connect/start',
    CONNECT_OK: 'blrf_ws/connect/ok',
    CONNECT_ERROR: 'blrf_ws/connect/error',

    /**
     * Disconnect from WS
     */
    DISCONNECT: 'blrf_ws/disconnect/start',
    DISCONNECT_OK: 'blrf_ws/disconnect/ok',
    DISCONNECT_ERROR: 'blrf_ws/disconnect/error',

    SEND: 'blrf_ws/send/start',
    SEND_OK: 'blrf_ws/send/ok',
    SEND_ERROR: 'blrf_ws/send/error',

    MESSAGE: 'blrf_ws/message'

};
