import { fetch } from './../utils';
import config from './../../../config';
import authTypes from './../ducks/auth/types';
/**
 * BLRF API object should look like this:
 *
 * - endpoint: URI to call
 * - method: HTTP method to use
 * - data: Optional data to send
 * - auth: Require authentication data
 * - types: Array of type of actions:
 *      0: Action 1: Success action 2: Fail action
 */
export const BLRF_API = Symbol('BLRF API');

/**
 * The BLRF Queue for actions that require authentication 
 * but we're currently authenticating (eg refreshing access token)
 *
 * Objects as: 
 * {
 *   action: Action to run
 *   promise: Promise to resolve (that was returned to action)
 * }
 *
 */
let BLRF_Queue = [];

/**
 * Create an empty promise that will be resolved when we 
 * process the queue
 */
function createPromise( ){
    var _resolve, _reject;

    var promise = new Promise(function(resolve, reject){
        _resolve = resolve; 
        _reject = reject;
    })

    promise.resolve = _resolve;
    promise.reject = _reject;

    return promise;
}

/**
 * Upon each action received, middleware will check if action 
 * is notifying as to PROCESS or FLUSH the queue
 */
const checkQueue = ( dispatch , action ) => {
    if (action.type && 
        (
            action.type === authTypes.AUTH_QUEUE_PROCESS ||
            action.type === authTypes.AUTH_QUEUE_FLUSH
        )
        && BLRF_Queue.length > 0) {
        if (action.type === authTypes.AUTH_QUEUE_PROCESS) {
            BLRF_Queue.map( async (a) => {
                const ret = await dispatch ( a.action );
                a.promise.resolve( ret );
            });
        }
        BLRF_Queue = [];
    }
}

/**
 * Authentication is stored in LocalStorage
 */

function getAuthHeaders( ) {

    const tokenType = window.localStorage.getItem('token_type');
    const accessToken = window.localStorage.getItem('access_token');

    if ( tokenType === null || accessToken === null ) {
        return null;
    }

    return {
        Authorization: `${tokenType} ${accessToken}`
    };
}

const blrfApi = ( store ) => ( next ) => async ( action ) => {

    const api = action[BLRF_API];

    /**
     * Check the BLRF Action queue
     */
    checkQueue ( store.dispatch, action );

    if (typeof api === 'undefined') {
        return next( action );
    }

    // read data from api
    const { endpoint, method = 'GET', data = null, auth = true, types } = api;
    // load action types
    const [ apiAction, apiSuccess, apiFail, ] = types;

    const authenticating = store.getState().app.auth.authenticating;

    /**
     * Check if authentication is required and if we are currently authenticating.
     * If both are true we need to create new empty Promise and push this action
     * to the queue to be processed later when app middleware will notify us with AUTH_QUEUE_*
     * actions.
     */
    if (auth === true && authenticating === true) {
        const actionPromise = createPromise();
        BLRF_Queue.push({
            action: action,
            promise: actionPromise
        });
        return actionPromise;
    }

    const defaultHeaders = {
        'X-Requested-With': 'BLRF UI for Web',
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json, text/plain, */*'
    };


    store.dispatch( { type: apiAction, payload: { api } } );

    // start the request
    try {
        let headers = defaultHeaders;
        const { url } = config.api;
        const fullUrl = `${url}${endpoint}`;

        // check if authentication was requested
        if (auth) {
            const authHeaders = getAuthHeaders( );
            if ( authHeaders === null ) {
                throw new Error('BUG: Authentication not available');
            }
            headers = { ...defaultHeaders, ...authHeaders };
        }
        const res = await fetch(fullUrl, method, headers, data);
        res.api = api;
        const nextAction = { type: apiSuccess, payload: res };
        return next( nextAction );
    } catch ( e ) {
        console.error(e);
        /**
         * We get different errors here, we need to check that!
         *
         * We may get: 
         * - Error.message
         * - e.status (http code), e.response.error, e.response.message 
         */
        let error = 'Unknown error';
        if (e.response && e.response.message) {
            error = e.response;
        }
        if (e.response && e.response.error) {
            error = e.response;
        }
        const payload = {
            error,
            api: api
        };
        const nextAction = { type: apiFail, payload };
        return next( nextAction );
    }
};

export default blrfApi;
