import React, { useMemo, useCallback } from 'react';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Tooltip from '@material-ui/core/Tooltip';

/**
 * Order by 
 *
 * field [ASC|DESC] - default is ASC if not provided
 */

const TableHeader = ({fields, actions, meta, search, query, ...props}) => {

    /**
     * Order by is an array 
     * with each field being user with optional ASC/DESC
     */
    const order = useMemo( ( ) => {
        const obj = {};
        if (meta && Array.isArray(meta.order))  {
            meta.order.map( o => {
                const ret = o.match(/([a-zA-Z0-9_]+) *(.*)/);
                return obj[ret[1]] = (ret[2] ? ret[2].toLowerCase() : 'asc');
            });
        }
        return obj;
    }, [ meta ]);

    const renderField = useCallback( ( field ) => {


        const createSortHandler = name => event => {
            /**
             * For now, we support only one sort by
             */
            let query_order = [];
            if (order[name]) {
                query_order.push(`${name} ${order[name] === 'asc' ? 'desc' : 'asc'}`)
            } else {
                query_order.push(`${name} asc`);
            }
            search({
                ...query,
                order: query_order
            });
        }

        if (field.sortable === undefined || field.sortable === true) {
            return (
                <Tooltip
                  title={`Sort by ${field.label}`}
                  placement={field.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={order.hasOwnProperty(field.name)}
                    direction={order[field.name] ? order[field.name] : 'asc'}
                    onClick={createSortHandler(field.name)}
                  >
                    {field.label.toUpperCase()}
                  </TableSortLabel>
                </Tooltip>
            );
        } else {
            return field.label.toUpperCase();
        }
    }, [ order, search, query ] );

    const renderActions = useMemo( ( ) => (
        actions.length > 0 ? <TableCell variant='head' key='actions'>ACTIONS</TableCell> : null
    ), [ actions ] );

    const renderFields = useMemo(
        () => fields.map(
            (field, key ) =>
                <TableCell 
                    variant='head' 
                    key={key}>
                    { renderField( field ) }
                </TableCell>  
        ),
    [ fields, renderField ] );

    return (
        <TableHead>
            <TableRow>
                {renderFields}
                {renderActions}
            </TableRow>
        </TableHead>
    );

};

export default TableHeader;
