import React from 'react';

import Alert from '@material-ui/lab/Alert';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import { default as LoginForm } from './../../../../../app/views/forms/login';

const AuthLogin = ({ authObject, ...props}) => {

    const { authenticated } = authObject;

    return (
        <Card>
            <CardHeader title='Login form' />
            <CardContent>
                { authenticated 
                    ? 
                    <Alert severity='success'> You are logged in!</Alert>
                    :
                    <LoginForm
                        initialValues={{
                            username: '',
                            password: ''
                        }}
                        onSubmit={() => console.log('submit') }
                        {...props}
                    /> 
                }
            </CardContent>
        </Card>
    );

};

export default AuthLogin;
