import React, { lazy }  from 'react';
import Module from './../../app/module';
import utils from './../../app/utils';

import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import SettingsIcon from '@material-ui/icons/SettingsApplications';
import ViewListIcon from '@material-ui/icons/ViewList';
import ReceiptIcon from '@material-ui/icons/Receipt';
import EuroIcon from '@material-ui/icons/Euro';

const baseBreadcrumb = {
    title: 'CoinFolio',
    path: utils.getFullPath('coin')
};

const dashboardPath = utils.getFullPath('coin/dashboard');
const baseDashboardBreadcrumb = {
    title: 'Dashboard',
    path: dashboardPath
};

const dashboardPage = {
    component: lazy( () => import('./views/pages/dashboard')),
    title: 'Dashboard',
    icon: <DashboardIcon />,
    path: dashboardPath,
    breadcrumbs: [
        baseBreadcrumb,
        baseDashboardBreadcrumb
    ]
};

const balancePath = utils.getFullPath('coin/balance');
const baseBalanceBreadcrumb = {
    title: 'Balance',
    path: balancePath
};

const balancePage = {
    component: lazy( () => import('./views/pages/balance')),
    title: 'Balance',
    icon: <AccountBalanceIcon />,
    path: balancePath,
    breadcrumbs: [
        baseBalanceBreadcrumb
    ]
};

const balanceCurrencyPath = utils.getFullPath('coin/balance/currency/:cid');

const balanceCurrencyPage = {
    component: lazy( () => import('./views/pages/balance/currency')),
    title: 'Currency',
    icon: <AccountBalanceIcon />,
    path: balanceCurrencyPath,
    breadcrumbs: [
        baseBalanceBreadcrumb,
        {
            title: 'Currency'
        }
    ]
};

const ordersPath = utils.getFullPath('coin/orders');
const baseOrdersBreadcrumb = {
    title: 'Orders',
    path: ordersPath
};

const ordersPage = {
    component: lazy( () => import('./views/pages/orders')),
    title: 'Orders',
    icon: <ViewListIcon />,
    path: ordersPath,
    breadcrumbs: [
        baseBreadcrumb,
        baseOrdersBreadcrumb
    ]
};

const orderPage = {
    component: lazy(() => import('./views/pages/order')),
    title: 'Order',
    icon: <ViewListIcon />,
    path: utils.getFullPath('coin/orders/:action/:oid?'),
    breadcrumbs: [
        baseBreadcrumb,
        baseOrdersBreadcrumb,
        {
            title: 'Order'
        }
    ]
};

const transactionsPath = utils.getFullPath('coin/transactions');
const baseTransactionsBreadcrumb = {
    title: 'Transactions',
    path: transactionsPath
};

const transactionsPage = {
    component: lazy( () => import('./views/pages/transactions')),
    title: 'Transactions',
    icon: <ReceiptIcon />,
    path: transactionsPath,
    breadcrumbs: [
        baseBreadcrumb,
        baseTransactionsBreadcrumb
    ]
};

const currenciesPath = utils.getFullPath('coin/currencies');
const baseCurrenciesBreadcrumb = {
    title: 'Currencies',
    path: currenciesPath
};

const currenciesPage = {
    component: lazy( () => import('./views/pages/currencies')),
    title: 'Currencies',
    icon: <EuroIcon />,
    path: currenciesPath,
    breadcrumbs: [
        baseBreadcrumb,
        baseCurrenciesBreadcrumb
    ]
};

const adminPath = utils.getFullPath('coin/admin');
const baseAdminBreadcrumb = {
    title: 'Admin',
    path: adminPath
};

const adminPage = {
    component: lazy( () => import('./views/pages/admin')),
    title: 'Admin',
    icon: <SettingsIcon />,
    path: adminPath,
    breadcrumbs: [
        baseBreadcrumb,
        baseAdminBreadcrumb
    ]
};

export default class coinModule extends Module {

    getReducer() {
        return null;
    }

    getMiddleware(){
        return null;
    }

    getDefaultUri() {
        return this.getUri() + '/dashboard';
    }

    getNavbar() {
        return [
            {
                title: 'CoinFolio',
                icon: <MonetizationOnIcon />,
                to: utils.getFullPath('coin')
            }
        ];
    }

    getSidebar(user) {
        const ret = [];
        if (user && user.access.coin) {
            const { coin } = user.access;
            if (coin.balance) {
                ret.push({
                    title: dashboardPage.title,
                    icon: dashboardPage.icon,
                    to: dashboardPage.path
                });
                ret.push({
                    title: balancePage.title,
                    icon: balancePage.icon,
                    to: balancePage.path
                });
            }
            if (coin.order) {
                ret.push({
                    title: ordersPage.title,
                    icon: ordersPage.icon,
                    to: ordersPage.path
                });
            }
            if (coin.transaction) {
                ret.push({
                    title: transactionsPage.title,
                    icon: transactionsPage.icon,
                    to: transactionsPage.path
                });
            }
            if (coin.currency) {
                ret.push({
                    title: currenciesPage.title,
                    icon: currenciesPage.icon,
                    to: currenciesPage.path
                });
            }
            if (coin.admin) {
                ret.push({
                    title: adminPage.title,
                    icon: adminPage.icon,
                    to: adminPage.path
                });
            }
        }
        return ret;
    }

    getRoutes() {
        return [
            dashboardPage,
            balancePage,
            balanceCurrencyPage,
            ordersPage,
            orderPage,
            transactionsPage,
            currenciesPage,
            adminPage
        ];
    }
};
