import React, { Fragment, useCallback, useMemo, useEffect } from 'react';

import { useTheme } from '@material-ui/styles';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { dashboardOperations } from './../../state/ducks/dashboard';
import { userComponents } from './../../state/ducks/user';

import { default as MuiLink } from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Statistic, { NumberSpring } from 'app/components/statistic';
import Table from 'app/components/table';

import DashboardChartDaily from './dashboard/dailyChart';

import coinUtils from '../../utils';
import utils from 'app/utils';

/**
 * Nivo charts theme
 */
const defaultChartsTheme = (theme) => ({
    textColor: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,

    axis: {
        ticks: {
            text: {
                fill: theme.palette.text.primary,
            }
        },
        legend: {
            text: {
                fill: theme.palette.text.primary,
            }
        }
    },
    tooltip: {
        container: {
            background: theme.palette.background.default
        }
    }
});

const DashboardPage = () => {

    const muiTheme = useTheme();

    const chartsTheme = defaultChartsTheme(muiTheme);

    /**
     * Take dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Query dashboard via Api
     */
    const getData = useCallback( query => dispatch( dashboardOperations.getData( 'dashboard', query ) ), [ dispatch ] );

    const clear = useCallback( () => dispatch( dashboardOperations.clear( 'dashboard' ) ), [ dispatch ] );

    /**
     * dashboard blrf object
     */
    const dashboardObject = useSelector( state => state.app.blrf.objects.dashboard || state.app.blrf.defaultObject );

    const { data, meta } = useMemo( () => {
        return {
            data: dashboardObject.data,
            meta: dashboardObject.meta
        }
    }, [ dashboardObject ]);

    const query = useMemo( () => {
        /**
         * When object is first created query will only contain { refs: 0, page: 1 }
         *
         * We want refs, so we change that. And we want to have default dashboard.
         * All other aspects of query object is controlled via
         * Table component.
         */
         const hasOrder = Array.isArray(dashboardObject.query.dashboard) && dashboardObject.query.dashboard.length > 0;
         if (dashboardObject.query.refs === 0 || !hasOrder) {
            return { ...dashboardObject.query, refs: ['User', 'Exchange', 'Currency'], dashboard: (hasOrder ? [...dashboardObject.query.dashboard ] : ['amount DESC'] ) };
         }
         return dashboardObject.query;
    }, [ dashboardObject.query ] );



    const dailyData = useMemo( () => {
        if (data && data.total && data.total.daily) {
            console.log('dailyData: ', data.total.daily);
            return data.total.daily.map(d => ({
                x: d.dt,
                y: parseFloat(d.total)
            }));
        }
        return [];
    }, [ data ]);

    const {
        loading,
        error
    } = dashboardObject;

    useEffect( () => {
        getData(query);
        return clear;
    }, [ getData, query ])

    console.log('data: ', data);

    const eur = {
      'sid'     : 'EUR',
      'decimals': 2
    }

    const fields = [
        {
            id: 'currency',
            name: 'currency',
            label: 'Currency',   
        },
        {
            label: 'Amount',
            name: 'amount',
            id: 'amount'

        },
        {
            label: 'Previous month',
            name: '1month',
            id: '1month'
        },
        {
            label: 'Previous week',
            name: '1month',
            id: '1month'
        },
        {
            label: 'Yesterday',
            name: 'yesterday',
            id: 'yesterday'
        },
        {
            label: 'Today',
            name: 'today',
            id: 'today'
        }
    ];

    const renderRow = (d, i) => ({
        key: i,
        cells: [
            <MuiLink component={Link} to={utils.getFullPath(`coin/balance/currency/${d.currency.id}`)}>{`${d.currency.name}`}</MuiLink>,
            coinUtils.formatCurrency(d.currency, d.today.amount),
            coinUtils.formatCurrency(eur, d['1m'].total),
            coinUtils.formatCurrency(eur, d['1w'].total),
            coinUtils.formatCurrency(eur, d['yesterday'].total),
            <b>{coinUtils.formatCurrency(eur, d['today'].total)}</b>
        ]
    });

    return (
        <Fragment>
        {data && data.total &&
        <Grid container spacing={2}>
            <Grid item xs={12}>
            
            </Grid>
            <Grid item xs={12}>
                <Typography
                    variant='h1'
                    align='center'>
                    <NumberSpring
                        value={parseFloat(data.total['today'].total)}
                        format={ (n) =>  coinUtils.formatCurrency(eur, n) } />
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Statistic
                    label={['1 month', 'Change']}
                    value={[parseFloat(data.total['1m'].total, 10), parseFloat(data.total['1m'].change)]}
                    format={[
                        (n) => coinUtils.formatCurrency(eur, n),
                        (n) => coinUtils.formatPercent(n)
                    ]}
                    />
            </Grid>
            <Grid item xs={4}>
                <Statistic
                    label={['1 week', 'Change']}
                    value={[parseFloat(data.total['1w'].total, 10), parseFloat(data.total['1w'].change)]}
                    format={[
                        (n) => coinUtils.formatCurrency(eur, n),
                        (n) => coinUtils.formatPercent(n)
                    ]}
                    />
            </Grid>
            <Grid item xs={4}>
                <Statistic
                    label={['Yesterday', 'Change']}
                    value={[parseFloat(data.total['yesterday'].total, 10), parseFloat(data.total['yesterday'].change)]}
                    format={[
                        (n) => coinUtils.formatCurrency(eur, n),
                        (n) => coinUtils.formatPercent(n)
                    ]}
                    />
            </Grid>
            <Grid item xs={12}>
                <DashboardChartDaily
                    data={dailyData}
                    theme={chartsTheme}
                    />
            </Grid>
            <Grid item xs={12}>
                <Table
                    fields={fields}
                    renderRow={renderRow}
                    data={data.currency}
                />
            </Grid>
        </Grid>
        }
        </Fragment>
    );
};

export default DashboardPage;
