import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';

class Confirm extends React.Component {

    static propTypes = {
        open: PropTypes.bool,
        title: PropTypes.string,
        content: PropTypes.string.isRequired,
        onClose: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        onConfirm: PropTypes.func.isRequired,
        cancelText: PropTypes.string,
        confirmText: PropTypes.string
    };

    static defaultProps = {
        open: false,
        title: null,
        cancelText: 'Cancel',
        confirmText: 'Confirm'
    };

    render() {

        const {
            open,
            title,
            content,
            onClose,
            onCancel,
            onConfirm,
            cancelText,
            confirmText
        } = this.props;

        if (!open) return null;

        return (
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                { title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle> }
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="primary" autoFocus>
                        <CancelIcon />
                        {cancelText}
                    </Button>
                    <Button onClick={onConfirm} color="secondary">
                        <CheckIcon />
                        {confirmText}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default Confirm;
