import React, { Fragment } from 'react';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import NumberSpring from './statistic/numberspring';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        border: `1px solid ${theme.palette.divider}`
    },
    label: {
        textAlign: 'center',
        margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px`,
    },
    value: {
        textAlign: 'center',
        margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px`,
        fontSize: 24
    },

    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px`,
        textAlign: 'center',
        borderBottom: `1px solid ${theme.palette.divider}`
    },
});

const StatisticAnimatedValue = ( { value, digits, format } ) => {
    return (
        <NumberSpring
            value={value} digits={digits} format={format} />
    );

};

const StatisticValue = ( { value, digits, format } ) => {
    if (Array.isArray(value)) {
        const valComponents = value.map((v, k) => {
            if (isNaN(v)) {
                return (<Fragment key={k}>
                            {k > 0 ? ' / ' : ''}
                            {v}
                        </Fragment>);
            } 
            return (
                    <Fragment key={k}>
                        {k > 0 ? ' / ' : ''}
                        <StatisticAnimatedValue value={v} digits={digits} format={format && format[k] ? format[k] : undefined}/>
                    </Fragment>
                );
        });
        return valComponents;
    } else {
        if (isNaN(value)) {
            return value;
        }
        return <StatisticAnimatedValue value={value} digits={digits} format={format} />   
    }
};

const Title = ( { classes, title } ) => {
    return (
        <Typography className={classes.title}>
            {title}
        </Typography>
    );
}

const Statistic = ( { classes, label, icon = undefined, value = undefined, title = undefined, format = undefined, digits  = 0} ) => {
    return (
        <div className={classes.root}>
            { title !== undefined && <Title title={title} classes={classes} />}
            <Typography className={classes.value}>
                { icon !== undefined && icon }
                { value !== undefined && <StatisticValue value={value} digits={digits} format={format} /> }
            </Typography>
            <Divider variant='inset' />
            <Typography variant='caption' className={classes.label}>{Array.isArray(label) ? label.join('/') : label}</Typography>
        </div>
    );
}

export default withStyles(styles)(Statistic);

export {
    NumberSpring
};
