import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import AppSidebarLink from './link';

const styles = theme => ({

});

const AppSidebarModuleItem = ({classes, title, icon, to, currentUri, ...rest}) => {

    return (
        <MenuItem 
            button
            component={AppSidebarLink}
            to={to}
            classes={classes}
            {...rest}>
            {icon ? 
                <ListItemIcon style={{ color: 'white' }}>
                    {icon}
                </ListItemIcon>
                : null
            }
            <ListItemText
                primary={title}
                classes={{ inset: classes.sidebarList }}
                primaryTypographyProps={{
                    style: { color: 'white', fontWeight: 400 }
                }}
                />
        </MenuItem>
    );
};

AppSidebarModuleItem.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
    icon: PropTypes.node,
    to: PropTypes.string.isRequired,
    currentUri: PropTypes.string.isRequired
};
AppSidebarModuleItem.defaultProps = {
    title: null,
    icon: null,
};

export default withStyles(styles)(AppSidebarModuleItem);
