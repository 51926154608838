import { blrfOperations } from 'app/state/ducks/blrf';

const { clear } = blrfOperations;

const get = ( key, id ) => async ( dispatch ) => {

    return await dispatch ( blrfOperations.get( {
        endpoint: 'coin/order',
        key,
        id
    }) );
    
};

const update = ( key, id, data ) => async ( dispatch ) => {

    return await dispatch ( blrfOperations.update( {
        endpoint: `coin/order/${id}`,
        key,
        data
    }) );

};

const create = ( key, data ) => async ( dispatch ) => {

    return await dispatch ( blrfOperations.create( {
        endpoint: 'coin/order',
        key,
        data
    }) );

};

const search = ( key, data ) => ( dispatch ) => {

    return dispatch ( blrfOperations.search( {
        endpoint: 'coin/order/search',
        key, 
        data
    }) );

};

const remove = ( key, id ) => async ( dispatch ) => {

    return await dispatch ( blrfOperations.remove( {
        endpoint: `coin/order/${id}`,
        key
    }) );

};

/**
 * Add user to order with percetage
 *
 * data:
 *
 * {
 *  order_id: 
 *  user_id:
 *  percentage:
 * }
 */
const addUser = ( key, data ) => ( dispatch ) => {
    return dispatch ( blrfOperations.create( {
        endpoint: 'coin/order/adduser',
        key, 
        data
    }) );
};

/**
 * Remove user from order
 *
 * data:
 *
 * {
 *  order_id: 
 *  user_id:
 * }
 */
const removeUser = ( key, data ) => ( dispatch ) => {
    return dispatch ( blrfOperations.create( {
        endpoint: 'coin/order/removeuser',
        key, 
        data
    }) );
};

/**
 * Automaticly fill order users based on percetage of total they have
 *
 * data:
 *
 * {
 *   order_id:
 *   user_ids: [] (optional)
 * }
 */
const fillUsers = ( key, data ) => ( dispatch ) => {
    return dispatch ( blrfOperations.create( {
        endpoint: 'coin/order/fillusers',
        key,
        data
    }) );
};

const process = ( key, id ) => async ( dispatch ) => {

    return await dispatch ( blrfOperations.get( {
        endpoint: 'coin/order/process',
        key,
        id
    }) );
    
};

const unprocess = ( key, id ) => async ( dispatch ) => {

    return await dispatch ( blrfOperations.get( {
        endpoint: 'coin/order/unprocess',
        key,
        id
    }) );
    
};

export {
    get, 
    update, 
    create,
    search,
    remove,
    addUser,
    removeUser,
    fillUsers,
    process,
    unprocess,
    clear
};
