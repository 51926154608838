import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import * as Yup from 'yup';

import { openSnackbar } from 'app/components/snackbar';

import { OAuth2ClientOperations } from './../../../state/ducks/oauth2/client';

import ViewEdit from 'app/components/viewedit';
import ErrorAlert from 'app/components/error';
import LinearProgress from '@material-ui/core/LinearProgress';

import utils from 'app/utils';

const clientValidation = Yup.object().shape({
    id:   Yup.string()
             .min(2, 'Id too short')
             .max(50, 'Id too long')
             .required('Id is required')
             .matches(/^[0-9A-Za-z_]+$/, {
                            message:'Only numbers, letters and underscore allowed',
                            excludeEmptyString: true
                        }),
    name: Yup.string()
             .min(2, 'Name too short')
             .max(50, 'Name too long')
             .required('Name is required'),
    secret: Yup.string()
            .min(2, 'Secret too short')
            .required('Secret is required'),
    redirect_uri: Yup.string().url().nullable()
});

const emptyClient = {
    id: '',
    name: '',
    secret: '',
    redirect_uri: ''
};

/**
 * Extract action and cid from react-router match prop
 */
const OAuth2ClientPage = ( { match: { params: { action, cid } } } ) => {

    /**
     * Are we editing
     */
    const edit = (action === 'add' || action === 'edit');

    console.log('cid: ', cid);

    /**
     * Dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Get client from Api
     */
    const get = useCallback( id => dispatch( OAuth2ClientOperations.get( 'client', id ) ) , [ dispatch ]);
    /**
     * Update client via Api
     */
    const update = useCallback( ( id, data ) => dispatch( OAuth2ClientOperations.update( 'client', id, data ) ), [ dispatch ] );
    /**
     * Create new client via Api
     */
    const create = useCallback( data => dispatch( OAuth2ClientOperations.create( 'client', data ) ) , [ dispatch ] );
    /**
     * Clean client blrf object
     */
    const clear = useCallback( () => dispatch( OAuth2ClientOperations.clear( 'client' ) ), [ dispatch ] );
    /**
     * Fetch client blrf object. If object is not yet available return default blrf object stored in state.
     */
    const clientObject = useSelector( state => state.app.blrf.objects.client || state.app.blrf.defaultObject );

    /**
     * Get client from blrf object data
     */
    const client = useMemo( () => {
        return ( action === 'add' ? emptyClient : clientObject.data.find(v => v) || emptyClient)
    }, [ clientObject.data, action ]);

    /**
     * Get loading and error from blrf client object
     */
    const { loading, error } = clientObject;

    /**
     * Call get client from Api if cid changed
     *
     * And register unmount function that will clear the client object.
     * This is important because, when we later arrive to this page and
     * cid has changed child components will still see the old client and
     * we will get double queries. One for old and one for new and data
     * will most likely be incorrect.
     */
    useEffect( () => {
        if (cid && cid.length > 0) {
            get( cid );
        }
        return clear;
    }, [ cid, get, clear ]);

    const fields = [
            {
                label: 'Id',
                name: 'id',
                value: client.id,
                width: 6
            },
            {
                label: 'Name',
                name: 'name',
                width: 6,
                value: client.name
            },
            {
                label: 'Redirect Uri',
                name: 'redirect_uri',
                width: 6,
                value: client.redirect_uri
            },
            {
                label: 'Secret',
                name: 'secret',
                width: 6,
                value: client.secret
            },
            {
                label: 'Created at',
                name: 'created_at',
                value: client.created_at,
                width: 3,
                edit: false
            },
            {
                label: 'Created by',
                name: 'created_by',
                value: client.created_by,
                width: 3,
                edit: false,
            },
            {
                label: 'Updated at',
                name: 'updated_at',
                value: client.updated_at,
                width: 3,
                edit: false,
            },
            {
                label: 'Updated by',
                name: 'updated_by',
                value: client.updated_by,
                width: 3,
                edit: false
            },
        ];

    const onSubmit = ( values ) => {
        let p = null;
        if (action === 'add') {
            p = create(values);
        } else {
            p = update( values.id, values );
        }
        p.then(ret => {
            if (ret.type.indexOf('error') > 0) {
                openSnackbar('Error while saving client', 'error');
            } else {
                openSnackbar('Client saved', 'success');
                window.history.go( -1 );
            }
        } );
        return p;
    }

    return (
        <Fragment>
            {error === false && loading && <LinearProgress />}
            {error !== false && <ErrorAlert error={error} />}
            {client &&
                <ViewEdit
                    header={{ title: (client.id.length > 0 ? 'Edit client' : 'Add client') }}
                    onSubmit={onSubmit}
                    fields={fields}
                    validationSchema={clientValidation}
                    loading={loading}
                    edit={edit}
                    editButton={{ component: Link, to: utils.getFullPath(`admin/oauth2/client/edit/${client.id}`) }}
                    />
            }
        </Fragment>
    );
}

OAuth2ClientPage.propTypes = {
    match: PropTypes.object.isRequired
};

export default OAuth2ClientPage;

