import { blrfOperations } from 'app/state/ducks/blrf';

const { clear } = blrfOperations;

const get = ( key, id ) => ( dispatch ) => {

    dispatch ( blrfOperations.get( {
        endpoint: 'admin/role',
        key,
        id
    }) );
    
};

const update = ( key, id, data ) => async ( dispatch ) => {

    return await dispatch ( blrfOperations.update( {
        endpoint: `admin/role/${id}`,
        key,
        data
    }) );

};

const create = ( key, data ) => async ( dispatch ) => {

    return await dispatch ( blrfOperations.create( {
        endpoint: 'admin/role',
        key,
        data
    }) );

};

const remove = ( key, id ) => async ( dispatch ) => {

    return await dispatch ( blrfOperations.remove( {
        endpoint: `admin/role/${id}`,
        key
    }) );

};

const search = ( key, data ) => ( dispatch ) => {

    return dispatch ( blrfOperations.search( {
        endpoint: 'admin/role/search',
        key, 
        data
    }) );

};

const getAcl = ( key, id ) => ( dispatch ) => {

    dispatch( blrfOperations.get({
        'endpoint': `admin/role/${id}/acl`,
        key
    }) );
}

const updateAcl = (key, id, data) => dispatch => {

    return dispatch( blrfOperations.update( {
        'endpoint': `admin/role/${id}/acl`,
        key,
        data
    }) );
}

export {
    get, 
    update, 
    create,
    remove,
    search,
    getAcl,
    updateAcl,
    clear
};
