import { blrfOperations } from 'app/state/ducks/blrf';

const { clear } = blrfOperations;

const get = ( key, id ) => async ( dispatch ) => {

    return await dispatch ( blrfOperations.get( {
        endpoint: 'admin/oauth2/authcode',
        key,
        id
    }) );
    
};

const search = ( key, data ) => ( dispatch ) => {

    return dispatch ( blrfOperations.search( {
        endpoint: 'admin/oauth2/authcode/search',
        key, 
        data
    }) );

};

const remove = ( key, id ) => async ( dispatch ) => {

    return await dispatch ( blrfOperations.remove( {
        endpoint: `admin/oauth2/authcode/${id}`,
        key
    }) );

};

const create = ( key, data ) => async ( dispatch ) => {

    return await dispatch ( blrfOperations.create( {
        endpoint: 'admin/oauth2/authcode/',
        key,
        data
    }) );

};

const update = ( key, id, data ) => async ( dispatch ) => {

    return await dispatch ( blrfOperations.update( {
        endpoint: `admin/oauth2/authcode/${id}`,
        key,
        data
    }) );

};

export {
    get, 
    search,
    remove,
    clear,
    create,
    update
};
