import React, { Fragment, useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { OAuth2RefreshTokenOperations } from './../../../state/ducks/oauth2/refreshToken';

import { Table } from 'app/components';

import { Link } from 'react-router-dom';

import Fab from '@material-ui/core/Fab';

import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';

import { openSnackbar } from 'app/components/snackbar';

import utils from 'app/utils';

const OAuth2RefreshTokenspage = () => {

    /**
     * Take dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Search for refreshTokens via Api
     */
    const search = useCallback( query => dispatch( OAuth2RefreshTokenOperations.search( 'refreshTokens', query ) ), [ dispatch ] );

    /**
     * Remove refreshTokens via Api
     */
    const remove = useCallback( id => dispatch( OAuth2RefreshTokenOperations.remove( 'refreshToken', id ) ), [ dispatch ] );

    /**
     * OAuth2RefreshTokens blrf object
     */
    const refreshTokensObject = useSelector( state => state.app.blrf.objects.refreshTokens || state.app.blrf.defaultObject );

    const { data, meta } = useMemo( () => {
        return {
            data: refreshTokensObject.data,
            meta: refreshTokensObject.meta
        }
    }, [ refreshTokensObject ]);

    const query = useMemo( () => {
        /**
         * When object is first created query will only contain { refs: 0, page: 1 }
         *
         * We want refs, so we change that. And we want to have default order.
         * All other aspects of query object is controlled via
         * Table component.
         */
        const hasOrder = Array.isArray(refreshTokensObject.query.order) && refreshTokensObject.query.order.length > 0;
        if (!Array.isArray(refreshTokensObject.query.refs) || !hasOrder) {
            return { ...refreshTokensObject.query, refs: ['AccessToken'], order: (hasOrder ? [...refreshTokensObject.query.order ] : ['id ASC'] ) };
        }
        return refreshTokensObject.query;
    }, [ refreshTokensObject.query ] );

    const {
        loading,
        error,
    } = refreshTokensObject;

    const fields = [
        {
            label: 'Id',
            name: 'id',
            width: 4,
        },
        {
            label: 'Access Token Id',
            name: 'access_token_id',
            width: 4,
        },
        {
            label: 'Expiry datetime',
            name: 'expiry_datetime',
            searchable: false,
            width: 2
        },
        {
            label: 'Created by',
            name: 'created_by',
            width: 2
        }
    ];

    const renderRow = ({ id, access_token_id, expiry_datetime, created_by }, i) => ({
        key: id,
        cells: [
            id.substring(0, 10) + '...' + id.slice(id.length - 10),
            access_token_id.substring(0, 10) + '...' + access_token_id.slice(id.length - 10),
            expiry_datetime,
            created_by
        ]
    });

    const actions = [
        {
            icon: <DescriptionIcon />,
            component: Link,
            to: utils.getFullPath('admin/oauth2/refreshToken/view/:id')
        },
        {
            icon: <CreateIcon />,
            component: Link,
            to: utils.getFullPath('admin/oauth2/refreshToken/edit/:id')
        },
        {
            icon: <DeleteIcon />,
            onClick: ( data ) => removeRefreshToken(data.id),
            confirm: {
                content: 'Are you sure you want to delete Refresh Token :name ?'
            }
        }
    ];

    const removeRefreshToken = async(id) => {
        const ret = await remove( id );
        if (ret.type.indexOf('error') > 0) {
            openSnackbar('Faild to delete Refresh Token', 'error');
        } else {
            openSnackbar('Access Token deleted', 'success');
            search(query);
        }
    }

    return(
        <Fragment>
            <Table
                fields={fields}
                renderRow={renderRow}
                actions={actions}
                loading={loading}
                search={search}
                query={query}
                data={data}
                meta={meta}
                error={error}
            />
            <Fab color='primary' style={{ float: 'right' }} variant='extended' component={Link} to={utils.getFullPath('admin/oauth2/refreshToken/add')}>
                <AddIcon />
                Add Refresh Token
            </Fab>
        </Fragment>
    )

}

export default OAuth2RefreshTokenspage;
