import config from './../config';

import DotObject from 'dot-object';

export default class utils {

    /**
     * Get absolute path
     *
     * Simply appends uri to every link without starting '/'
     *
     * Since config.uri may be '/' or '/something' with need to check this.
     */
    static getFullPath(to) {
        let uri = config.uri;
        /**
         * If it is an absolute path (starts with /), we
         * can simply return the to path.
         */
        if (to.charAt(0) === '/') {
            return to;
        }
        /**
         * Otherwise, we need to append our config.uri to it.
         */

        // make sure we have atleast '/'
        uri = uri.length === 0 ? '/' : uri;
        return uri.charAt(uri.length -1) === '/' ? `${uri}${to}` : `${uri}/${to}`;
    }

    /**
     * A simple template system that parses string and inserts object properties
     *
     * Example:
     *  obj.name = 'foobar'
     *  str = 'Hello :name'
     *
     * Example with subobject:
     *
     * obj.subObj.name = 'foobar'
     * str = 'Hello: :subObj.name'
     *
     * ret = parseObjectProperties(str, obj)
     * ret is now: 'Hello foobar'
     */
    static parseObjectProperties(str, obj){
        const properties = str.match(/:([A-Za-z0-9_\.]+)/g);
        let ret = str;
        if (properties === null) {
            return ret;
        }
        
        properties.forEach(
            (prop) => {

                const objProp = prop.substring(1);

                /**
                 * If dot is matched at the end, remove it.
                 */
                if (objProp.charAt(objProp.length-1) == '.') {
                    objProp = string.slice(0, -1);
                    if (objProp.length == 0) {
                        return;
                    }
                }

                const val = DotObject.pick(objProp, obj);
                if (val){
                    ret = ret.replace(prop, val);
                }
            }
        );
        return ret;
    }

    static objectToQuerystring( obj ) {
        return Object.keys(obj).reduce(
            (str, key, i) => {
                var delimiter, val;
                delimiter = (i === 0) ? '?' : '&';
                key = encodeURIComponent(key);
                val = encodeURIComponent(obj[key]);
                return [str, delimiter, key, '=', val].join('');
            }, ''
        );
    } 
}
