import React, { Fragment, useCallback, useMemo, useState } from 'react';
import {Provider} from 'react-redux'
import {mount, shallow} from 'enzyme'
import { ConnectedRouter } from 'connected-react-router'
import UsersPage from '../../../views/pages/users';
import store, {history} from "../../../../../app/state/store";
import { Table } from 'app/components';


describe('Test users page', () => {

    beforeAll(() => {
        localStorage.setItem('token_type', 'Bearer')
        localStorage.setItem('access_token', 'myFakeAccessToken1')
    })

    // this hsold be moved in utils file
    let setMount = (component) => {

        return mount(

            <Provider store={store}>
                <ConnectedRouter history={history}>
                    {component}
                </ConnectedRouter>
            </Provider>
        );
    }
    it('Should render ', async () => {
        const wrapper = setMount(<UsersPage/>)
        expect(wrapper.find(Table).length).toEqual(1)
        expect(wrapper.find(Table).props().data).toEqual([])

    });
})
