import store, { injectModuleReducer } from './../../store';
import { addMiddleware } from './../../middleware/dyn';

/**
 * Detect current module based on current path obtained from redux-router
 *
 * This gets called on every @@router/LOCATION_CHANGE
 */
export const detectCurrentModule = (modules, path) => {
    console.log(' >> detect current module: ', path);
    if (modules !== null && path !== null) {
        for ( let i = 0; i < modules.length; i++ ) {
            const _module = modules[i];
            const regexp = '^' + _module.getUri().replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1') + '($|\\/.*)';
            console.log('module: ', _module, ' uri: ', _module.getUri(), ' regexp: ', regexp);
            if (path.match(regexp)) {
                return _module;
            }
        }
    }
    return null;
};

/**
 * When application starts for the first time (App mounts)
 * we call app/module/load and we load the modules.
 *
 * After the load is finished, we fire the initModules via app/module/init
 */
export const loadModules = (modulesArray) => {

    const modules = [];

    const req = require.context('./../../../../modules/', true, /.*/);

    for ( let i = 0; i < modulesArray.length; i++) {
        const { name = null, config = {} } = modulesArray[i];

        if (name) {
            const path = `./${name}/${name}`;
            const m = req(path);
            const o = new m.default(name, config);
            modules.push(o);
        }
    }
    return modules;
};

/**
 * Load reducers for all modules
 *
 * When we finish loading modules, we call app/module/init action
 * and this will injectModuleReducer into store.
 */
export const initModules = (modulesArray) => {

    for ( let i = 0; i < modulesArray.length; i++) {
        const _module = modulesArray[i];
        const r = _module.getReducer();
        const m = _module.getMiddleware();
        if (r !== null) {
            injectModuleReducer(store, _module.getName(), r);
        }
        if (m !== null) {
            addMiddleware(m);
        }
    }
};
