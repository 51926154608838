import React from 'react';

import SearchIcon from '@material-ui/icons/Search';

import ViewEdit from './../viewedit';

/**
 * defautlValues: To which values to we reset the form
 */
const TableSearch = ({query, search, initialValues = {}, defaultValues = {}, ...props}) => {

    const onSubmit = ( values ) => {
        query.page = 1;
        query.query = values;
        return search(query);
    }

    return (
        <ViewEdit
            edit={true}
            backButton={false}
            saveButton={{ size: 'small', content: 'Search', startIcon: <SearchIcon /> }}
            resetButton={{ size: 'small', type: 'reset', onClick: () => onSubmit(defaultValues) }}
            onSubmit={onSubmit}
            initialValues={ { ...initialValues, ...query.query } }
            {...props} 
            />
    );

};

export default TableSearch;
