import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { userOperations } from './../../state/ducks/user';

import Tabs from 'app/components/tabs';
import ErrorAlert from 'app/components/error';

import LinearProgress from '@material-ui/core/LinearProgress';

import UserGeneral from './user/general';
import UserSecurity from './user/security';

const emptyUser = {
    id: null,
    name: '',
    Roles: [],
    Groups: [],
    Children: [],
    parent_id: null,
    created_at: null,
    created_by: null,
    updated_at: null,
    updated_by: null 
}

/**
 * Extract action and uid from react-router match prop
 */
const UserPage = ( { match: { params: { action, uid } } } ) => {

    /**
     * Dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Get user from Api
     */
    const get = useCallback( id => dispatch( userOperations.get( 'user', id ) ) , [ dispatch ]);
    /**
     * Update user via Api
     */
    const update = useCallback( ( id, data ) => dispatch( userOperations.update( 'user', id, data ) ), [ dispatch ] );

    /**
     * Create new user via Api
     */
    const create = useCallback( data => dispatch( userOperations.create( 'user', data ) ) , [ dispatch ] );
    /**
     * Clean user blrf object
     */
    const clear = useCallback( () => dispatch( userOperations.clear( 'user' ) ), [ dispatch ] );

    /**
     * Fetch user blrf object. If object is not yet available return default blrf object stored in state.
     */
    const userObject = useSelector( state => state.app.blrf.objects.user || state.app.blrf.defaultObject );

    /**
     * Get user from blrf object data
     */
    const user = useMemo( () => {
        return ( action === 'add' ? emptyUser : userObject.data.find(v => v) )
    }, [ userObject.data, action ]);

    /**
     * Get loading and error from blrf user object
     */
    const { loading, error } = userObject;

    /**
     * Call get user from Api if uid changed
     *
     * And register unmount function that will clear the user object.
     * This is important because, when we later arrive to this page and
     * uid has changed child components will still see the old user and
     * we will get double queries. One for old and one for new and data
     * will most likely be incorrect.
     */
    useEffect( () => {
        if (uid > 0) {
            get( uid );
        }
        return clear;
    }, [ uid, get, clear ]);

    /**
     * Generate panes for tabs
     */
    const panes = useMemo( () => {
        if (!user) {
            return null;
        }
        return (
            [
                {
                    tab: { label: 'General' },
                    content:
                        <UserGeneral
                            key='general'
                            loading={loading}
                            user={user}
                            edit={action === 'add' || action === 'edit'}
                            update={update}
                            create={create}
                        />
                },
                {
                    tab: { label: 'Security' },
                    content:
                        <UserSecurity
                            key='security'
                            loading={loading}
                            user={user}
                        />
                }
            ]);
    }, [ user, action, loading, update, create ]);

    return (
        <Fragment>
            {error === false && loading && <LinearProgress />}
            {error !== false && <ErrorAlert error={error} />}
            {user && <Tabs panes={panes} />}
        </Fragment>
    );

};

UserPage.propTypes = {
    match: PropTypes.object.isRequired
};

UserPage.defaultProps = {

};

export default UserPage;