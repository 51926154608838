import React from 'react';
import PropTypes from 'prop-types';

import AppNavbarModuleDropdown from './moduleDropdown';
import AppNavbarModuleButton from './moduleButton';

const AppNavbarModule = ({module, user, close}) => (
    module.getNavbar(user).map((item, key) => (
            Array.isArray(item.items) ? 
                <AppNavbarModuleDropdown key={key} close={close} {...item} />:
                <AppNavbarModuleButton key={key} close={close} {...item} />
        )
    )
);

AppNavbarModule.propTypes = {
    module: PropTypes.object.isRequired,
    user: PropTypes.object,
    close: PropTypes.func.isRequired
};

AppNavbarModule.defaultProps = {
    user: null
};

export default AppNavbarModule;
