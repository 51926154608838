import actions from './actions';

const load = () => async (dispatch) => {

    await dispatch( actions.load() );
    await dispatch( actions.init() );

};

export {
    load
};
