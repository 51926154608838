import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as operations from './operations';

import { connectedSelectField } from 'app/components/form/field/select';

function clientSelectImplementation ( WrappedComponent ) {
    return class extends Component {

        onSearchEvent = ( searchQuery ) => {
            const { search } = this.props;
            return search({
                refs: 0,
                query: {
                    name: `${searchQuery}*`
                }
            }, false);
        };

        map = ( client ) => ({ key: client.id, value: client.id, label: client.name });

        render( ) {
            const { select, ...rest } = this.props;
            const default_select = {
                map: this.map,
                onSearchEvent: this.onSearchEvent,
                ...select
            };

            return <WrappedComponent
                        select={default_select}
                        placeholder='Search client by name ...'
                        {...rest}
                    />;
        }
    };
}

const mapDispatchToProps =  ( dispatch, ownProps ) => {
    const { blrfKey = 'clients_select' } = ownProps;
    return bindActionCreators(
        {
            search: (query) => operations.search(blrfKey, query),
        }, dispatch);
};

const mapStateToProps = ( state, ownProps ) => {
    const { blrfKey = 'clients_select' } = ownProps;
    const { loading = false, error = false, query, data = []} = state.app.blrf.objects[blrfKey] || state.app.blrf.defaultObject;
    return {
        loading, 
        query, 
        data,
        error: (error !== false ? error && error.message : false)
    }
};

const clientsSelectField = connect(mapStateToProps, mapDispatchToProps)(clientSelectImplementation(connectedSelectField()));

export {
    clientsSelectField
};
