import React, { Fragment, useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { transactionOperations } from './../../state/ducks/transaction';
import { exchangeComponents } from './../../state/ducks/exchange';
import { currencyComponents } from './../../state/ducks/currency';
import { userComponents } from './../../state/ducks/user';

import * as transactionConst from './../../const/transaction';

import { Table } from 'app/components';

import { default as MuiLink } from '@material-ui/core/Link';

import { Link } from 'react-router-dom';

import Fab from '@material-ui/core/Fab';

import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';

import { openSnackbar } from 'app/components/snackbar';

import utils from 'app/utils';
import coinUtils from '../../utils';

const transactionsPage = () => {

    /**
     * Take dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Search for transactions via Api
     */
    const search = useCallback( query => dispatch( transactionOperations.search( 'transactions', query ) ), [ dispatch ] );

    /**
     * Remove transaction via Api
     */
    const remove = useCallback( id => dispatch( transactionOperations.remove( 'transaction', id ) ), [ dispatch ] );

    /**
     * transactions blrf object
     */
    const transactionsObject = useSelector( state => state.app.blrf.objects.transactions || state.app.blrf.defaultObject );

    const { data, meta } = useMemo( () => {
        return {
            data: transactionsObject.data,
            meta: transactionsObject.meta
        }
    }, [ transactionsObject ]);

    const query = useMemo( () => {
        /**
         * When object is first created query will only contain { refs: 0, page: 1 }
         *
         * We want refs, so we change that. And we want to have default transaction.
         * All other aspects of query object is controlled via
         * Table component.
         */
         const hasOrder = Array.isArray(transactionsObject.query.order) && transactionsObject.query.order.length > 0;
         if (transactionsObject.query.refs === 0 || !hasOrder) {
            return { ...transactionsObject.query, refs: ['Order','User', 'Exchange','Currency'], order: (hasOrder ? [...transactionsObject.query.transaction ] : ['id DESC'] ) };
         }
         return transactionsObject.query;
    }, [ transactionsObject.query ] );

    const {
        loading,
        error
    } = transactionsObject;

    const fields = [
        {
            label: 'Id',
            name: 'id',
            width: 1,
            type: 'number'
        },
        {
            label: 'Order',
            name: 'order_id',
            width: 1,
            type: 'number',
            searchable: false
        },
        {
            label: 'Datetime',
            name: 'created_at',
            width: 1,
            searchable: false
        },
        {
            label: 'Status',
            name: 'status',
            width: 1,
            select: {
                defaultOptions: transactionConst.transactionStatusOptions
            }
        },
        {
            label: 'User',
            name: 'user_id',
            width: 2,
            component: userComponents.usersSelectField
        },
        {
            label: 'Exchange',
            name: 'exchange_id',
            width: 2,
            component: exchangeComponents.exchangesSelectField
        },
        {
            label: 'Currency',
            name: 'currency_id',
            width: 2,
            component: currencyComponents.currenciesSelectField
        },
        {
            label: 'Amount',
            name: 'amount',
            width: 1,
            searchable: false
        },
        {
            label: 'Balance',
            name: 'balance',
            width: 1,
            searchable: false
        }
    ];

    const renderRow = ({ id, Order, created_at, status, User, Exchange, Currency, amount, balance }, i) => ({
        key: i,
        cells: [
            id,
            <MuiLink component={Link} to={utils.getFullPath(`coin/orders/view/${Order.id}`)}>{`${Order.id}`}</MuiLink>,
            created_at,
            status ? transactionConst.transactionStatus[status].name : status,
            User.name,
            Exchange.name,
            Currency.sid,
            coinUtils.formatCurrency(Currency, amount),
            coinUtils.formatCurrency(Currency, balance),
        ]
    });

    return (
        <Table
            fields={fields}
            renderRow={renderRow}
            loading={loading}
            search={search}
            query={query}
            data={data}
            meta={meta}
            error={error}
            />
    );
};

export default transactionsPage;
