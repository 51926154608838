import React from 'react';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';

import MuiAlert from '@material-ui/lab/Alert';

const Alert = (props) => <MuiAlert elevation={6} variant='filled' {...props} />;

let openSnackbarFn;

class ConsecutiveSnackbars extends React.PureComponent {
    queue = [];

    state = {
        open: false,
        messageInfo: {},
    };

    componentDidMount = () => {
        openSnackbarFn = this.handleOpen;
    };

    handleOpen = (message, variant, options = {} ) => {
        this.queue.push({
            message,
            variant,
            key: new Date().getTime(),
            options
        });

        if (this.state.open) {
            // immediately begin dismissing current message
            // to start showing new one
            this.setState({ open: false });
        } else {
            this.processQueue();
        }
    };

    processQueue = () => {
        if (this.queue.length > 0) {
            this.setState({
                messageInfo: this.queue.shift(),
                open: true,
            });
        }
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false });
    };

    handleExited = () => {
        this.processQueue();
    };

    render() {
        const { message, variant, options = {}, key } = this.state.messageInfo;
        const {
            autoHideDuration = 6000,
            actions = []
        } = options;
        const closeAction =
            <IconButton
                size='small'
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.handleClose}
            >
                <CloseIcon />
            </IconButton>;
        actions.push(closeAction);
        return (
            <Snackbar
                key={key}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={this.state.open}
                autoHideDuration={autoHideDuration}
                onClose={this.handleClose}
                onExited={this.handleExited}
            >
                <Alert
                    severity={variant}
                    onClose={this.handleClose}
                    action={actions}>
                    {message}
                </Alert>
            </Snackbar>
        );
    }
}

ConsecutiveSnackbars.propTypes = {

};

export function openSnackbar(message, variant, options = {} ) {
    return openSnackbarFn(message, variant, options);
}

export default ConsecutiveSnackbars;
