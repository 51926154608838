import { blrfOperations } from 'app/state/ducks/blrf';

const { clear } = blrfOperations;

const get = ( key, id ) => async ( dispatch ) => {

    return await dispatch ( blrfOperations.get( {
        endpoint: 'admin/oauth2/refreshtoken',
        key,
        id
    }) );
    
};

const search = ( key, data ) => ( dispatch ) => {

    return dispatch ( blrfOperations.search( {
        endpoint: 'admin/oauth2/refreshtoken/search',
        key, 
        data
    }) );

};

const remove = ( key, id ) => async ( dispatch ) => {

    return await dispatch ( blrfOperations.remove( {
        endpoint: `admin/oauth2/refreshtoken/${id}`,
        key
    }) );

};

export {
    get, 
    search,
    remove,
    clear
};
