import types from './types';
import { BLRF_API } from './../../middleware/blrf';

import config from './../../../../config';

export default {

    /**
     * Upon first App mount, we initialize the authentication
     */
    init: () => ({
        type: types.INIT
    }),

    /**
     * Instruct middlewares to process the queued actions
     */
    authQueueProcess: () => ({
        type: types.AUTH_QUEUE_PROCESS
    }),

    /**
     * Instruct middlwares to drop all the queues, we've failed somewhere.
     */
    authQueueFlush: () => ({
        type: types.AUTH_QUEUE_FLUSH
    }),

    /**
     * Request new oauth2 token
     */
    login: (username, password) =>  ({
        [ BLRF_API ]: {
            endpoint: 'oauth2/token',
            method: 'post',
            data: {
                grant_type: 'password',
                client_id: config.api.clientId,
                client_secret: config.api.clientSecret,
                scope: 'basic',
                username,
                password
            },
            auth: false,
            types: [ types.LOGIN, types.LOGIN_OK, types.LOGIN_ERROR ]
        }
    }),

    logout: () => ({
        [ BLRF_API ]: {
            endpoint: 'oauth2/token',
            method: 'delete',
            types: [types.LOGOUT, types.LOGOUT_OK, types.LOGOUT_OK ]
        }
    }),

    /**
     * Refresh access token
     */
    refresh: (refresh_token) => ({
        [BLRF_API]: {
            endpoint: 'oauth2/token',
            method: 'post',
            data: {
                grant_type: 'refresh_token',
                refresh_token,
                client_id: config.api.clientId,
                client_secret: config.api.clientSecret,
                scope: ['basic']
            },
            auth: false,
            types: [ types.LOGIN, types.LOGIN_OK, types.LOGIN_ERROR ]
        }
    }),

    /**
     * Fetch current user info
     */
    user: () => ({
        [BLRF_API]: {
            endpoint: 'oauth2/user',
            method: 'get',
            types: [ types.USER, types.USER_OK, types.LOGIN_ERROR ]
        }
    }),

    /**
     * Change current users password
     */
    password: (password) => ({
        [BLRF_API]: {
            endpoint: 'oauth2/user/password',
            method: 'put',
            data: { password }, 
            types: [ types.PASSWORD, types.PASSWORD_OK, types.PASSWORD_ERROR ]
        }
    })

};
