export default class utils {

    /**
     * Format currency
     *
     * @param object currency Full currency object
     * @param float value Value to format
     */
    static formatCurrency(currency, value) {
        try {
            return new Intl.NumberFormat('si-SL', { style: 'currency', currency: currency.sid, maximumFractionDigits: currency.decimals }).format(value);
        } catch (e) {
            return currency.sid + ' ' + (new Intl.NumberFormat('si-SL', { style: 'decimal', maximumFractionDigits: currency.decimals }).format(value));
        }
    }

    static formatPercent(value) {
        return new Intl.NumberFormat('si-SL', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2}).format(value);
    }
}