import {userOperations} from '../../../../state/ducks/user'
import store from 'app/state/store';
import fetchMock from 'fetch-mock'
import types from "../../../../../../app/state/ducks/blrf/types";



describe('User operatios test', () => {

    beforeAll(() => {
        localStorage.setItem('token_type', 'Bearer')
        localStorage.setItem('access_token', 'myFakeAccessToken')
    })

    afterEach(() => {
        fetchMock.restore() ;
    });

    it('User search should return proper payload', async () => {

        const responseData = [
            {
                id: 1,
                name: 'User1',
                username: 'user1'
            },
            {
                id: 2,
                name: 'User2',
                username: 'user2'
            },
        ]

        const key = 'users'
        const endpoint = 'admin/user/search'

        fetchMock
            .post(`end:${endpoint}`,
                {
                    data: responseData
                }
            )

        const searchData = {};
        const res = await store.dispatch(userOperations.search(key, searchData));

        const expectedActionResponse = {
            type: 'blrf/search/ok',
            payload: {
                data: responseData,
                api: {
                    key: key,
                    endpoint: endpoint,
                    method: 'post',
                    props: {},
                    data: searchData,
                    auth: true,
                    types: [ types.SEARCH, types.SEARCH_OK, types.SEARCH_ERROR ]
                }
            }
        }
        expect(res).toEqual(expectedActionResponse)
        expect(store.getState().app.blrf.objects.users.data).toEqual(responseData)
    })

    it('Get user should return proper payload', async () => {

        const responseData = [
            {
                id: 1,
                name: 'User1',
                username: 'user1'
            },
        ]

        const userId = 5
        const key = 'user'
        const endpoint = `admin/user/${userId}`

        fetchMock
            .get(`end:${endpoint}`,
                {
                    data: responseData
                }
            )

        const res = await store.dispatch(userOperations.get(key, userId));

        const expectedActionResponse = {
            type: 'blrf/get/ok',
            payload: {
                data: responseData,
                api: {
                    key: key,
                    endpoint: endpoint,
                    method: 'get',
                    props: {},
                    auth: true,
                    types: [ types.GET, types.GET_OK, types.GET_ERROR ]
                }
            }
        }

        expect(res).toEqual(expectedActionResponse)
    });

    it('Update should return proper payload', async () => {
        const responseData = [
            {
                id: 1,
                name: 'User1',
                username: 'user123'
            },
        ]

        const userId = 1
        const updateData = {username: 'user123'}
        const key = 'user'
        const endpoint = `admin/user/${userId}`

        fetchMock
            .put(`end:${endpoint}`,
                {
                    data: responseData
                }
            )


        const res = await store.dispatch(userOperations.update(key, userId, updateData));

        const expectedActionResponse = {
            type: 'blrf/update/ok',
            payload: {
                data: responseData,
                api: {
                    data: updateData,
                    key: key,
                    endpoint: endpoint,
                    method: 'put',
                    props: {},
                    auth: true,
                    types: [ types.UPDATE, types.UPDATE_OK, types.UPDATE_ERROR ]
                }
            }
        }
        expect(res).toEqual(expectedActionResponse)
        expect(store.getState().app.blrf.objects.user.data).toEqual(responseData)
    });

    it('Crate user should return proper payload', async () => {
        const data = [
            {
                id: 1,
                name: 'User1',
                username: 'user123'
            },
        ]

        const key = 'user'
        const endpoint = `admin/user`

        fetchMock
            .post(`end:${endpoint}`,
                {
                    data: data
                }
            )


        const res = await store.dispatch(userOperations.create(key, data));

        const expectedActionResponse = {
            type: 'blrf/create/ok',
            payload: {
                data: data,
                api: {
                    data: data,
                    key: key,
                    endpoint: endpoint,
                    method: 'post',
                    props: {},
                    auth: true,
                    types: [ types.CREATE, types.CREATE_OK, types.CREATE_ERROR ]
                }
            }
        }
        expect(res).toEqual(expectedActionResponse)
        expect(store.getState().app.blrf.objects.user.data).toEqual(data)
    });

    it('Remove user should return proper payload and remove user from store', async () => {
        const data = [ ]

        const userId = 1
        const key = 'user'
        const endpoint = `admin/user/${userId}`

        fetchMock
            .delete(`end:${endpoint}`,
                {
                    data: data
                }
            )


        const res = await store.dispatch(userOperations.remove(key, userId));

        const expectedActionResponse = {
            type: 'blrf/remove/ok',
            payload: {
                data: data,
                api: {
                    key: key,
                    endpoint: endpoint,
                    method: 'delete',
                    auth: true,
                    types: [ types.REMOVE, types.REMOVE_OK, types.REMOVE_ERROR ]
                }
            }
        }
        expect(res).toEqual(expectedActionResponse)

        expect(store.getState().app.blrf.objects.user.data).toEqual(data)

    });

})