import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import * as Yup from 'yup';

import { openSnackbar } from 'app/components/snackbar';

import { OAuth2AuthCodeOperations } from './../../../state/ducks/oauth2/authCode';
import {OAuth2ClientComponents} from './../../../state/ducks/oauth2/client';

import ViewEdit from 'app/components/viewedit';
import ErrorAlert from 'app/components/error';
import LinearProgress from '@material-ui/core/LinearProgress';

import utils from 'app/utils';
import {userComponents} from "../../../state/ducks/user";

const authCodeValidation = Yup.object().shape({
    id: Yup.number(),
    client_id: Yup.string()
        .min(2, 'Id too short')
        .max(50, 'Id too long')
        .required('Id is required')
        .matches(/^[0-9A-Za-z_]+$/, {
            message:'Only numbers, letters and underscore allowed',
            excludeEmptyString: true
        }),
    user_id: Yup.number().required(),
    redirect_uri: Yup.string().url().nullable()
});

const emptyAuthCode = {
    id: '',
    client_id: '',
    user_id: '',
    expiry_datetime: '',
    redirect_uri: '',
    created_at: null,
    created_by: null,
    updated_at: null,
    updated_by: null
};

const OAuth2AuthCodePage = ( { match: { params: { action, acid } } } ) => {

    /**
     * Are we editing
     */
    const edit = (action === 'add' || action === 'edit');

    console.log('acid: ', acid);

    /**
     * Dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Get authCode from Api
     */
    const get = useCallback( id => dispatch( OAuth2AuthCodeOperations.get( 'authCode', id ) ) , [ dispatch ]);

    /**
     * Update authCode via Api
     */
    const update = useCallback( ( id, data ) => dispatch( OAuth2AuthCodeOperations.update( 'authCode', id, data ) ), [ dispatch ] );
    /**
     * Create new authCode via Api
     */
    const create = useCallback( data => dispatch( OAuth2AuthCodeOperations.create( 'authCode', data ) ) , [ dispatch ] );
    /**
     * Clean authCode blrf object
     */
    const clear = useCallback( () => dispatch( OAuth2AuthCodeOperations.clear( 'authCode' ) ), [ dispatch ] );
    /**
     * Fetch authCode blrf object. If object is not yet available return default blrf object stored in state.
     */
    const authCodeObject = useSelector( state => state.app.blrf.objects.authCode || state.app.blrf.defaultObject );

    /**
     * Get authCode from blrf object data
     */
    const authCode = useMemo( () => {
        return ( action === 'add' ? emptyAuthCode : authCodeObject.data.find(v => v) || emptyAuthCode)
    }, [ authCodeObject.data, action ]);

    /**
     * Get loading and error from blrf authCode object
     */
    const { loading, error } = authCodeObject;

    /**
     * Call get authCode from Api if aid changed
     *
     * And register unmount function that will clear the scope object.
     * This is important because, when we later arrive to this page and
     * aid has changed child components will still see the old scope and
     * we will get double queries. One for old and one for new and data
     * will most likely be incorrect.
     */
    useEffect( () => {
        if (acid && acid.length > 0) {
            get( acid );
        }
        return clear;
    }, [ acid, get, clear ]);

    const fields = [
        {
            label: 'Id',
            name: 'id',
            disabled: true,
            value: authCode.id,
            width: 1
        },
        {
            label: 'Client id',
            name: 'client_id',
            value: (authCode.Client ? ( edit ? authCode.client_id : authCode.Client.name) : null ),
            component: OAuth2ClientComponents.clientsSelectField ,
            width: 4,
        },
        {
            label: 'User',
            name: 'user_id',
            value: (authCode.User ? ( edit ? authCode.user_id : authCode.User.name) : null ),
            component: userComponents.usersSelectField,
            width: 3
        },
        {
            label: 'Expiry datetime',
            name: 'expiry_datetime',
            value: authCode.expiry_datetime,
            searchable: false,
            width: 3
        },
        {
            label: 'Redirect uri',
            name: 'redirect_uri',
            value: authCode.redirect_uri ,
            width: 2,
        },
        {
            label: 'Created by',
            name: 'created_by',
            value: authCode.created_by ,
            width: 2,
            edit: false
        },
        {
            label: 'Created at',
            name: 'created_at',
            value: authCode.created_at ,
            width: 2,
            edit: false
        }
    ];

    const onSubmit = ( values ) => {
        let p = null;
        if (action === 'add') {
            p = create(values);
        } else {
            p = update( values.id, values );
        }
        p.then(ret => {
            if (ret.type.indexOf('error') > 0) {
                openSnackbar('Error while saving scope', 'error');
            } else {
                openSnackbar('Scope saved', 'success');
                window.history.go( -1 );
            }
        } );
        return p;
    }

    return (
        <Fragment>
            {error === false && loading && <LinearProgress />}
            {error !== false && <ErrorAlert error={error} />}
            {authCode &&
            <ViewEdit
                header={{ title: (authCode.id.length > 0 ? 'Edit AuthCode' : 'Add AuthCode') }}
                onSubmit={onSubmit}
                fields={fields}
                validationSchema={authCodeValidation}
                loading={loading}
                edit={edit}
                editButton={{ component: Link, to: utils.getFullPath(`admin/oauth2/authCode/edit/${authCode.id}`) }}
            />
            }
        </Fragment>
    );
}

OAuth2AuthCodePage.propTypes = {
    match: PropTypes.object.isRequired
};

export default OAuth2AuthCodePage;