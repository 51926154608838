import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import AppNavbarModuleDropdownItem from './moduleDropdownItem';

const styles = theme => ({
    nested: {
        paddingLeft: theme.spacing(4)
    }
});

class AppNavbarModuleDropdown extends Component {

    static propTypes = {
        title: PropTypes.string.isRequired,
        icon: PropTypes.node,
        items: PropTypes.array.isRequired,
        close: PropTypes.func.isRequired,   // the main menu cose
        classes: PropTypes.object.isRequired,
    };

    static defaultProps = {
        icon: null
    };

    state = {
        open: false
    };

    handleClick = () => {
        this.setState(state => ({ open: !state.open }));
    };

    render() {
        const { title, icon, items, close, classes } = this.props;
        return (
            <Fragment>
                <MenuItem button onClick={this.handleClick}>
                    {icon ? 
                        <ListItemIcon>
                            {icon}
                        </ListItemIcon>
                        : null
                    }
                    <ListItemText primary={title} />
                    {this.state.open ? <ExpandLess /> : <ExpandMore />}
                </MenuItem>
                <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                    {items.map((item, key) => <AppNavbarModuleDropdownItem key={key} close={close} classes={classes} {...item} />)}
                </Collapse>
            </Fragment>
        );
    }
}

export default withStyles(styles)(AppNavbarModuleDropdown);

/**
 * @todo: Need to figure out how to render icon instead of text in small screen
 *
const AppNavbarModuleDropdown = ({title, icon, items}) => (
    <Dropdown pointing button item text={title}>
        <Dropdown.Menu>
            {items.map((item, key) => <AppNavbarModuleDropdownItem key={key} {...item} />)}
        </Dropdown.Menu>
    </Dropdown>
);


AppNavbarModuleDropdown.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.node,
    items: PropTypes.array.isRequired
};
AppNavbarModuleDropdown.defaultProps = {
    title: null,
    icon: null
};

export default AppNavbarModuleDropdown;
*/