/**
 * blrf/.../... actions
 */
export default {
    /**
     * Search
     */
    SEARCH: 'blrf/search/start',
    SEARCH_OK: 'blrf/search/ok',
    SEARCH_ERROR: 'blrf/search/error',

    /**
     * Get single object
     */
    GET: 'blrf/get/start',
    GET_OK: 'blrf/get/ok',
    GET_ERROR: 'blrf/get/error',

    /**
     * Save
     */
    UPDATE: 'blrf/update/start',
    UPDATE_OK: 'blrf/update/ok',
    UPDATE_ERROR: 'blrf/update/error',

    /**
     * Create object
     */
    CREATE: 'blrf/create/start',
    CREATE_OK: 'blrf/create/ok',
    CREATE_ERROR: 'blrf/create/error',

    /**
     * Delete object
     */
    REMOVE: 'blrf/remove/start',
    REMOVE_OK: 'blrf/remove/ok',
    REMOVE_ERROR: 'blrf/remove/error',

    /**
     * Clear object data
     */
    CLEAR: 'blrf/clear',
    
    /**
     * Update single object data
     */
    UPDATE_SINGLE: 'blrf/updateSingle'
};
