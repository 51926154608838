import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import ViewEdit from 'app/components/viewedit';

import * as Yup from 'yup';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { roleComponents } from './../../../state/ducks/role';
import { groupComponents } from './../../../state/ducks/group';
import { realmComponents } from './../../../state/ducks/realm';
import { userComponents } from './../../../state/ducks/user';

import { openSnackbar } from 'app/components/snackbar';

import utils from 'app/utils';

const userValidation = Yup.object().shape({
    name: Yup.string()
            .min(2, 'Name too short')
            .max(100, 'Name too long')
            .required('Name is required'),
    username: Yup.string()
            .min(2, 'Username too short')
            .max(100, 'Username too long')
            .required('Username is required'),
    realm_id: Yup.number()
            .typeError('Realm is requried')
            .required('Realm is required'),
    role_id: Yup.array()
            .of(Yup.number())
            .required('At least one role is required'),
    new_password: Yup.string().when(
        'id', {
            is: (val) => !(val > 0),
            then: Yup.string()
                .min(4, 'Password too short')
                .required('Password is required'),
            otherwise: Yup.string()
        }
    )
});

const UserGeneral = ({user, update, create, edit, loading}) => {

    console.log('UserGeneral: edit: ', edit);

    const fields = [
        {
            label: 'Id',
            name: 'id',
            value: user.id,
            width: 1,
            disabled: true,
        },
        {
            label: 'Name',
            name: 'name',
            value: user.name,
            width: 4,
        },
        {
            label: 'Username',
            name: 'username',
            value: user.username,
            width: 4,
        },
        {
            label: 'Realm',
            name: 'realm_id',
            value: ( user.Realm ? ( edit === true ? user.realm_id : user.Realm.name) : null ),
            width: 3,
            select: {
                defaultData: user && user.Realm ? [user.Realm] : null,
            },
            component: realmComponents.realmsSelectField,
        },
        {
            label: 'Roles',
            name: 'role_id',
            value: ( edit ? user.Roles.map(r => r.Role.id) : user.Roles.map(r => r.Role.name).join(',')),
            width: 6,
            select: {
                defaultData: user && user.Roles ? user.Roles.map(r => r.Role) : null,
                multiple: true
            },
            component: roleComponents.rolesSelectField,
        },
        {
            label: 'Groups',
            name: 'group_id',
            value: ( edit ? user.Groups.map(r => r.Group.id) : user.Groups.map(r => r.Group.name).join(',')),
            width: 6,
            select: {
                defaultData: user && user.Groups ? user.Groups.map(r => r.Group) : null,
            },
            component: groupComponents.groupsSelectField,
        },
        {
            label: 'Parent',
            name: 'parent_id',
            value: ( user.Parent ? ( edit ? user.parent_id : user.Parent.name ) : null ),
            width: 6,
            select: {
                defaultData: user && user.Parent ? [ user.Parent, { id: 0, name: 'No parent user' } ] : [  { id: 0, name: 'No parent user' } ],
            },
            component: userComponents.usersSelectField,
        },
        {
            label: 'Password',
            name: 'new_password',
            type: 'password',
            value: '',
            width: 6,
        },
        {
            label: 'Created at',
            name: 'created_at',
            value: user.created_at,
            width: 3,
            edit: false
        },
        {
            label: 'Created by',
            name: 'created_by',
            value: user.created_by,
            width: 3,
            edit: false,
        },
        {
            label: 'Updated at',
            name: 'updated_at',
            value: user.updated_at,
            width: 3,
            edit: false,
        },
        {
            label: 'Updated by',
            name: 'updated_by',
            value: user.updated_by,
            width: 3,
            edit: false
        }
    ];

    const onSubmit = ( values ) => {
        let p = null;
        if ( values.id > 0 ) {
            p = update( values.id, values );
        } else {
            p = create ( values );
        }

        p.then( ret => {
            if (ret.type) {
                if (ret.type.indexOf('error') > 0) {
                    openSnackbar('User update/save failed', 'error');
                } else {
                    openSnackbar('User update successful', 'success');
                    window.history.go(-1);
                }
            }
        });
        return p;
    };

    return (
        <ViewEdit
            header={{ avatar: <AccountCircleIcon />, title: user.name || 'New user'}}
            loading={loading}
            onSubmit={onSubmit}
            fields={fields}
            validationSchema={userValidation}
            edit={edit}
            editButton={{ component: Link, to: utils.getFullPath(`admin/users/edit/${user.id}`) }}
            />
    );
};

UserGeneral.propTypes = {
    user: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
    create: PropTypes.func.isRequired,
    edit: PropTypes.bool.isRequired
};

export default UserGeneral;