import { AsyncComponent } from './components';
import utils from './utils';

const profilePage = AsyncComponent(() => import('./views/pages/profile'));

/**
 * Base routes for application
 */
const routes = [
    {
        path: utils.getFullPath('profile'),
        exact: true,
        component: profilePage
    }
];

export default routes;
