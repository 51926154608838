import actions from './actions';

const { init, user, logout, password, authQueueProcess, authQueueFlush } = actions;

const login = ( username, password ) => ( dispatch ) => {
    
    return dispatch( actions.login( username, password ) );

};

const refresh = ( token ) => async ( dispatch ) => {
    return await dispatch(actions.refresh( token ));
};

export {
    init,
    login,  
    user,
    refresh,
    logout,
    password,
    authQueueProcess,
    authQueueFlush
};
