import React from 'react';

/**
 * Buy type
 */
const T_BUY         = 'BUY';
/**
 * Transfer type
 */
const T_TRANSFER    = 'TRANSFER';
/**
 * Mine type
 */
const T_MINE        = 'MINE';
/**
 * Invest type
 */
const T_INVEST      = 'INVEST';
/**
 * Wait status
 */
const S_WAIT        = 'WAIT';
/**
 * Status Process
 */
const S_PROCESS     = 'PROCESS';
/**
 * Status Done
 */
const S_DONE    = 'DONE';

const orderType = {

    [ T_BUY ]: {
        id: T_BUY,
        name: 'Buy',
    },

    [ T_TRANSFER ]: {
        id: T_TRANSFER,
        name: 'Transfer'
    },

    [ T_MINE ]: {
        id: T_MINE,
        name: 'Mine'
    },

    [ T_INVEST ]: {
        id: T_INVEST,
        name: 'Invest'
    }

}

const orderTypeOptions = Object.keys( orderType ).map( k => ({
    value: orderType[k].id, label: orderType[k].name
}));

const orderStatus = {

    [ S_WAIT ]: {
        id: S_WAIT,
        name: 'Wait'
    },

    [ S_PROCESS ]: {
        id: S_PROCESS,
        name: 'Process'
    },

    [ S_DONE ]: {
        id: S_DONE,
        name: 'Done'
    }

};

const orderStatusOptions = Object.keys( orderStatus ).map( k => ({
    value: orderStatus[k].id, label: orderStatus[k].name
}));

export {

    T_BUY,
    T_TRANSFER,
    T_INVEST,

    orderType,
    orderTypeOptions,

    S_WAIT,
    S_PROCESS,
    S_DONE,

    orderStatus,
    orderStatusOptions

};
