import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Hidden from '@material-ui/core/Hidden';


import { NavLink } from 'react-router-dom';

const styles = theme => ({

    root: {
        color: 'white',
        textDecoration: 'none'
    }

});


const breadcrumbLink = ( props ) => {
    return props.path ? 
        <NavLink className={props.classes.root} to={props.path}>{props.title}</NavLink> :
        props.title;
}

const BreadcrumbLink = withStyles(styles)(breadcrumbLink);

const BreadcrumbsImpl = ( { breadcrumbs } ) => {


    if (breadcrumbs) {
        return(
            <Hidden xsDown>
                <Breadcrumbs aria-label="Breadcrumb">
                    {breadcrumbs.map ( ( breadcrumb, idx ) => <BreadcrumbLink key={idx} {...breadcrumb} />)}
                </Breadcrumbs>
            </Hidden>
        );
    } else {
        return null;
    }
}


const AppNavbarBreadcrumbs = ( { module })  => {

    
    if (module) {

        const routes = module.getRoutes();

        return (
            <Switch>
            {routes.map( ( { auth, component, exact = true, breadcrumbs = null, ...route } ) => {
                if (breadcrumbs) {
                    return (<Route key={route.path} exact={exact} {...route} render={() => <BreadcrumbsImpl breadcrumbs={breadcrumbs} />} />);
                }
                return null;
            })}
            </Switch>
        );
    }
    return null;
};

export default AppNavbarBreadcrumbs;