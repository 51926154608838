import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import invariant from 'invariant';
import { isFunction, isPlainObject, isString, isBoolean } from 'lodash';

/**
 * Wrapper (HOC) into Form with formik.
 *
 * Needed props:
 *
 * initialValues
 * validationSchema (Yup)
 * onSubmit => object|string|exception|boolean|promise
 *
 * Optional props:
 *  resetAfterSubmit => boolean
 *
 * We need to provide initialValues for form because that's the only way Formik knows which 
 * fields are active.
 *
 * Formik works best with Yup validation. So, if we need validation, provide it as Yup schema.
 *
 * onSubmit function will receive two arguments: values (object) and formikBag object (see Formik docs).
 *
 * onSubmit will attempt to handle as much return values as possible.
 *
 * When object is returned:
 *
 *  Two types of objects are expected:
 *
 *  BLRF RETURN OBJECT FROM ACTION
 *  {
 *     type: string
 *     payload: {
 *         api: { ... }
 *         error: {
 *           response: {
 *              error: string
 *              message: string      
 *           },
 *           status,
 *        }
 *     }
 *  }
 *
 *  When string is returned:
 *      It's a simple FORM LEVEL error message
 *
 *  When exception is thrown: same goes for exception values as for other returned values from onSubmit
 *
 *  When promise is returned, we Excpect error to be called on exception, or returned value, which is then handled the same way as return values.
 *
 */

const withAppForm = ( WrappedComponent ) => {

    return class extends Component {

        renderWrappedComponent = (formikProps) => {
            return <Form><WrappedComponent {...this.props} {...formikProps} /></Form>;
        }

        /**
         * This will handle the ret value, if it 
         * is not a promise.
         */
        handleSubmitReturn = (ret, formikBag) => {
            const { resetAfterSubmit } = this.props;

            const retStatus = {
                isError: true,
                field: undefined,
                status: {
                    type: 'error',
                    message: 'Unknown error'
                }
            };
            if (isPlainObject(ret)) {
                if (isString(ret.type) && isPlainObject(ret.payload)) {
                    if (ret.type.indexOf('error') > 0) {
                        retStatus.isError = true;
                        /**
                         * Check if we can get the error message out of payload.
                         */
                        const { payload } = ret;
                        /**
                         * This is our standard error message return
                         */
                        if (payload.error && payload.error.response && payload.error.response.message) {
                            /**
                             * Validation will return field also
                             */
                            if (payload.error.response.field) {
                                retStatus.field = payload.error.response.field;
                            }
                            retStatus.status.message = payload.error.response.message;
                        /**
                         * We get this from fetch (probably all general exceptions)
                         */
                        } else if (payload.error && payload.error.message && typeof payload.error.message === 'string') {
                            retStatus.status.message = payload.error.message;
                            if (payload.error.field) {
                                retStatus.field = payload.error.field;
                            }
                        }
                    } else {
                        retStatus.isError = false;
                    }
                }
            } else if (isString(ret)) {
                console.error(' >> Attempt to solve unsupported ret: string: ', ret);
            } else if (isBoolean(ret)) {
                if (ret) {
                    retStatus.isError = false;
                }
            }
            else {
                console.error('Unhandled submit ret: ', ret);
                console.error('Unhandled submit ret type: ', (typeof ret));
            }

            /**
             * Set submitting to false
             */
            formikBag.setSubmitting(false);
            if (resetAfterSubmit) {
                formikBag.resetForm();
            }

            if (retStatus.isError) {
                if (retStatus.field === undefined) {
                    formikBag.setStatus(retStatus.status);
                } else {
                    formikBag.setFieldError(retStatus.field, retStatus.status.message);
                }
            }
        }

        handleSubmit = ( values, formikBag ) => {
            const { onSubmit } = this.props;

            let ret = undefined;
            try {
                ret = onSubmit( values );
            } catch (ret) {

            }
            if (ret instanceof Promise) {
                ret.then( ret => {
                    this.handleSubmitReturn(ret, formikBag);
                }).catch( ret => {
                    this.handleSubmitReturn(ret, formikBag);
                });
                return ret;
            } else {
                return this.handleSubmitReturn(ret, formikBag);
            }
        }

        render() {
            const {
                initialValues = undefined,
                onSubmit = undefined,
                resetAfterSubmit = false,
                enableReinitialize = true,
                error = false,
                ...props
            } = this.props;

            invariant(initialValues !== undefined, 'withAppForm: Formik needs initialValues as prop, so it knows which fields are registered');
            invariant(isFunction(onSubmit), 'withAppForm: Formik needs onSubmit function as prop');
            invariant(isBoolean(resetAfterSubmit), 'withAppForm: resetAfterSubmit prop should be boolean!');

            const initialStatus = (error === false ? undefined : { type: 'error', ...error });

            return (
                <Formik
                    initialValues={initialValues}
                    onSubmit={this.handleSubmit}
                    enableReinitialize={enableReinitialize}
                    initialStatus={initialStatus}
                    validateOnMount={true}
                    {...props}
                    component={this.renderWrappedComponent} />
            );
        }
    }
};

export default withAppForm;
