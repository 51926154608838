import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import * as Yup from 'yup';

import { openSnackbar } from 'app/components/snackbar';

import { orderOperations } from './../../state/ducks/order';
import * as orderConst from './../../const/order';

import ViewEdit from 'app/components/viewedit';

import Grid from '@material-ui/core/Grid';

import ErrorAlert from 'app/components/error';
import LinearProgress from '@material-ui/core/LinearProgress';

import utils from 'app/utils';
import coinUtils from '../../utils';

import { OrderWizardContextProvider } from './order/wizard/context';
import OrderWizard from './order/wizard';
import ViewOrderPage from './order/view';

const orderValidation = Yup.object().shape({
    type: Yup.string()
            .nullable()
            .required(),
    name: Yup.string()
            .min(2, 'Name too short')
            .max(50, 'Name too long')
            .required('Name is required'),
    settings: Yup.string()
});

const emptyOrder = {
    id: '',
    type: orderConst.T_BUY,
    status: orderConst.S_WAIT,
    s_exchange_id: null,
    s_currency_id: null,
    s_amount: null,
    d_exchange_id: null,
    d_currency_id: null,
    d_amount: null,
    fee_amount: null,
    fee_currency_id: null,
    created_at: null,
    created_by: null,
    updated_at: null,
    updated_by: null
};

/**
 * Extract action and oid from react-router match prop
 */
const orderPage = ( { match: { params: { action, oid } }, location: { hash }, history } ) => {

    /**
     * Are we editing
     */
    const edit = (action === 'add' || action === 'edit');

    /**
     * Get optional step from hash
     */
    const step = useMemo( () => {
        return hash.length > 1 && hash.startsWith('#') ? parseInt(hash.substr(1), 10) : 0;
    }, [ hash ]);

    /**
     * Dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Get order from Api
     */
    const get = useCallback( id => dispatch( orderOperations.get( 'order', id ) ) , [ dispatch ]);
    /**
     * Update order via Api
     */
    const update = useCallback( ( id, data ) => dispatch( orderOperations.update( 'order', id, data ) ), [ dispatch ] );
    /**
     * Create new order via Api
     */
    const create = useCallback( data => dispatch( orderOperations.create( 'order', data ) ) , [ dispatch ] );
    /**
     * Add single user to order
     */
    const addUser = useCallback( (order_id, user_id, percentage) => dispatch( orderOperations.addUser( 'order', { order_id, user_id, percentage } ) ), [ dispatch ] );
    /**
     * Remove user from order
     */
    const removeUser = useCallback( (order_id, user_id) => dispatch( orderOperations.removeUser( 'order', { order_id, user_id } ) ), [ dispatch ] );
    /**
     * Fill order with users that have available balance
     */
    const fillUsers = useCallback( (order_id, user_ids = []) => dispatch( orderOperations.fillUsers( 'order', { order_id, user_ids } ) ), [ dispatch ] );
    /**
     * Process order
     */
    const process = useCallback( id => dispatch( orderOperations.process( 'order', id ) ), [ dispatch ] );
    /**
     * Unprocess order
     */
    const unprocess = useCallback( id => dispatch( orderOperations.unprocess( 'order', id ) ), [ dispatch ] );
    /**
     * Clean order blrf object
     */
    const clear = useCallback( () => dispatch( orderOperations.clear( 'order' ) ), [ dispatch ] );
    /**
     * Fetch order blrf object. If object is not yet available return default blrf object stored in state.
     */
    const orderObject = useSelector( state => state.app.blrf.objects.order || state.app.blrf.defaultObject );

    /**
     * Get order from blrf object data
     */
    const order = useMemo( () => {
        return orderObject.data.find(v => v) || emptyOrder;
    }, [ orderObject.data, action ]);

    /**
     * Get loading and error from blrf order object
     */
    const { loading, error } = orderObject;

    /**
     * Call get order from Api if oid changed
     *
     * And register unmount function that will clear the order object.
     * This is important because, when we later arrive to this page and
     * oid has changed child components will still see the old order and
     * we will get double queries. One for old and one for new and data
     * will most likely be incorrect.
     */
    useEffect( () => {
        if (oid > 0) {
            get( oid );
        }
        return clear;
    }, [ oid, get, clear ]);

    return (
        <Fragment>
            {error === false && loading && <LinearProgress />}
            {error !== false && <ErrorAlert error={error} />}
            {order && edit &&
                <OrderWizardContextProvider
                    order={order}
                    get={get}
                    update={update}
                    create={create}
                    addUser={addUser}
                    removeUser={removeUser}
                    fillUsers={fillUsers}
                    process={process}
                    unprocess={unprocess}
                    step={step}
                    history={history}>
                    <OrderWizard />
                </OrderWizardContextProvider>
            }
            {order && !edit &&
                <ViewOrderPage order={order} />
            }
        </Fragment>
    );
}

orderPage.propTypes = {
    match: PropTypes.object.isRequired
};

export default orderPage;
