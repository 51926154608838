import React, { useMemo } from 'react';

import { default as MuiTableBody } from '@material-ui/core/TableBody';
import TableRow from './row';

const TableBody = ({data, renderRow, actions, ...row}) => {

    const renderTableData = useMemo(
        () => data.map(renderRow).map( (row, key) => <TableRow key={key} actions={actions} {...row} data={data[key]} />),
        [ data, renderRow, actions ]
    );

    return (
        <MuiTableBody>
            {renderTableData}
        </MuiTableBody>
    );
};

export default TableBody;
