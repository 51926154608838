import React, { useMemo } from 'react';

import ResourceActions from './actions';

import { Tabs } from 'app/components';

const RoleAclModule = ( { module, ...props } ) => {
    const resources = module.resources;
    const panes = useMemo( () => {
        const panes = [];
        for ( let i = 0; i < resources.length; i++ ) {
            const r = resources[i];
            panes.push({
                tab: { label: r.name },
                content: <ResourceActions module={module} resource={r} {...props} />
            });
        }
        return panes;
    }, [ module, resources, props ]);

    return (
        <Tabs
            panes={panes} />
    );

}

export default RoleAclModule;
