import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { connect } from 'react-redux'

import { pmProcessOperations } from './../../../state/ducks/pm/process';

import { Table } from './../../../../../app/components';

import DescriptionIcon from '@material-ui/icons/Description';

import utils from './../../../../../app/utils';

class pmProcessesPage extends Component {

    static propTypes = {
        loading: PropTypes.bool.isRequired,
        query: PropTypes.object.isRequired,
        meta: PropTypes.object,
        data: PropTypes.array.isRequired,
        search: PropTypes.func.isRequired,
    };

    static defaultProps = {
        meta: null
    };

    fields = [
        {
            label: 'Pid',
            name: 'pid',
            type: 'number',
            width: 1,
        },
        {
            label: 'Hostname',
            name: 'hostname',
            width: 3,
        },
        {
            label: 'Status',
            name: 'status',
            width: 2
        },
        {
            label: 'Routing key',
            name: 'routing_key',
            width: 2
        },
        {
            label: 'Command',
            name: 'command',
            width: 2
        },
        {
            label: 'Exit code',
            name: 'exit_code',
            width: 1,
        },
        {
            label: 'Term signal',
            name: 'term_signal',
            width: 1
        }
    ];

    renderRow = ({ pid, hostname, status, routing_key, command, exit_code, term_signal }, i) => ({
        key: `${pid}-${hostname}`,
        variant: (status === 'DEAD' ? 'disabled' : ( status === 'RUNNING' ? 'success' : undefined ) ),
        cells: [
            pid,
            hostname,
            status,
            routing_key,
            command,
            exit_code,
            term_signal
        ]
    });

    actions = [
        {
            icon: <DescriptionIcon />,
            component: Link,
            to: utils.getFullPath('admin/pm/processs/view/:id')
        },
    ];

    render() {
        const { loading, ...props } = this.props;
        return (
            <Table
                fields={this.fields}
                renderRow={this.renderRow}
                actions={this.actions}
                loading={loading}
                {...props}
                />
        );
    }
}

const mapStateToProps = ( state, ownProps ) => {

    const {
        loading = false,
        query = { refs: 1, page: 1 },
        data = [],
        meta = null,
        error = false
    } = state.app.blrf.objects.pmProcesses || {};

    return {
        loading, 
        query, 
        data,
        meta,
        error
    }
};

const mapDispatchToProps = {
    search: ( query ) => pmProcessOperations.search( 'pmProcesses', query ),
    remove: ( id ) => pmProcessOperations.remove( 'pmProcesses', id ),
};

export default connect( mapStateToProps, mapDispatchToProps )( pmProcessesPage );
