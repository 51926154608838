/**
 * Check if type has /start
 */
export function isActionStart(type) {
    return type.endsWith('/start');
}

/**
 * Check if type has /ok or /error 
 */
export function isActionStop(type) {
    return type.endsWith('/ok') || type.endsWith('/error');
}
