import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import * as Yup from 'yup';

import { openSnackbar } from 'app/components/snackbar';

import { OAuth2AccessTokenOperations } from './../../../state/ducks/oauth2/accessToken';
import {OAuth2ClientComponents} from './../../../state/ducks/oauth2/client';

import ViewEdit from 'app/components/viewedit';
import ErrorAlert from 'app/components/error';
import LinearProgress from '@material-ui/core/LinearProgress';

import utils from 'app/utils';
import {userComponents} from "../../../state/ducks/user";

const accessTokenValidation = Yup.object().shape({
    id: Yup.number(),
    client_id: Yup.string()
        .required('Id is required')
        .matches(/^[0-9A-Za-z]+$/, {
            message:'Only numbers and letters allowed',
            excludeEmptyString: true
        }),
    user_id: Yup.number().required(),
});

const emptyAccessToken = {
    id: '',
    client_id: '',
    user_id: '',
    expiry_datetime: '',
    RefreshTokens: [],
    created_at: null,
    created_by: null,
    updated_at: null,
    updated_by: null
};

const OAuth2AccessTokenPage = ( { match: { params: { action, atid } } } ) => {

    /**
     * Are we editing
     */
    const edit = (action === 'add' || action === 'edit');

    /**
     * Dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Get accessToken from Api
     */
    const get = useCallback( id => dispatch( OAuth2AccessTokenOperations.get( 'accessToken', id ) ) , [ dispatch ]);

    /**
     * Update accessToken via Api
     */
    const update = useCallback( ( id, data ) => dispatch( OAuth2AccessTokenOperations.update( 'accessToken', id, data ) ), [ dispatch ] );
    /**
     * Create new accessToken via Api
     */
    const create = useCallback( data => dispatch( OAuth2AccessTokenOperations.create( 'accessToken', data ) ) , [ dispatch ] );
    /**
     * Clean accessToken blrf object
     */
    const clear = useCallback( () => dispatch( OAuth2AccessTokenOperations.clear( 'accessToken' ) ), [ dispatch ] );
    /**
     * Fetch accessToken blrf object. If object is not yet available return default blrf object stored in state.
     */
    const accessTokenObject = useSelector( state => state.app.blrf.objects.accessToken || state.app.blrf.defaultObject );

    /**
     * Get accessToken from blrf object data
     */
    const accessToken = useMemo( () => {
        return ( action === 'add' ? emptyAccessToken : accessTokenObject.data.find(v => v) || emptyAccessToken)
    }, [ accessTokenObject.data, action ]);

    /**
     * Get loading and error from blrf accessToken object
     */
    const { loading, error } = accessTokenObject;

    /**
     * Call get accessToken from Api if aid changed
     *
     * And register unmount function that will clear the Access Token object.
     * This is important because, when we later arrive to this page and
     * aid has changed child components will still see the old Access Token and
     * we will get double queries. One for old and one for new and data
     * will most likely be incorrect.
     */
    useEffect( () => {
        if (atid && atid.length > 0) {
            get( atid );
        }
        return clear;
    }, [ atid, get, clear ]);

    const fields = [
        {
            label: 'Id',
            name: 'id',
            disabled: true,
            value: `${accessToken.id.substring(0, 8)}...${accessToken.id.slice(-8)}`,
            width: 4
        },
        {
            label: 'Client id',
            name: 'client_id',
            value: (accessToken.Client ? ( edit ? accessToken.client_id : accessToken.Client.name) : null ),
            component: OAuth2ClientComponents.clientsSelectField ,
            width: 4,
        },
        {
            label: 'User',
            name: 'user_id',
            value: (accessToken.User ? ( edit ? accessToken.user_id : accessToken.User.name) : null ),
            component: userComponents.usersSelectField,
            width: 3
        },
        {
            label: 'Expiry datetime',
            name: 'expiry_datetime',
            value: accessToken.expiry_datetime,
            searchable: false,
            width: 3
        },
        {
            label: 'Created by',
            name: 'created_by',
            value: accessToken.created_by ,
            width: 2,
            edit: false
        },
        {
            label: 'Created at',
            name: 'created_at',
            value: accessToken.created_at ,
            width: 2,
            edit: false
        },
        {
            label: 'Refresh Token',
            name: 'RefreshTokens.id',
            value: accessToken.RefreshTokens.length > 0 ? `${accessToken.RefreshTokens[0].id.substring(0, 8)}...${accessToken.RefreshTokens[0].id.slice(-8)}` : '/',
            width: 8,
            edit: false
        },
        {
            label: 'Refresh Token expiry datetime',
            name: 'RefreshTokens.expiry_datetime',
            value: accessToken.RefreshTokens.length > 0 ? accessToken.RefreshTokens[0].expiry_datetime : '/',
            width: 4,
            edit: false
        }
    ];

    const onSubmit = ( values ) => {
        let p = null;
        if (action === 'add') {
            p = create(values);
        } else {
            p = update( values.id, values );
        }
        p.then(ret => {
            if (ret.type.indexOf('error') > 0) {
                openSnackbar('Error while saving Access Token', 'error');
            } else {
                openSnackbar('Access Token saved', 'success');
                window.history.go( -1 );
            }
        } );
        return p;
    }

    return (
        <Fragment>
            {error === false && loading && <LinearProgress />}
            {error !== false && <ErrorAlert error={error} />}
            {accessToken &&
            <ViewEdit
                header={{ title: (accessToken.id.length > 0 ? 'Edit accessToken' : 'Add accessToken') }}
                onSubmit={onSubmit}
                fields={fields}
                validationSchema={accessTokenValidation}
                loading={loading}
                edit={edit}
                editButton={{ component: Link, to: utils.getFullPath(`admin/oauth2/accessToken/edit/${accessToken.id}`) }}
            />
            }
        </Fragment>
    );
}

OAuth2AccessTokenPage.propTypes = {
    match: PropTypes.object.isRequired
};

export default OAuth2AccessTokenPage;