import React, { Fragment, useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { OAuth2AuthCodeOperations } from './../../../state/ducks/oauth2/authCode';
import { OAuth2ClientComponents } from './../../../state/ducks/oauth2/client';
import { userComponents } from './../../../state/ducks/user';

import { Table } from 'app/components';

import { Link } from 'react-router-dom';

import Fab from '@material-ui/core/Fab';

import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';

import { openSnackbar } from 'app/components/snackbar';

import utils from 'app/utils';

const OAuth2AuthCodessPage = () => {

    /**
     * Take dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Search for authCodes via Api
     */
    const search = useCallback( query => dispatch( OAuth2AuthCodeOperations.search( 'authCodes', query ) ), [ dispatch ] );

    /**
     * Remove authCode via Api
     */
    const remove = useCallback( id => dispatch( OAuth2AuthCodeOperations.remove( 'authCode', id ) ), [ dispatch ] );

    /**
     * OAuth2AuthCodess blrf object
     */
    const authCodesObject = useSelector( state => state.app.blrf.objects.authCodes || state.app.blrf.defaultObject );

    const { data, meta } = useMemo( () => {
        return {
            data: authCodesObject.data,
            meta: authCodesObject.meta
        }
    }, [ authCodesObject ]);

    const query = useMemo( () => {
        /**
         * When object is first created query will only contain { refs: 0, page: 1 }
         *
         * We want refs, so we change that. And we want to have default order.
         * All other aspects of query object is controlled via
         * Table component.
         */
         const hasOrder = Array.isArray(authCodesObject.query.order) && authCodesObject.query.order.length > 0;
         if (!Array.isArray(authCodesObject.query.refs) || !hasOrder) {
            return { ...authCodesObject.query, refs: ['Client', 'User'], order: (hasOrder ? [...authCodesObject.query.order ] : ['expiry_datetime DESC'] ) };
         }
         return authCodesObject.query;
    }, [ authCodesObject.query ] );

    const {
        loading,
        error
    } = authCodesObject;

    const fields = [
        {
            label: 'Id',
            name: 'id',
            width: 2,
        },
        {
            label: 'Client',
            name: 'client_id',
            width: 4,
            component: OAuth2ClientComponents.clientsSelectField
        },
        {
            label: 'User',
            name: 'user_id',
            component: userComponents.usersSelectField,
            width: 4
        },
        {
            label: 'Expiry',
            name: 'expiry_datetime',
            width: 2,
            searchable: false
        }
    ];


    const renderRow = ({ id, Client, User, expiry_datetime }, i) => ({
        key: id,
        cells: [
            id,
            Client.name,
            User.name,
            expiry_datetime
        ]
    });

    const actions = [
        {
            icon: <DescriptionIcon />,
            component: Link,
            to: utils.getFullPath('admin/oauth2/authCode/view/:id')
        },
        {
            icon: <CreateIcon />,
            component: Link,
            to: utils.getFullPath('admin/oauth2/authCode/edit/:id')
        },
        {
            icon: <DeleteIcon />,
            onClick: ( data ) => removeAuthCode(data.id),
            confirm: {
                content: 'Are you sure you want to delete authCode :name ?'
            }
        }
    ];

    const removeAuthCode = async( id ) => {
        const ret = await remove( id );
        if (ret.type.indexOf('error') > 0) {
            openSnackbar('Failed to delete authCode', 'error');
        } else {
            openSnackbar('AuthCode deleted', 'success');
            search( query );
        }
    }


    return (
        <Fragment>
            <Table
                fields={fields}
                renderRow={renderRow}
                actions={actions}
                loading={loading}
                search={search}
                query={query}
                data={data}
                meta={meta}
                error={error}
                />
            <Fab color='primary' style={{ float: 'right' }} variant='extended' component={Link} to={utils.getFullPath('admin/oauth2/authCode/add')}>
                <AddIcon />
                Add Auth Code
            </Fab>
        </Fragment>
    );
};

export default OAuth2AuthCodessPage;























/*
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { oauth2AuthCodesOperations } from './../../../state/ducks/oauth2/authCode';
import { Table } from 'app/components';
import Fab from '@material-ui/core/Fab';

import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';

import { openSnackbar } from 'app/components/snackbar';

import utils from 'app/utils';

class OAuth2AuthCodessPage extends Component {

    static propTypes = {
        loading: PropTypes.bool.isRequired,
        query: PropTypes.object.isRequired,
        meta: PropTypes.object,
        data: PropTypes.array.isRequired,
        search: PropTypes.func.isRequired,
        remove: PropTypes.func.isRequired,
    };

    static defaultProps = {
        meta: null
    };

    fields = [
        {
            label: 'Id',
            name: 'id',
            width: 4,
        },
        {
            label: 'Name',
            name: 'name',
            width: 4,
        },
        {
            label: 'Created by',
            name: 'created_by',
            width: 2
        },
        {
            label: 'Updated at',
            name: 'updated_at',
            width: 2  
        },
        {
            label: 'Updated by',
            name: 'updated_by',
            width: 2  
        }
    ];


    renderRow = ({ id, name, created_by, updated_at, updated_by}, i) => ({
        key: id,
        cells: [
            id,
            name,
            created_by,
            updated_at,
            updated_by
        ]
    });

    actions = [
        {
            icon: <DescriptionIcon />,
            component: Link,
            to: utils.getFullPath('admin/oauth2/authCode/view/:id')
        },
        {
            icon: <CreateIcon />,
            component: Link,
            to: utils.getFullPath('admin/oauth2/authCode/edit/:id')
        },
        {
            icon: <DeleteIcon />,
            onClick: ( data ) => this.removeAuthCodes(data.id),
            confirm: {
                content: 'Are you sure you want to delete authCode :name ?'
            }
        }
    ];

    async removeAuthCodes ( id ) {
        const { remove, search, query } = this.props;
        const ret = await remove( id );
        if (ret.type.indexOf('error') > 0) {
            openSnackbar('Failed to delete authCode', 'error');
        } else {
            openSnackbar('authCode deleted', 'success');
            search( query );
        }
    }

    render() {
        const { loading, ...props } = this.props;
        return (
            <Fragment>
                <Table
                    formId='admin/form/oauth2/authCode/search'
                    fields={this.fields}
                    renderRow={this.renderRow}
                    actions={this.actions}
                    loading={loading}
                    {...props}
                    />
                <Fab color='primary' style={{ float: 'right' }} variant='extended' component={Link} to={utils.getFullPath('admin/oauth2/authCode/add')}>
                    <AddIcon />
                    Add authCode
                </Fab>
            </Fragment>
        );
    }
}

const mapStateToProps = ( state, ownProps ) => {

    const {
        loading = false,
        query = { refs: 1, page: 1 },
        data = [],
        meta = null,
        error = false
    } = state.app.blrf.objects.authCode || {};

    return {
        loading, 
        query, 
        data,
        meta,
        error
    }
};

const mapDispatchToProps = {
    search: ( query ) => oauth2AuthCodesOperations.search( 'authCode', query ),
    remove: ( id ) => oauth2AuthCodesOperations.remove( 'authCode', id ),
};

export default connect( mapStateToProps, mapDispatchToProps )( OAuth2AuthCodessPage );
*/

























/*
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { oauth2AuthCodeOperations } from './../../../state/ducks/oauth2/authCode';
import { OAuth2ClientComponents } from './../../../state/ducks/oauth2/client';
import { userComponents } from './../../../state/ducks/user';
import { Table } from 'app/components';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import { openSnackbar } from 'app/components/snackbar';


import utils from 'app/utils';

class oauth2AuthCodesPage extends Component {

    static propTypes = {
        loading: PropTypes.bool.isRequired,
        query: PropTypes.object.isRequired,
        meta: PropTypes.object,
        data: PropTypes.array.isRequired,
        search: PropTypes.func.isRequired,
        remove: PropTypes.func.isRequired,
    };

    static defaultProps = {
        meta: null
    };

    fields = [
        {
            label: 'Id',
            name: 'id',
            width: 1,
        },
        {
            label: 'Client id',
            name: 'client_id',
            component: OAuth2ClientComponents.clientsSelectField,
            width: 3,
        },
        {
            label: 'User',
            name: 'user_id',
            component: userComponents.usersSelectField,
            width: 3
        },
        {
            label: 'Expiry datetime',
            name: 'expiry_datetime',
            searchable: false,
            width: 2
        }
    ];

    renderRow = ({ id, client_id, User, expiry_datetime, created_by}, i) => ({
        key: id,
        cells: [
            id,
            client_id,
            User.name,
            expiry_datetime,
            created_by
        ]
    });

    actions = [
        {
            icon: <DescriptionIcon />,
            component: Link,
            to: utils.getFullPath('admin/oauth2/authCode/view/:id')
        },
        {
            icon: <DeleteIcon />,
            onClick: ( data ) => this.removeCode(data.id),
            confirm: {
                content: 'Are you sure you want to delete Auth Code?'
            }
        }
    ];

    async removeCode ( id ) {
        const { remove, search, query } = this.props;
        const ret = await remove( id );
        if (ret.type.indexOf('error') > 0) {
            openSnackbar('Failed to delete Auth Code', 'error');
        } else {
            openSnackbar('Auth Code deleted', 'success');
            search( query );
        }
    }

    render() {
        const { loading, ...props } = this.props;
        return (
            <Fragment>
                <Table
                    fields={this.fields}
                    renderRow={this.renderRow}
                    actions={this.actions}
                    loading={loading}
                    {...props}
                    />
            </Fragment>
        );
    }
}

const mapStateToProps = ( state, ownProps ) => {

    const {
        loading = false,
        query = { refs: 1, page: 1 },
        data = [],
        meta = null,
        error = false
    } = state.app.blrf.objects.oauth2authCode || {};

    return {
        loading, 
        query, 
        data,
        meta,
        error
    }
};

const mapDispatchToProps = {
    search: ( query ) => oauth2AuthCodeOperations.search( 'oauth2authCode', query ),
    remove: ( id ) => oauth2AuthCodeOperations.remove( 'oauth2authCode', id ),
};

export default connect( mapStateToProps, mapDispatchToProps )( oauth2AuthCodesPage );
*/