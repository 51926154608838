import React, { Component, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as AsyncSelect }from 'react-select/async';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 250,
    },
    input: {
        display: 'flex',
        padding: 0,
        height: '32px',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
    },
    chip: {
        margin: '-6px 0 -7px',
        height: 24,
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
    paper: {
        marginTop: theme.spacing(1),
    },
    divider: {
        height: theme.spacing(2),
    },
});

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    return (
        <TextField
            fullWidth
            margin='normal'
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}

function Option(props) {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused || props.isSelected}
            component='div'
            disabled={props.isDisabled}
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={classNames(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={event => {
                props.removeProps.onClick();
                props.removeProps.onMouseDown(event);
            }}
        />
    );
}

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

const components = {
    Option,
    Control,
    NoOptionsMessage,
    Placeholder,
    SingleValue,
    MultiValue,
    ValueContainer,
    Menu,
};

class MuiSelect extends Component {

    state = {
        inputValue: ''
    }

    handleInputChange = (newValue: string) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };

    merge = (a, b, p) => a.filter( aa => ! b.find ( bb => aa[p] === bb[p]) ).concat(b);

    /**
     * Debounce returning promise, so we can use it in loadOptions
     * Found here: https://stackoverflow.com/a/54265129/3414333
     */
    debounce = (f, interval) => {
        let timer = null;

        return (...args) => {
            clearTimeout(timer);
            return new Promise((resolve) => {
                timer = setTimeout(
                    () => resolve(f(...args)),
                    interval,
                );
            });
        };
    }

    /**
     * Wait for 300ms for user to finish writing input.
     *
     * This would minimze the queries to backend while user is typing.
     */
    loadOptions = this.debounce( (inputValue = '') => {
        const { select } = this.props;
        const {
            onSearchEvent,
            defaultData = null,
            defaultOptions = [],
            map
        } = select;

        if (onSearchEvent) {

            let _defaultOptions = defaultOptions;

            if (defaultData) {
                _defaultOptions = this.merge(_defaultOptions, defaultData.map(map), 'value');
            }
            return onSearchEvent(inputValue).then( ( { type, payload: { data } }) => {
                return new Promise((resolve, reject) => { type.endsWith('/error') ? reject('error') : resolve(this.merge(_defaultOptions, data.map(map), 'value')) } );
            });
        }
        return null;
    }, 300);

    onChange = ( a, b ) => {

        const {
            field,
            form,
            select = {},
            onChange = undefined
        } = this.props;

        const {
            multiple = false
        } = select;

        let newVal = null;
        if (multiple) {
            newVal = Array.isArray(a) ? a.map( (opt) => opt.value ) : null;
        } else {
            newVal = (a ? a.value : null);
        }

        /**
         * Can't use onChange anymore since Formik does't support
         * onChange with direct value and we don't have access to onChange event
         */
        form.setFieldValue(field.name, newVal);
        /**
         * We can still have onChange event defined in field
         */
        if (onChange) {
            onChange( undefined, newVal);
        }
    }

    onBlur = ( ev) => {
        const { form, field } = this.props;
        form.setFieldTouched(field.name, true);
    }

    getOptionValue = ( option ) => {
        return option.value;
    };

    getOptionLabel = ( option ) => {
        return option.label;
    };

    isOptionSelected = (option, currentValue) => {
        const { select = {} } = this.props;
        const { multiple = false } = select;

        if (multiple) {
            return currentValue.indexOf(option) > -1;
        } else {
            return option.value === currentValue;
        }
    };

    /**
     * Because react-select requires value to be a whole object
     * we need to find it via data and map and value that we provide.
     *
     * Explanation: https://github.com/JedWatson/react-select/issues/2920
     */
    getSelectValue = ( value ) => {
        const {
            select = {}
        } = this.props;
        const {
            data = null, 
            defaultData = null,
            defaultOptions = [],
            map,
            multiple = false
        } = select;

        let _defaultOptions = defaultOptions;

        if (defaultData) {
            _defaultOptions = this.merge(defaultData.map( map ), _defaultOptions,  'value');
        }
        let dataOptions = [];
        if (data) {
            dataOptions = data.map( map );
        }
        const options = this.merge(dataOptions, _defaultOptions, 'value');

        if (multiple) {
            /**
             * Value should be an array
             */

            const ret = Array.isArray(value) ? options.filter( opt => value.indexOf(opt.value) > -1 ) : null;
            return ret;
        } else {
            /**
             * options.filter()[0] would return undefined, but we need null
             * if we want to reset the select input.
             */
            const ret = options.filter( opt => opt.value === value)[0];
            return ret ? ret : null;
        }
    };

    render() {
        const {
            /**
             * From withStyles()
             */
            classes,
            theme,
            /**
             * From Formik
             */
            field,
            form,
            /**
             * Our fields
             */
            label,
            margin = 'normal',
            placeholder,
            disabled = false,
            error = false,

            /**
             * Our select props
             */
            select = {}
        } = this.props;

        const touched = form.touched[field.name] ? form.touched[field.name] : false;
        const invalid = form.errors[field.name] ? true : false;
        const isError = error !== false || (touched && invalid);
        const formError = form.errors[field.name];
        const value = field.value;

        /**
         * Read default select props
         */
        const {
            multiple = false,
            loading = false,
            onSearchEvent,
            defaultOptions,
            isOptionDisabled, 

            /**
             * React select props
             */
            isClearable = false,
            isSearchable = false,
            menuPortalTarget = document.body,
        } = select;

        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
            }),
            dropdownIndicator: base => ({
                ...base,
                padding: '6px'
            }),
            /**
             * see: https://react-select.com/advanced#portaling
             */
            menuPortal: base => ({
                ...base,
                zIndex: 9999 
            })
        };

        return (
            <AsyncSelect
                classes={classes}
                styles={selectStyles}
                textFieldProps={{
                    label,
                    disabled,
                    margin,
                    error: isError,
                    helperText: (isError ? formError || error : undefined),
                    InputLabelProps: {
                        error: isError,
                        shrink: true,
                    },
                }}
                cacheOptions
                defaultOptions={onSearchEvent ? true : defaultOptions}
                loadOptions={onSearchEvent ? this.loadOptions : undefined }
                onInputChange={this.handleInputChange}
                isOptionDisabled={isOptionDisabled}
                getOptionValue={this.getOptionValue}
                getOptionLabel={this.getOptionLabel}
                isLoading={loading || field.loading}
                components={components}
                value={this.getSelectValue(value)}
                onChange={this.onChange}
                onBlur={this.onBlur}
                placeholder={placeholder}
                isMulti={multiple}
                isDisabled={error !== false || disabled}
                closeMenuOnSelect={!multiple}
                menuPortalTarget={menuPortalTarget}
                isClearable={isClearable}
                isSearchable={isSearchable}
                backspaceRemovesValue={true}
            />
        );
    }
}

MuiSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const selectField = withStyles(styles, { withTheme: true })(MuiSelect);

export default selectField;

const connectedSelectField = ( ) => {

    return ({
        data,
        loading,
        select,
        search,
        query,
        defaultData,
        as,
        ...restProps
    }) => {

        const WrappedComponent = selectField;

        const defaultSelect = useMemo( () => {
            return {
                data,
                loading,
                defaultOptions: (Array.isArray(defaultData) ? defaultData.map(select.map) : undefined),
                isSearchable: true,
                isClearable: true
            }
        },
        [ data, loading, defaultData, select.map ]);

        const SelectObject = useMemo(() => {
            return {
                ...defaultSelect,
                ...select
            }
        }, [ defaultSelect, select]);

        return(
            <WrappedComponent
                select={SelectObject}
                {...restProps}/>
        );
    }
};

export {
    connectedSelectField
};