import actions from './actions';

const { get, update, create, search, remove, clear, updateSingle } = actions;

export {
    get,
    update,
    create, 
    search,
    remove,
    clear,
    updateSingle
};
