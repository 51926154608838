import React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import invariant from 'invariant';

/**
 * Removed ...custom from <Input ...> as we get a lot of props that are simply not valid.
 * If any prop is needed, put it in by hand with default value.
 */
const StaticField = ({
    /**
     * Props for Material-UI
     */
    fullWidth = true,
    margin = 'normal',
    autoFocus = false,
    variant = 'standard',

    /**
     * Our fields
     */
    id = null,
    label = undefined,
    help = undefined,
    disabled = false,
    loading = false,
    edit = false,
    style = {},
    
    /**
     * Formik form
     */
    form, 
    /**
     * Formik field
     */
    field
}) => {
    invariant(!(field.name === null && id === null), 'StaticField expects name or id to be provided in props');
    const labelBy = (id === null ? field.name : id);

    return (
        <FormControl fullWidth={fullWidth} margin={margin}>
            <InputLabel shrink htmlFor={labelBy}>{label}</InputLabel>
            <div style={{ ...style, paddingTop: '20px'}}>{field.value || <span>&nbsp;</span>}</div>
        </FormControl>
    );
};

export default StaticField;
