import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import SignalWifi4BarIcon from '@material-ui/icons/SignalWifi4Bar';
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import SignalCellularConnectedNoInternet0BarIcon from '@material-ui/icons/SignalCellularConnectedNoInternet0Bar';

export default class AppNavbarWsConnections extends Component {

    static propTypes = {
        connections: PropTypes.object.isRequired
    };

    state = {
        anchorEl: null
    };

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    renderMenuItem = ( conn ) => (
        <MenuItem key={this.props.connections[conn].key} onClick={this.handleClose}>
            <ListItemIcon>
                {this.props.connections[conn].connected ? <SignalCellularAltIcon /> : <SignalCellularConnectedNoInternet0BarIcon color='error' />}
            </ListItemIcon>
            <ListItemText inset primary={this.props.connections[conn].key} />
        </MenuItem>
    );

    renderMenu = () => {
        const { connections } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        let state = true;
        Object.keys(connections).forEach( ( c) => {
            if (connections[c].connected === false) {
                state = false;
            }
        });
        return(
            <Fragment>
                <IconButton
                    aria-owns={open ? 'menu-ws' : null}
                    aria-haspopup="true"
                    onClick={this.handleMenu}
                    color="inherit"
                >
                    { state ? <SignalWifi4BarIcon /> : <SignalWifiOffIcon color='error' /> }
                </IconButton>
                <Menu
                    id="menu-ws"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={this.handleClose}
                >
                    {Object.keys(connections).map(this.renderMenuItem)}
                </Menu>
            </Fragment>
        );
    }

    render() {
        const { connections } = this.props;
        const keys = Object.keys(connections);
        if (keys.length > 0) {
            return this.renderMenu();
        } else {
            return null;
        }
    }
}
