import React, { useCallback, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { pingOperations } from './../../../state/ducks/ping';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';


import { openSnackbar } from 'app/components/snackbar';
import ErrorAlert from 'app/components/error';
import Button from 'app/components/button';

/**
 * Should provide single ping and ping with chart
 */
const SinglePing = () => {

    /**
     * Dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * When did we request ping
     */
    const [ reqTime, setReqTime ] = useState(null);
    /**
     * Pong time in response
     */
    const [ repTime, setRepTime ] = useState(null);
    /**
     * When did we process response
     */
    const [ resTime, setResTime ] = useState(null);

    /**
     * Call ping api method
     */
    const ping = useCallback( () => dispatch( pingOperations.ping( 'ping' ) ), [ dispatch ] );

    /**
     * Fetch ping blrf object. If object is not yet available return default blrf object stored in state.
     */
    const pingObject = useSelector( state => state.app.blrf.objects.ping || state.app.blrf.defaultObject );

    /**
     * Get loading
     */
    const {
        loading,
        error
    } = pingObject;

    const sendPing = () => {
        setRepTime(null);
        setResTime(null);
        setReqTime(new Date().getTime());
        ping().then(ret => {
            if (ret.type) {
                if (ret.type.indexOf('error') > 0) {
                    openSnackbar(`Ping failed: ${ret.payload.error.message}`, 'error');
                } else {
                    setResTime(new Date().getTime());
                    setRepTime(ret.payload.data.pong);
                }
            }
        });
    }

    const totalServerTime = useMemo( () => {
        if (repTime > 0) {
            return repTime - (reqTime/1000);
        }
        return undefined;
    }, [ reqTime, repTime ] );

    const totalLocalTime = useMemo( () => {
        if (resTime > 0) {
            return (resTime - reqTime)/1000;
        }
        return undefined;
    }, [ reqTime, resTime ] );

    return (
        <Card variant='outlined'>
            <CardHeader title='Send single ping' />
            <CardContent>
                pong: {repTime} req: {reqTime} total: {totalServerTime} local: {totalLocalTime}
            </CardContent>
            {error && <CardContent><ErrorAlert error={error} /></CardContent>}
            <CardActions>
                <Button
                    loading={loading}
                    onClick={sendPing}>
                    Ping
                </Button>
            </CardActions>
        </Card>
    );
}

export default SinglePing;
