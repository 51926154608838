import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import * as Yup from 'yup';

import { openSnackbar } from 'app/components/snackbar';

import { OAuth2RefreshTokenOperations } from './../../../state/ducks/oauth2/refreshToken';

import ViewEdit from 'app/components/viewedit';
import ErrorAlert from 'app/components/error';
import LinearProgress from '@material-ui/core/LinearProgress';

import utils from 'app/utils';

const refreshTokenValidation = Yup.object().shape({
    id: Yup.number(),
    client_id: Yup.string()
        .required('Id is required')
        .matches(/^[0-9A-Za-z]+$/, {
            message:'Only numbers and letters allowed',
            excludeEmptyString: true
        }),
    access_token_id: Yup.number().required(),
});

const emptyrefreshToken = {
    id: '',
    access_token_id: '',
    expiry_datetime: '',
    created_at: null,
    created_by: null,
    updated_at: null,
    updated_by: null
};

const OAuth2refreshTokenPage = ( { match: { params: { action, rtid } } } ) => {

    /**
     * Are we editing
     */
    const edit = (action === 'add' || action === 'edit');

    /**
     * Dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Get refreshToken from Api
     */
    const get = useCallback( id => dispatch( OAuth2RefreshTokenOperations.get( 'refreshToken', id ) ) , [ dispatch ]);

    /**
     * Update refreshToken via Api
     */
    const update = useCallback( ( id, data ) => dispatch( OAuth2RefreshTokenOperations.update( 'refreshToken', id, data ) ), [ dispatch ] );
    /**
     * Create new refreshToken via Api
     */
    const create = useCallback( data => dispatch( OAuth2RefreshTokenOperations.create( 'refreshToken', data ) ) , [ dispatch ] );
    /**
     * Clean refreshToken blrf object
     */
    const clear = useCallback( () => dispatch( OAuth2RefreshTokenOperations.clear( 'refreshToken' ) ), [ dispatch ] );
    /**
     * Fetch refreshToken blrf object. If object is not yet available return default blrf object stored in state.
     */
    const refreshTokenObject = useSelector( state => state.app.blrf.objects.refreshToken || state.app.blrf.defaultObject );

    /**
     * Get refreshToken from blrf object data
     */
    const refreshToken = useMemo( () => {
        return ( action === 'add' ? emptyrefreshToken : refreshTokenObject.data.find(v => v) || emptyrefreshToken)
    }, [ refreshTokenObject.data, action ]);

    /**
     * Get loading and error from blrf refreshToken object
     */
    const { loading, error } = refreshTokenObject;

    /**
     * Call get refreshToken from Api if aid changed
     *
     * And register unmount function that will clear the Refresh Token object.
     * This is important because, when we later arrive to this page and
     * aid has changed child components will still see the old Refresh Token and
     * we will get double queries. One for old and one for new and data
     * will most likely be incorrect.
     */
    useEffect( () => {
        if (rtid && rtid.length > 0) {
            get( rtid );
        }
        return clear;
    }, [ rtid, get, clear ]);

    const fields = [
        {
            label: 'Id',
            name: 'id',
            disabled: true,
            value: `${refreshToken.id.substring(0, 8)}...${refreshToken.id.slice(-8)}`,
            width: 4
        },
        {
            label: 'Access Token',
            name: 'access-token_id',
            value: `${refreshToken.id.substring(0, 8)}...${refreshToken.id.slice(-8)}`,
            width: 3
        },
        {
            label: 'Expiry datetime',
            name: 'expiry_datetime',
            value: refreshToken.expiry_datetime,
            searchable: false,
            width: 3
        },
        {
            label: 'Created by',
            name: 'created_by',
            value: refreshToken.created_by ,
            width: 2,
            edit: false
        },
        {
            label: 'Created at',
            name: 'created_at',
            value: refreshToken.created_at ,
            width: 2,
            edit: false
        },
    ];

    const onSubmit = ( values ) => {
        let p = null;
        if (action === 'add') {
            p = create(values);
        } else {
            p = update( values.id, values );
        }
        p.then(ret => {
            if (ret.type.indexOf('error') > 0) {
                openSnackbar('Error while saving Refresh Token', 'error');
            } else {
                openSnackbar('Refresh Token saved', 'success');
                window.history.go( -1 );
            }
        } );
        return p;
    }

    return (
        <Fragment>
            {error === false && loading && <LinearProgress />}
            {error !== false && <ErrorAlert error={error} />}
            {refreshToken &&
            <ViewEdit
                header={{ title: (refreshToken.id.length > 0 ? 'Edit refreshToken' : 'Add refreshToken') }}
                onSubmit={onSubmit}
                fields={fields}
                validationSchema={refreshTokenValidation}
                loading={loading}
                edit={edit}
                editButton={{ component: Link, to: utils.getFullPath(`admin/oauth2/refreshToken/edit/${refreshToken.id}`) }}
            />
            }
        </Fragment>
    );
}

OAuth2refreshTokenPage.propTypes = {
    match: PropTypes.object.isRequired
};

export default OAuth2refreshTokenPage;