import { createStore, applyMiddleware, combineReducers, compose } from 'redux';

import { connectRouter, routerMiddleware } from 'connected-react-router'

import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import { createBrowserHistory as createHistory } from 'history'

import * as reducers from './ducks';
import { appMiddleware, blrfApi, blrfWsApi, dynMiddleware } from './middleware';

export const history = createHistory();

const routerReducer = connectRouter(history);

function createReducer(moduleReducers = {}){
    return combineReducers(
        {
            router: routerReducer, 
            app: combineReducers(reducers),
            ...moduleReducers
        }
    );
}

/**
 * Module will call this method, to inject it's reducer into our 
 * store root reducer dynamicaly.
 */
export const injectModuleReducer = (store, name, reducer) => {
    store.moduleReducers[name] = reducer;
    store.replaceReducer(createReducer(store.moduleReducers));
};

const initialState = {}

const enhancers = [];

const middleware = [
    thunk,
    routerMiddleware(history),
    appMiddleware,
    blrfApi,
    blrfWsApi,
    dynMiddleware
];

if (process.env.NODE_ENV === 'development') {

    middleware.push(createLogger({
        duration: true, // display duration of each action
        diff: true,     // display diff of state
    }));

    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension())
    }
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

const store = createStore(
    createReducer(),
    initialState,
    composedEnhancers
);

/**
 * This is where we store modules' reducers.
 */
store.moduleReducers = {};

export default store;
