import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import * as Yup from 'yup';

import { openSnackbar } from 'app/components/snackbar';

import { realmOperations } from './../../state/ducks/realm';

import ViewEdit from 'app/components/viewedit';

import ErrorAlert from 'app/components/error';
import LinearProgress from '@material-ui/core/LinearProgress';

import utils from 'app/utils';

const realmValidation = Yup.object().shape({
    type: Yup.string()
            .nullable()
            .required(),
    name: Yup.string()
            .min(2, 'Name too short')
            .max(50, 'Name too long')
            .required('Name is required'),
    settings: Yup.string()
});

const emptyRealm = {
    id: '',
    type: null,
    name: '',
    created_at: null,
    created_by: null,
    updated_at: null,
    updated_by: null
};

/**
 * Extract action and rid from react-router match prop
 */
const RealmPage = ( { match: { params: { action, rid } } } ) => {

    /**
     * Are we editing
     */
    const edit = (action === 'add' || action === 'edit');

    /**
     * Dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Get realm from Api
     */
    const get = useCallback( id => dispatch( realmOperations.get( 'realm', id ) ) , [ dispatch ]);
    /**
     * Update realm via Api
     */
    const update = useCallback( ( id, data ) => dispatch( realmOperations.update( 'realm', id, data ) ), [ dispatch ] );
    /**
     * Create new realm via Api
     */
    const create = useCallback( data => dispatch( realmOperations.create( 'realm', data ) ) , [ dispatch ] );
    /**
     * Clean realm blrf object
     */
    const clear = useCallback( () => dispatch( realmOperations.clear( 'realm' ) ), [ dispatch ] );
    /**
     * Fetch realm blrf object. If object is not yet available return default blrf object stored in state.
     */
    const realmObject = useSelector( state => state.app.blrf.objects.realm || state.app.blrf.defaultObject );

    /**
     * Get realm from blrf object data
     */
    const realm = useMemo( () => {
        return ( action === 'add' ? emptyRealm : realmObject.data.find(v => v) || emptyRealm)
    }, [ realmObject.data, action ]);

    /**
     * Get loading and error from blrf realm object
     */
    const { loading, error } = realmObject;

    /**
     * Get realm types via Api
     */
    const getRealmTypes = useCallback( () => dispatch( realmOperations.getTypes( 'realm_types' ) ), [ dispatch ] );

    /**
     * Fetch realm types from state
     */
    const realmTypes = useSelector( state => (state.app.blrf.objects.realm_types && state.app.blrf.objects.realm_types.data) || [] );
    /**
     * Call get realm from Api if rid changed
     *
     * And register unmount function that will clear the realm object.
     * This is important because, when we later arrive to this page and
     * rid has changed child components will still see the old realm and
     * we will get double queries. One for old and one for new and data
     * will most likely be incorrect.
     */
    useEffect( () => {
        if (rid > 0) {
            get( rid );
        }
        return clear;
    }, [ rid, get, clear ]);

    useEffect( () => {
        getRealmTypes();
    }, [ rid, getRealmTypes ] );

    const fields = [
            {
                label: 'Id',
                name: 'id',
                disabled: true,
                value: realm.id,
                width: 1
            },
            {
                label: 'Type',
                name: 'type',
                width: 3,
                value: realm.type,
                placeholder: 'Search types ...',
                select: {
                    defaultOptions: realmTypes.map( type => ({ id: type, key: type, value: type, label: type }) ),
                },
            },
            {
                label: 'Name',
                name: 'name',
                width: 8,
                value: realm.name,
            },
            {
                label: 'Settings',
                name: 'settings',
                value: realm.settings ? realm.settings : '',
                multiline: true,
                rows: 8,
                width: 12,
                help: 'JSON Object as string'
            },
            {
                label: 'Created at',
                name: 'created_at',
                value: realm.created_at,
                width: 3,
                edit: false
            },
            {
                label: 'Created by',
                name: 'created_by',
                value: realm.created_by,
                width: 3,
                edit: false,
            },
            {
                label: 'Updated at',
                name: 'updated_at',
                value: realm.updated_at,
                width: 3,
                edit: false,
            },
            {
                label: 'Updated by',
                name: 'updated_by',
                value: realm.updated_by,
                width: 3,
                edit: false
            }
        ];

    const onSubmit = ( values ) => {
        let p = null;
        if (action === 'add') {
            p = create(values);
        } else {
            p = update( values.id, values );
        }
        p.then(ret => {
            if (ret.type.indexOf('error') > 0) {
                openSnackbar('Error while saving realm', 'error');
            } else {
                openSnackbar('Realm saved', 'success');
                window.history.go( -1 );
            }
        } );
        return p;
    }

    return (
        <Fragment>
            {error === false && loading && <LinearProgress />}
            {error !== false && <ErrorAlert error={error} />}
            {realm &&
                <ViewEdit
                    header={{ title: (realm.id > 0 ? 'Edit realm' : 'Add realm') }}
                    onSubmit={onSubmit}
                    fields={fields}
                    validationSchema={realmValidation}
                    loading={loading}
                    edit={edit}
                    editButton={{ component: Link, to: utils.getFullPath(`admin/realms/edit/${realm.id}`) }}
                    />
            }
        </Fragment>
    );
}

RealmPage.propTypes = {
    match: PropTypes.object.isRequired
};

export default RealmPage;
