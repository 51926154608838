import React from 'react'

const AdminPage = () => {

    return (
        <h1>Admin</h1>
    );
};

export default AdminPage;
