import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import * as Yup from 'yup';

import { openSnackbar } from 'app/components/snackbar';

import { OAuth2ScopeOperations } from './../../../state/ducks/oauth2/scope';

import ViewEdit from 'app/components/viewedit';
import ErrorAlert from 'app/components/error';
import LinearProgress from '@material-ui/core/LinearProgress';

import utils from 'app/utils';

const scopeValidation = Yup.object().shape({
    id:   Yup.string()
             .min(2, 'Id too short')
             .max(50, 'Id too long')
             .required('Id is required')
             .matches(/^[0-9A-Za-z_]+$/, {
                            message:'Only numbers, letters and underscore allowed',
                            excludeEmptyString: true
                        }),
    name: Yup.string()
             .min(2, 'Name too short')
             .max(50, 'Name too long')
             .required('Name is required')
});

const emptyScope = {
    id: '',
    name: ''
};

/**
 * Extract action and sid from react-router match prop
 */
const OAuth2ScopePage = ( { match: { params: { action, sid } } } ) => {

    /**
     * Are we editing
     */
    const edit = (action === 'add' || action === 'edit');

    /**
     * Dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Get scope from Api
     */
    const get = useCallback( id => dispatch( OAuth2ScopeOperations.get( 'scope', id ) ) , [ dispatch ]);
    /**
     * Update scope via Api
     */
    const update = useCallback( ( id, data ) => dispatch( OAuth2ScopeOperations.update( 'scope', id, data ) ), [ dispatch ] );
    /**
     * Create new scope via Api
     */
    const create = useCallback( data => dispatch( OAuth2ScopeOperations.create( 'scope', data ) ) , [ dispatch ] );
    /**
     * Clean scope blrf object
     */
    const clear = useCallback( () => dispatch( OAuth2ScopeOperations.clear( 'scope' ) ), [ dispatch ] );
    /**
     * Fetch scope blrf object. If object is not yet available return default blrf object stored in state.
     */
    const scopeObject = useSelector( state => state.app.blrf.objects.scope || state.app.blrf.defaultObject );

    /**
     * Get scope from blrf object data
     */
    const scope = useMemo( () => {
        return ( action === 'add' ? emptyScope : scopeObject.data.find(v => v) || emptyScope)
    }, [ scopeObject.data, action ]);

    /**
     * Get loading and error from blrf scope object
     */
    const { loading, error } = scopeObject;

    /**
     * Call get scope from Api if sid changed
     *
     * And register unmount function that will clear the scope object.
     * This is important because, when we later arrive to this page and
     * sid has changed child components will still see the old scope and
     * we will get double queries. One for old and one for new and data
     * will most likely be incorrect.
     */
    useEffect( () => {
        if (sid && sid.length > 0) {
            get( sid );
        }
        return clear;
    }, [ sid, get, clear ]);

    const fields = [
            {
                label: 'Id',
                name: 'id',
                value: scope.id,
                width: 6
            },
            {
                label: 'Name',
                name: 'name',
                width: 6,
                value: scope.name
            },
            {
                label: 'Created at',
                name: 'created_at',
                value: scope.created_at,
                width: 3,
                edit: false
            },
            {
                label: 'Created by',
                name: 'created_by',
                value: scope.created_by,
                width: 3,
                edit: false,
            },
            {
                label: 'Updated at',
                name: 'updated_at',
                value: scope.updated_at,
                width: 3,
                edit: false,
            },
            {
                label: 'Updated by',
                name: 'updated_by',
                value: scope.updated_by,
                width: 3,
                edit: false
            },
        ];

    const onSubmit = ( values ) => {
        let p = null;
        if (action === 'add') {
            p = create(values);
        } else {
            p = update( values.id, values );
        }
        p.then(ret => {
            if (ret.type.indexOf('error') > 0) {
                openSnackbar('Error while saving scope', 'error');
            } else {
                openSnackbar('Scope saved', 'success');
                window.history.go( -1 );
            }
        } );
        return p;
    }

    return (
        <Fragment>
            {error === false && loading && <LinearProgress />}
            {error !== false && <ErrorAlert error={error} />}
            {scope &&
                <ViewEdit
                    header={{ title: (scope.id.length > 0 ? 'Edit scope' : 'Add scope') }}
                    onSubmit={onSubmit}
                    fields={fields}
                    validationSchema={scopeValidation}
                    loading={loading}
                    edit={edit}
                    editButton={{ component: Link, to: utils.getFullPath(`admin/oauth2/scope/edit/${scope.id}`) }}
                    />
            }
        </Fragment>
    );
}

OAuth2ScopePage.propTypes = {
    match: PropTypes.object.isRequired
};

export default OAuth2ScopePage;





























/*
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { openSnackbar } from 'app/components/snackbar';
import { oauth2ScopeOperations } from './../../../state/ducks/oauth2/scope';
import * as Yup from 'yup';

import ViewEdit from 'app/components/viewedit';
import utils from 'app/utils';

const userValidation = Yup.object().shape({
    id: Yup.string()
            .min(2, 'Id too short')
            .max(50, 'Id too long')
            .required('Id is required')
            .matches(/^[0-9A-Za-z_]+$/, {
                            message:'Only numbers, letters and underscor allowed',
                            excludeEmptyString: true
                        }),
    name: Yup.string()
            .min(2, 'Name too short')
            .max(50, 'Name too long')
            .required('Name is required')
});

class OAuth2ScopePage extends Component {


    static defaultProps = {
        sid: null,
        scope: null
    };

    async componentWillMount() {
        const { sid, get } = this.props;
        if (sid !== null && sid.length > 0) {
            await get( sid );
        }
    }

    getFields() {
        const { scope } = this.props;

        return [
            {
                label: 'Id',
                name: 'id',
                value: scope.id,
                width: 6
            },
            {
                label: 'Name',
                name: 'name',
                width: 6,
                value: scope.name
            },
            {
                label: 'Created at',
                name: 'created_at',
                value: scope.created_at,
                width: 3,
                edit: false
            },
            {
                label: 'Created by',
                name: 'created_by',
                value: scope.created_by,
                width: 3,
                edit: false,
            },
            {
                label: 'Updated at',
                name: 'updated_at',
                value: scope.updated_at,
                width: 3,
                edit: false,
            },
            {
                label: 'Updated by',
                name: 'updated_by',
                value: scope.updated_by,
                width: 3,
                edit: false
            },
        ];
    }

    onSubmit = async ( values ) => {
        const { update, create, action } = this.props;
        let ret = null;
        if (action === 'add') {
            ret = await create(values);
        } else {
            ret = await update( values.id, values );
        }
        if (ret.type.indexOf('error') > 0) {
            openSnackbar('Error while saving scope', 'error');
        } else {
            openSnackbar('scope saved', 'success');
            window.history.go( -1 );
        }
        return ret;
    }

    render() {
        const { scope, loading, edit } = this.props;
        return (
            scope && 
                <ViewEdit
                    header={{ title: (scope.id > 0 ? (edit ? 'Edit scope' : 'View scope') : 'Add scope') }}
                    onSubmit={this.onSubmit}
                    fields={this.getFields()}
                    validationSchema={userValidation}
                    loading={loading}
                    edit={edit}
                    editButton={{ component: Link, to: utils.getFullPath(`admin/oauth2/scope/edit/${scope.id}`) }}
                    />
        );
    }
}

const mapStateToProps = ( state, ownProps ) => {
    const { loading = false, data = [] } = state.app.blrf.objects.scope || {};
    const { action = 'view', sid = null } = ownProps.match.params;
    const empty_scope = {
        id: null,
        name: '',
        routing_key: '',
        enabled: true,
        command: '',
        max_workers: 1,
        max_workers_per_host: 1,
        last_check_at: null,
        last_check_by: null,
        created_at: null,
        created_by: null,
        updated_at: null,
        updated_by: null
    };
    return {
        loading,
        action,
        edit: action === 'edit' || action === 'add',
        sid: sid,
        scope: (action === 'add' ? empty_scope : data.find( v => v )),
    }
};

const mapDispatchToProps = {
    get: ( id ) => oauth2ScopeOperations.get( 'scope', id ),
    update: ( id, data ) => oauth2ScopeOperations.update( 'scope', id, data ),
    create: ( data ) => oauth2ScopeOperations.create( 'scope', data ),
};

export default connect(mapStateToProps, mapDispatchToProps)(OAuth2ScopePage);
*/