import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import App from './app';

const AppWrapper = () => {

    const initTheme = useSelector( state => state.app.theme );

    const theme = useMemo( () => createMuiTheme(initTheme), [ initTheme ] );

    return (
        <MuiThemeProvider theme={ theme }>
            <CssBaseline />
            <App />
        </MuiThemeProvider>
    );
};

export default AppWrapper;
