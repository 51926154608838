import React from 'react';

const AuthState = () => {

    return (
        <h1>State card</h1>
    );

};

export default AuthState;
