import React, { PureComponent,  Fragment } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import AppsIcon from '@material-ui/icons/Apps';
import MenuList from '@material-ui/core/MenuList';
import Menu from '@material-ui/core/Menu';
import ListSubheader from '@material-ui/core/ListSubheader';

import AppNavbarModule from './module';

export default class AppNavbarModules extends PureComponent {

    static propTypes = {
        modules: PropTypes.array,
        user: PropTypes.object
    };

    static defaultProps = {
        modules: null,
        user: null
    };

    state = {
        anchorEl: null
    };

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { modules, user } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        return (
            <Fragment>
                <IconButton
                    aria-owns={open ? 'menu-appbar' : null}
                    aria-haspopup="true"
                    color="inherit"
                    area-label="choose module"
                    onClick={this.handleMenu}
                >
                    <AppsIcon />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={this.handleClose}
                >
                    <MenuList subheader={<ListSubheader>Modules</ListSubheader>} >
                        { modules ? modules.map((module, key) => (
                            <AppNavbarModule key={key} module={module} user={user} close={this.handleClose} />
                        )) : null }
                    </MenuList>
                </Menu>
            </Fragment>
        );
    }
}
