import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Hidden from '@material-ui/core/Hidden';

import { AppSidebarModule } from './sidebar/index';

export default class AppSidebar extends PureComponent {

    static propTypes = {
        module: PropTypes.object,
        user: PropTypes.object,
        currentUri: PropTypes.string.isRequired,
        logout: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
        theme: PropTypes.object.isRequired,
        mobileOpen: PropTypes.bool.isRequired
    };

    static defaultProps = {
        module: null,
        user: null
    };

    render() {
        const { classes, theme, mobileOpen, handleDrawerToggle, module, user, currentUri } = this.props;
        return (
            <Fragment>
                <Hidden mdUp>
                    <SwipeableDrawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        onOpen={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <AppSidebarModule module={module} user={user} classes={classes} currentUri={currentUri} />
                    </SwipeableDrawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        variant="permanent"
                        open
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <AppSidebarModule module={module} user={user} classes={classes} currentUri={currentUri} />
                    </Drawer>
                </Hidden>
            </Fragment>
        );
    }
}
