import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as operations from './operations';

import { connectedSelectField } from 'app/components/form/field/select';

function usersSelectImplementation ( WrappedComponent ) {
    return class extends Component {

        onSearchEvent = ( searchQuery ) => {
            const { search } = this.props;
            const query = (searchQuery ? { name: `${searchQuery}*` } : {});
            return search({
                refs: ['Realm'],
                query
            }, false);
        };

        map = ( user ) => ({ key: user.id, value: user.id, label: `${user.name}${user.Realm ? '@' + user.Realm.name : ''}` });

        render( ) {
            const { select, ...rest } = this.props;
            const default_select = {
                map: this.map,
                onSearchEvent: this.onSearchEvent,
                ...select
            };

            return <WrappedComponent
                        select={default_select}
                        placeholder='Search users by name ...'
                        {...rest}
                    />;
        }
    };
}

const mapDispatchToProps =  ( dispatch, ownProps ) => {
    const { blrfKey = 'users_select' } = ownProps;
    return bindActionCreators(
        {
            search: (query) => operations.search(blrfKey, query),
        }, dispatch);
};

const mapStateToProps = ( state, ownProps ) => {
    const { blrfKey = 'users_select' } = ownProps;
    const { loading = false, error = false, query = { refs: 0, page: 1 }, data = []} = state.app.blrf.objects[blrfKey] || {};
    return {
        loading, 
        query, 
        data,
        error: (error !== false ? error && error.message : false)
    }
};

const usersSelectField = connect(mapStateToProps, mapDispatchToProps)(usersSelectImplementation(connectedSelectField()));

export {
    usersSelectField
};
