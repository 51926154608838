import React, { useMemo } from 'react'

import invariant from 'invariant';
import DotObject from 'dot-object';

import ViewEditForm from './viewedit/form';

const ViewEdit = (
    {
        edit = false,
        onSubmit = undefined,
        ...props 
    }
) => {

    invariant(!(edit === true && onSubmit === undefined), 'ViewEdit: When edit is true, onSubmit must be provided');

    const initialValues = useMemo( () => {
        const ret = {};
        if (!props.initialValues) {
            const dot = new DotObject('.', false, true);
            props.fields.map( (field, key) => {
                if (field.edit !== false) {
                    if (field.name && field.value !== undefined) {
                        dot.str(field.name, field.value, ret);
                    }
                }
                return true;
            });
        } else {
            return props.initialValues;
        }
        return ret;
    }, [ props.fields, props.initialValues ]);

    return (
        <ViewEditForm 
            initialValues={initialValues}
            edit={edit}
            onSubmit={onSubmit ? onSubmit : () => invariant(false, 'onSubmit not defined') }
            {...props}  
        />
    );
};

export default ViewEdit;
