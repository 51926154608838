import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import AppSidebar from './sidebar';
import AppNavbar from './navbar';
import AppRoutes from './routes';

import { moduleOperations } from './../../state/ducks/module';
import { authOperations } from './../../state/ducks/auth';

import { withStyles } from '@material-ui/styles';

import { default as ConsecutiveSnackbars } from './../../components/snackbar';

import './style.css';

const headerHeight =  64;
const drawerWidth = 200;

const styles = theme => ({

    appBar: {
        [theme.breakpoints.up('md')]: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px) !important`,
        },
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },

    navIconHide: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },

    drawerPaper: {
        width: drawerWidth,
        backgroundColor: (theme.palette.type === 'light' ? 'rgb(64, 64, 64)' : 'rgb(32, 32, 32)'),
        color: 'white',
        border: 'none',
        [theme.breakpoints.up('md')]: {

        },
    },

    content: {
        minHeight: `calc(100vh - ${headerHeight}px)`,
        height: '1px',  /* seems to fix heigh issues of child elements */
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(1)
        }
    },

});

class App extends Component {

    static propTypes = {
        /**
         * Was first render already received
         */
        wasFirstRender: PropTypes.bool.isRequired,
        /**
         * Is application initializing
         */
        initializing: PropTypes.bool.isRequired,
        /**
         * Number of async actions awaiting completition
         * @see ducks/loading
         */
        loading: PropTypes.number,
        /**
         * Array of module objects
         * @see ducks/module
         */
        modules: PropTypes.array,
        /**
         * Currently active module
         * @see ducks/module
         */
        module: PropTypes.object,
        /**
         * Current user if authenticated
         */
        user: PropTypes.object,
        /**
         * Load modules operation
         */
        loadModules: PropTypes.func.isRequired,
        /**
         * Initialize authentication
         */
        initAuth: PropTypes.func.isRequired,
        /**
         * Current URI
         */
        currentUri: PropTypes.string.isRequired
    };

    static defaultProps = {
        loading: 0,
        modules: null,
        module: null,
        user: null,
    };

    state = {
        mobileOpen: false,
    };


    /**
     * We can use await now, to wait for all initialization to be done.
     *
     * Get the current window size.
     * First, check authentication and if we need to do anything
     * Then load modules
     */
    async componentDidMount() {
        const { initAuth, loadModules } = this.props;
        await initAuth();
        await loadModules();

    }

    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };

    render() {
        const { 
            loading, initializing, authenticating, wasFirstRender, currentUri, wsConnections,
            module, modules, user, logout, classes, theme } = this.props;
        let _currentUri = currentUri;

        if (currentUri !== null && _currentUri.length > 1 && _currentUri.charAt(_currentUri.length-1) === '/') {
            _currentUri = currentUri.slice(0,-1);
        }

        return (
            <Fragment>
                <AppNavbar
                    handleDrawerToggle={this.handleDrawerToggle}
                    classes={classes}
                    modules={modules}
                    module={module}
                    wsConnections={wsConnections}
                    loading={loading}
                    user={user}
                    authenticating={authenticating}
                    logout={logout} />
                <AppSidebar
                    handleDrawerToggle={this.handleDrawerToggle}
                    mobileOpen={this.state.mobileOpen}
                    classes={classes}
                    theme={theme}
                    module={module}
                    currentUri={_currentUri || '/'}
                    user={user}
                    logout={logout} />
                <div className={classes.content}>
                    {!initializing && <AppRoutes wasFirstRender={wasFirstRender} authenticating={authenticating} module={module} user={user} currentUri={_currentUri} />}
                </div>
                <ConsecutiveSnackbars />
            </Fragment>
        );
    }
}

const mapStateToProps = ( state ) => ( {
    /**
     * Was first render is used by appRoutes to decide when to render the redirect routes if any are needed.
     * And also, if not found page should be displayed or are we still loading.
     * wasFirstRender will be updated by auth reducer when connected-react-router will issue the first 
     * location change action.
     */
    wasFirstRender: state.app.auth.wasFirstRender,
    initializing: state.app.module.initializing || state.app.auth.initializing,
    authenticating: state.app.auth.authenticating,
    loading: state.app.loading, 
    modules: state.app.module.all,
    module: state.app.module.current,
    user: state.app.auth.user,
    wsConnections: state.app.blrf_ws.connections,
    currentUri: state.router.location ? state.router.location.pathname : null,
} );

const mapDispatchToProps = {
    loadModules: moduleOperations.load,
    initAuth: authOperations.init,
    logout: authOperations.logout
};

export default connect( mapStateToProps, mapDispatchToProps ) ( withStyles(styles, { withTheme: true }) ( App ) );
