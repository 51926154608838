import React from 'react';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

/**
 * This is Material UI Form.Field wrapped into formik field
 *
 * Formik will add two props:
 *  - field
 *  - form
 */
const TextField = ({
    /**
     * Props for Material-UI
     */
    fullWidth = true,
    margin = 'normal',
    autoFocus = false,
    variant = 'standard',

    /**
     * Our fields
     */
    id = undefined,
    label = undefined,
    help = undefined,
    disabled = false,
    loading = false,
    edit = true,

    /**
     * Formik form
     */
    form, 
    /**
     * Formik field
     */
    field,
    /**
     * Other props are passed to Input component
     */
    ...props
}) => {

    const touched = form.touched[field.name]
    const invalid = form.errors[field.name] ? true : false;
    const error = form.errors[field.name];

    return (
        <FormControl disabled={form.isSubmitting || loading || disabled} variant={variant} error={touched && invalid} fullWidth={fullWidth} margin={margin}>
            { id && label && 
                <InputLabel htmlFor={id}>{label}</InputLabel>
            }
            <Input id={id} autoFocus={autoFocus} {...props} {...field} />
            { touched && invalid && <FormHelperText id={`${id}-error`}>{error}</FormHelperText>}
            { help && <FormHelperText>{help}</FormHelperText>}
        </FormControl>
    );

};

export default TextField;
