import 'isomorphic-fetch';

export default ( url, method, headers, body, timeout = 20000 ) => {
    let isFormData = false;
    if (body instanceof FormData) {
        isFormData = true;
        /**
         * If we have form data, we need to remove Content-Type
         * @see https://muffinman.io/uploading-files-using-fetch-multipart-form-data/
         */
        delete headers['Content-Type'];
    }
    const options = {
        method,
        timeout, 
        headers: headers,
        body: (isFormData ? body : ( body !== null ? JSON.stringify( body ) : null) ),
    };

    return fetch( url, options )
        .then(
            res => { 
                const contentType = res.headers.get('content-type');
                let _res = null;
                if (contentType && contentType.indexOf('application/json') >= 0) {
                    _res = res.json();
                } else {
                    _res = res.text();
                }
                return parseStatus( res.status, _res ) 
            }
        ).catch( error => {
            /**
             * error maybe a normal object (returned from RESTful API) or Error instance
             * which is received by fetch upon network errors.
             */
            console.error('error: ', error);
            if (error instanceof Error) {
                throw new Error(`Network error: ${error && error.message ? error.message : error}`);
            } else {
                throw error;
            }
        });
};

function parseStatus( status, res ) {
    return new Promise( ( resolve, reject ) => {
        if ( status >= 200 && status < 300 ) {
            /**
             * Response code 204 means no response, so we return empty object.
             */
            res.then( response => resolve( (status === 204 ? {} : response ) ) );
        } else {
            res.then( response => reject( { status, response } ) );
        }
    } );
}
