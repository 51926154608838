import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import { NavLink } from 'react-router-dom';

const styles = theme => ({

    active: {
        backgroundColor: theme.palette.action.selected,
    }

});

/**
 * Need to wrap NavLink into component for refs to work
 */
class AppSidebarLink extends Component {

    render() {
        const {
            activeClassName = this.props.classes.active,
            ...props
        } = this.props;
        return (<NavLink activeClassName={activeClassName} {...props} />);
    }

}

export default withStyles(styles)(AppSidebarLink);
