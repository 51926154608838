import {pingOperations} from '../../../../state/ducks/ping'
import store from 'app/state/store';
import fetchMock from 'fetch-mock'

describe('Example test', () => {
    afterEach(() => {
        fetchMock.restore();
    });

    it('Ping payload should be equal as response data', async () => {

        const responseData = {
            id: 1,
            ping: 'Pong',
            pong: 'Ping'
        }

        const id = 1
        const key = 'example'
        const endpoint = `example/ping/${id}`

        fetchMock
            .get(`end:${endpoint}`, [responseData])

        const res = await store.dispatch(pingOperations.pingId(key, id));

        expect(res.payload[0]).toEqual(responseData)
    })
})