import React, { Fragment, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { transactionOperations } from './../../../../state/ducks/transaction';

import * as transactionConst from './../../../../const/transaction';

import { Table } from 'app/components';

import { Link } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import DescriptionIcon from '@material-ui/icons/Description';
import ReceiptIcon from '@material-ui/icons/Receipt';

import utils from 'app/utils';
import coinUtils from '../../../../utils';

const OrderTransactionsPage = ( { order } ) => {


    /**
     * Take dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Search for transactions via Api
     */
    const search = useCallback( query => dispatch( transactionOperations.search( 'transactions', query ) ), [ dispatch ] );

    /**
     * Remove transaction via Api
     */
    const remove = useCallback( id => dispatch( transactionOperations.remove( 'transaction', id ) ), [ dispatch ] );

    const clear = useCallback( () => dispatch( transactionOperations.clear( 'transactions' ) ), [ dispatch ] );

    /**
     * transactions blrf object
     */
    const transactionsObject = useSelector( state => state.app.blrf.objects.transactions || state.app.blrf.defaultObject );

    const { data, meta } = useMemo( () => {
        return {
            data: transactionsObject.data,
            meta: transactionsObject.meta
        }
    }, [ transactionsObject ]);

    const query = useMemo( () => {
        /**
         * When object is first created query will only contain { refs: 0, page: 1 }
         *
         * We want refs, so we change that. And we want to have default transaction.
         * All other aspects of query object is controlled via
         * Table component.
         */
         const hasOrder = Array.isArray(transactionsObject.query.order) && transactionsObject.query.order.length > 0;
         const { order_id = undefined } = transactionsObject.query.query || {};
         if (transactionsObject.query.refs === 0 || !hasOrder || order_id !== order.id) {
            return {
                ...transactionsObject.query,
                query: {
                    order_id: order.id
                },
                refs: ['User','Exchange','Currency'],
                order: (hasOrder ? [...transactionsObject.query.order ] : ['created_at DESC', 'id DESC'] )
            };
         }
         return transactionsObject.query;
    }, [ transactionsObject.query, order.id ] );

    console.log(' >> query: ', query);

    const {
        loading,
        error
    } = transactionsObject;

    const fields = [
        {
            label: 'Id',
            name: 'id',
            width: 1,
            type: 'number'
        },
        {
            label: 'Datetime',
            name: 'created_at',
            width: 1,
            searchable: false
        },
        {
            label: 'Status',
            name: 'status',
            width: 1,
            select: {
                defaultOptions: transactionConst.transactionStatusOptions
            }
        },
        {
            label: 'User',
            name: 'user_id',
            width: 1
        },
        {
            label: 'Exchange',
            name: 'exchange_id',
            width: 1
        },
        {
            label: 'Currency',
            name: 'currency_id',
            width: 1
        },
        {
            label: 'Amount',
            name: 'amount',
            width: 1
        },
        {
            label: 'Balance',
            name: 'balance',
            width: 1
        }
    ];

    const renderRow = ({ id, created_at, status, User, Exchange, Currency, amount, balance }, i) => ({
        key: id,
        cells: [
            id,
            created_at,
            status ? transactionConst.transactionStatus[status].name : status,
            User.name,
            Exchange.name,
            Currency.name,
            coinUtils.formatCurrency(Currency, amount),
            coinUtils.formatCurrency(Currency, balance)
        ]
    });

    const actions = [
        {
            icon: <DescriptionIcon />,
            component: Link,
            to: utils.getFullPath('coin/transactions/view/:id')
        }
    ];

    useEffect( () => {
        if (order.id > 0) {
            search(query);
            return clear;
        }
    }, [ order, search, query, clear ]);

    return (
        <Card variant='outlined'>
            <CardHeader title='Transactions' avatar={<ReceiptIcon />} />
            <CardContent>
                <Table
                    fields={fields}
                    renderRow={renderRow}
                    actions={actions}
                    loading={loading}
                    query={query}
                    data={data}
                    meta={meta}
                    error={error}
                    />
            </CardContent>
        </Card>
    );
};

OrderTransactionsPage.propTypes = {
    order: PropTypes.object.isRequired
};

export default OrderTransactionsPage;
