import React, { Fragment, useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { OAuth2ClientOperations } from './../../../state/ducks/oauth2/client';

import { Table } from 'app/components';

import { Link } from 'react-router-dom';

import Fab from '@material-ui/core/Fab';

import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';

import { openSnackbar } from 'app/components/snackbar';

import utils from 'app/utils';

const OAuth2ClientsPage = () => {

    /**
     * Take dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Search for clients via Api
     */
    const search = useCallback( query => dispatch( OAuth2ClientOperations.search( 'clients', query ) ), [ dispatch ] );

    /**
     * Remove client via Api
     */
    const remove = useCallback( id => dispatch( OAuth2ClientOperations.remove( 'client', id ) ), [ dispatch ] );

    /**
     * OAuth2Clients blrf object
     */
    const clientsObject = useSelector( state => state.app.blrf.objects.clients || state.app.blrf.defaultObject );

    const { data, meta } = useMemo( () => {
        return {
            data: clientsObject.data,
            meta: clientsObject.meta
        }
    }, [ clientsObject ]);

    const query = useMemo( () => {
        /**
         * When object is first created query will only contain { refs: 0, page: 1 }
         *
         * We want refs, so we change that. And we want to have default order.
         * All other aspects of query object is controlled via
         * Table component.
         */
         const hasOrder = Array.isArray(clientsObject.query.order) && clientsObject.query.order.length > 0;
         if (clientsObject.query.refs !== 0 || !hasOrder) {
            return { ...clientsObject.query, refs: 0, order: (hasOrder ? [...clientsObject.query.order ] : ['name ASC'] ) };
         }
         return clientsObject.query;
    }, [ clientsObject.query ] );

    const {
        loading,
        error
    } = clientsObject;

    const fields = [
        {
            label: 'Id',
            name: 'id',
            width: 2,
        },
        {
            label: 'Name',
            name: 'name',
            width: 4,
        },
        {
            label: 'Redirect Uri',
            name: 'redirect_uri',
            width: 4,
        }
    ];


    const renderRow = ({ id, name, redirect_uri}, i) => ({
        key: id,
        cells: [
            id,
            name,
            redirect_uri
        ]
    });

    const actions = [
        {
            icon: <DescriptionIcon />,
            component: Link,
            to: utils.getFullPath('admin/oauth2/client/view/:id')
        },
        {
            icon: <CreateIcon />,
            component: Link,
            to: utils.getFullPath('admin/oauth2/client/edit/:id')
        },
        {
            icon: <DeleteIcon />,
            onClick: ( data ) => removeClient(data.id),
            confirm: {
                content: 'Are you sure you want to delete client :name ?'
            }
        }
    ];

    const removeClient = async( id ) => {
        const ret = await remove( id );
        if (ret.type.indexOf('error') > 0) {
            openSnackbar('Failed to delete client', 'error');
        } else {
            openSnackbar('Client deleted', 'success');
            search( query );
        }
    }


    return (
        <Fragment>
            <Table
                fields={fields}
                renderRow={renderRow}
                actions={actions}
                loading={loading}
                search={search}
                query={query}
                data={data}
                meta={meta}
                error={error}
                />
            <Fab color='primary' style={{ float: 'right' }} variant='extended' component={Link} to={utils.getFullPath('admin/oauth2/client/add')}>
                <AddIcon />
                Add client
            </Fab>
        </Fragment>
    );
};

export default OAuth2ClientsPage;
