import { default as types } from './types';
import { createReducer } from './../../utils';

/**
 * WebSocket.readyState =
 *
 * 0 => Connecting
 * 1 => Open
 * 2 => Closing
 * 3 => Closed
 */

const connectionObject = {
    /**
     * The connection key 
     */
    key: null,
    /**
     * URL where we connection (endpoint)
     */
    url: null,
    /**
     * The WebSocket connection object
     */
    socket: null,
    /**
     * Connected ?
     */
    connected: false,
    /**
     * Are we waiting for any operation to finish on socket
     */
    loading: true,
    /**
     * When was the connection created
     */
    created: null,
    /**
     * When was the connection updated
     */
    updated: null,
    /**
     * Last event object received
     */
    event: null,
};

const createConnection = ( key, url ) => (
    { ...connectionObject, key, url, created: new Date().getTime() }
);

const updateConnection = ( connection, data ) => {
    const socket = data.socket || connection.socket;
    return { ...connection, ...data, connected: (socket === null ? false : socket.readyState === 1), updated: new Date().getTime() };
}

const updateState = ( state, connection ) => {
    const key = connection.key;

    state.keys.add(key);

    return {
        ...state, 
        connections: { ...state.connections, [ key ]: connection }
    };
};

const actionsMap = {

    [ types.CONNECT ]: ( state, { payload: { api: { key, url } } } ) => (
        updateState( state, createConnection( key, url ) )
    ),

    [ types.RECONNECT ]: ( state, { payload: { api: { key, url } } } ) => (
        updateState( state, updateConnection ( state.connections[key], { loading: true } ) )
    ),

    [ types.CONNECT_OK ] : ( state, { payload: { socket, event, api: { key } } } ) => (
        updateState ( state, updateConnection ( state.connections[key], { socket, event, loading: false } ) )
    ),

    [ types.CONNECT_ERROR ] : ( state, { payload: { event, api: { key } } } ) => (
        updateState ( state, updateConnection ( state.connections[key], { socket: null, event, loading: false } ) )
    ),

    [ types.DISCONNECT ]: ( state, { payload: { api: { key } } } ) => (
        updateState( state, updateConnection ( state.connections[key], { loading: true } ) )
    ),

    [ types.DISCONNECT_OK ]: ( state, { payload: { api: { key } } } ) => (
        updateState( state, updateConnection ( state.connections[key], { socket: null, loading: false } ) )
    ),

    [ types.DISCONNECT_ERROR ]: ( state, { payload: { api: { key } } } ) => (
        updateState( state, updateConnection ( state.connections[key], { socket: null, loading: false } ) )
    ),

    [ types.SEND ]: ( state, { payload: { api: { key, data } } } ) => (
        updateState( state, updateConnection( state.connections[key], { loading: true } ) )
    ),

    [ types.SEND_OK ]: ( state, { payload: { api: { key } } } ) => (
        updateState( state, updateConnection( state.connections[key], { loading: false } ) )
    ),

    [ types.SEND_ERROR ]: ( state, { payload: { api: { key } } } ) => (
        updateState( state, updateConnection( state.connections[key], { loading: false } ) )
    ),

    [ types.MESSAGE ]: ( state, { payload: { event, api: { key } } } ) => (
        updateState( state, updateConnection( state.connections[key], { event } ) )
    ),  

};

const initialState = {

    keys: new Set(),
    connections: {}

};

export default createReducer( initialState ) ( actionsMap );
