import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import ErrorAlert from 'app/components/error';
import LinearProgress from '@material-ui/core/LinearProgress';

import SaveIcon from '@material-ui/icons/Save';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';

import { Fab } from 'app/components/button';

import Accordion from 'app/components/accordion';

import { openSnackbar } from 'app/components/snackbar';

import { roleOperations } from './../../../state/ducks/role';

import RoleAclModule from './module';

import { withAppForm } from 'app/components/form';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        /*
        flexBasis: '33.33%',
        flexShrink: 0,
        */
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

const RoleAclFormImpl = ({ acls, ...props }) => {
    const classes = useStyles();

    const panes = useMemo( () => {
        const ret = [];

        for ( let i = 0; i < acls.length; i++ ) {
            const mod = acls[i];
            ret.push({
                summary: 
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <Typography className={classes.heading}><b>{mod.name}</b></Typography>
                        </Grid>
                        <Grid item xs={10} md={8}>
                            <Typography className={classes.secondaryHeading}>{mod.description}</Typography>
                        </Grid>
                        <Grid item xs={2} md={1}>
                            { mod.allowed ? <DoneIcon color='action' /> : <CancelIcon color='error' /> }
                        </Grid>
                    </Grid>,
                content: <RoleAclModule module={mod} {...props} />
            });
        }

        return ret;
    }, [ acls, classes, props ] );

    return (
        <Card>
            <CardHeader title='Permissions' />
            <CardContent>
                <Accordion
                    panes={panes} 
                />
            </CardContent>
            <CardActions>
                <Grid container>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Fab
                            type='submit'
                            color='primary'
                            variant='extended'
                            loading={props.loading || props.isSubmitting}
                        >
                            <SaveIcon />
                             Save
                        </Fab>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );

};

const RoleAclForm = withAppForm(RoleAclFormImpl);

const RoleAcl = ( { role } ) => {

    /**
     * Dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Get role roleAcl from Api
     */
    const get = useCallback( id => dispatch( roleOperations.getAcl( 'roleAcl', id ) ) , [ dispatch ]);

    /**
     * Clean resource blrf object
     */
    const clear = useCallback( () => dispatch( roleOperations.clear( 'roleAcl' ) ), [ dispatch ] );

    /**
     * Update role Acl
     */
    const update = useCallback( (id, data) => dispatch( roleOperations.updateAcl( 'roleAcl', id, data) ), [ dispatch ] );

    /**
     * Fetch roleAcl blrf object. If object is not yet available return default blrf object stored in state.
     */
    const roleAclObject = useSelector( state => state.app.blrf.objects.roleAcl || state.app.blrf.defaultObject );

    /**
     * Fetch data and loading from roleAcl object
     */
    const { data, error, loading } = useMemo( () => {
        return {
            data: roleAclObject.data,
            error: roleAclObject.error,
            loading: roleAclObject.loading
        }
    }, [ roleAclObject ]);

    const initialValues = useMemo( () => {
        let initialValues = {};
        data.map( 
            ( mod ) => { 
                console.log(' >>> module: ', mod);
                initialValues[mod.key] = {}; 
                return mod.resources.map( ( res ) => { 
                    initialValues[mod.key][res.key] = {};
                    return res.actions.map( ( action ) => { 
                        initialValues[mod.key][res.key][action.key] = (action.allowed === null ? 'I' : (action.allowed ? 'Y' : 'N'));
                        return [];
                    })
                 })
            });
        return initialValues;
    }, [ data ] );

    /**
     * Call get roleAcl from Api if uid changed
     *
     * And register unmount function that will clear the roleAcl object.
     * This is important because, when we later arrive to this page and
     * role.id has changed child components will still see the old role and
     * we will get double queries. One for old and one for new and data
     * will most likely be incorrect.
     */
    useEffect( () => {
        if (role && role.id > 0) {
            get( role.id );
            return clear;
        }
    }, [ role, get, clear ]);

    const onSubmit = ( values ) => {
        let p = null;
        p = update( role.id, values );
        p.then(ret => {
            if (ret.type.indexOf('error') > 0) {
                openSnackbar('Error while saving resource', 'error');
            } else {
                openSnackbar('Role roleAcl (ACLs) saved', 'success');
                window.history.go( -1 );
            }
        } );
        return p;
    }

    return (
        <Fragment>
            {error === false && loading && <LinearProgress />}
            {error !== false && <ErrorAlert error={error} />}
            {role &&
                <RoleAclForm
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    loading={loading}
                    acls={data} 
                />
            }
        </Fragment>
    );
}

RoleAcl.propTypes = {
    role: PropTypes.object.isRequired,
};

export default RoleAcl;
