import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import ErrorAlert from 'app/components/error';
import LinearProgress from '@material-ui/core/LinearProgress';

import SaveIcon from '@material-ui/icons/Save';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';

import { Fab } from 'app/components/button';

import Accordion from 'app/components/accordion';

import { resourceOperations } from './../../../state/ducks/resource';

import ResourceModule from './module';

import { withAppForm } from 'app/components/form';

const ResourceFormImpl = ({ resource, ...props }) => {

    const panes = useMemo( () => {
        const ret = [];

        for ( let i = 0; i < resource.length; i++ ) {
            const m = resource[i];
            ret.push({
                summary: 
                    <Fragment>
                        <Typography style={{ flexBasis: '33.33%', flexShrink: 0 }} variant='h6'><b>{m.name}</b> module</Typography>
                        <Typography variant='subtitle1'>
                            { m.allowed ? <DoneIcon color='action' /> : <CancelIcon color='error' /> }
                        </Typography>
                    </Fragment>,
                content: <ResourceModule module={m} />
            });
        }

        return ret;
    }, [ resource ] );

    return (
        <Card>
            <CardHeader title='Permissions' />
            <CardContent>
                <Accordion
                    panes={panes} 
                />
            </CardContent>
            <CardActions>
                <Grid container>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Fab
                            type='submit'
                            color='primary'
                            variant='extended'
                            loading={props.loading || props.isSubmitting}
                        >
                            <SaveIcon />
                             Save
                        </Fab>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );

};

const ResourceForm = withAppForm(ResourceFormImpl);

const ResourcePage = ( { role, onSubmit } ) => {

    /**
     * Dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Get role resources from Api
     */
    const get = useCallback( id => dispatch( resourceOperations.get( 'resources', id ) ) , [ dispatch ]);

    /**
     * Clean resource blrf object
     */
    const clear = useCallback( () => dispatch( resourceOperations.clear( 'resources' ) ), [ dispatch ] );

    /**
     * Fetch resources blrf object. If object is not yet available return default blrf object stored in state.
     */
    const resourcesObject = useSelector( state => state.app.blrf.objects.resources || state.app.blrf.defaultObject );

    /**
     * Fetch data and loading from resources object
     */
    const { data, error, loading } = useMemo( () => {
        return {
            data: resourcesObject.data,
            error: resourcesObject.error,
            loading: resourcesObject.loading
        }
    }, [ resourcesObject ]);

    const initialValues = useMemo( () => {
        let initialValues = {};
        data.map( 
            ( mod ) => { 
                initialValues[mod.name] = {}; 
                return mod.resources.map( ( res ) => { 
                    initialValues[mod.name][res.name] = {};
                    return res.operations.map( ( op ) => { 
                        initialValues[mod.name][res.name][op.operation] = (op.allowed === null ? 'I' : (op.allowed ? 'Y' : 'N'));
                        return [];
                    })
                 })
            });
        return initialValues;
    }, [ data ] );

    /**
     * Call get resources from Api if uid changed
     *
     * And register unmount function that will clear the resources object.
     * This is important because, when we later arrive to this page and
     * role.id has changed child components will still see the old role and
     * we will get double queries. One for old and one for new and data
     * will most likely be incorrect.
     */
    useEffect( () => {
        if (role && role.id > 0) {
            get( role.id );
            return clear;
        }
    }, [ role, get, clear ]);

    return (
        <Fragment>
            {error === false && loading && <LinearProgress />}
            {error !== false && <ErrorAlert error={error} />}
            {role &&
                <ResourceForm
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    loading={loading}
                    resource={data} 
                />
            }
        </Fragment>
    );
}

ResourcePage.propTypes = {
    role: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default ResourcePage;
