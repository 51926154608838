import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import { default as MuiTableCell } from '@material-ui/core/TableCell';

const CustomMuiTableCell = withStyles(theme => ({
    root: {
        /*color: 'inherit'*/
    }
}))(MuiTableCell);

const TableCell = ( { content, ...props } ) => <CustomMuiTableCell {...props}>{content}</CustomMuiTableCell>;

export default TableCell;
