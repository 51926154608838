import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

const TableIconButton = withStyles(theme => ({
    root: {
        padding: 5
    }
}))(IconButton);

export default TableIconButton;
