import React, { useMemo } from 'react';

import { isPlainObject, isString } from 'lodash';

import Alert from '@material-ui/lab/Alert';

import Button from './button';

/**
 * Some error objects that are supported
 *
 * error: {
 *     message: string
 * }
 */
const ErrorAlert = ( { error } ) => {

    const message = useMemo( () => {
        if (isString(error)) {
            return error;
        }
        else if (isPlainObject(error) && isString(error.message) ) {

            return error.message;
        }
        else {
            return 'Unknown error (could not find error message in error)';
        }
    }, [ error] );

    return (
        <Alert
            severity='error'
            action={
                <Button
                    color='inherit'
                    size='small'
                    onClick={() => window.history.go(-1) }>
                    Back
                </Button>
            }>
            {message}
        </Alert>
    );
};

export default ErrorAlert;