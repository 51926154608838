import React, { Fragment, useContext, useMemo } from 'react';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Button from 'app/components/button';
import { NumberSpring } from 'app/components/statistic';
import Modal from 'app/components/modal';

import OrderWizardContext from './context';

import OrderUsersTable from './orderUsers/table';
import OrderUsersAdd from './orderUsers/add';
import OrderUsersFill from './orderUsers/fill';

import coinUtils from './../../../../utils';

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={props.value*100} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
            <NumberSpring value={props.value} format={(n) => coinUtils.formatPercent(n)} /> </Typography>
      </Box>
    </Box>
  );
}

/**
 * We have many different options here:
 *
 * MANUAL:
 *
 *   - We can add users with percents that must add up to 100%
 *
 * AUTOMATIC:
 *
 *   - We can add ALL users that have available balance
 *   - We can select certain users and percentage will be calculated
 */
const OrderWizardUsersStep = () => {

    const ctx = useContext( OrderWizardContext );

    const {
        loading,
        nextStep,
        previousStep,
        order,
        fillOrderUsers,
        addOrderUser,
        removeOrderUser
    } = ctx;

    const totalPercentage = useMemo( () => {
        if (order.OrderUsers && order.OrderUsers.length > 0) {
            return order.OrderUsers.reduce(
                (cur, os) => parseFloat(os.percent) + cur, 0
            );
        }
        return 0;
    }, [ order.OrderUsers ]);

    console.log('total percentage: ', totalPercentage);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={ () => fillOrderUsers( order.id ) }>
                    Fill order (all users)
                </Button>
                <Modal
                    buttonComponent={Button}
                    buttonProps={{
                        variant: 'contained',
                        color: 'primary',
                        children: 'Fill order (select users)'
                    }}
                    content={OrderUsersFill}
                    contentProps={{ order, fillOrderUsers }}
                    />
                <Modal
                    buttonComponent={Button}
                    buttonProps={{
                        variant: 'contained',
                        color: 'primary',
                        children: 'Add user'
                    }}
                    content={OrderUsersAdd}
                    contentProps={{ order, addOrderUser }}
                    />
            </Grid>
            <Grid item xs={12}>
                <Card variant='outlined'>
                    <CardHeader
                        title='Users' />
                    <CardContent>
                        <LinearProgressWithLabel value={totalPercentage} />
                        <OrderUsersTable
                            order={order}
                            removeOrderUser={removeOrderUser} />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant='outlined'
                    loading={loading}
                    onClick={previousStep}>
                    Back
                </Button>
                <Button
                    variant='outlined'
                    type='submit'
                    color='primary'
                    disabled={totalPercentage.toPrecision(10) !== '1.000000000'}
                    loading={loading}
                    onClick={nextStep}
                >
                    Next
                </Button>
            </Grid>
        </Grid>
    );

}

export default OrderWizardUsersStep;