import { blrfOperations } from 'app/state/ducks/blrf';

const { clear } = blrfOperations;

const get = ( key, id ) => async ( dispatch ) => {

    return await dispatch ( blrfOperations.get( {
        endpoint: 'admin/pm/process',
        key,
        id
    }) );
    
};

const update = ( key, id, data ) => async ( dispatch ) => {

    return await dispatch ( blrfOperations.update( {
        endpoint: `admin/pm/process/${id}`,
        key,
        data
    }) );

};

const create = ( key, data ) => async ( dispatch ) => {

    return await dispatch ( blrfOperations.create( {
        endpoint: 'admin/pm/process',
        key,
        data
    }) );

};

const search = ( key, data ) => ( dispatch ) => {

    return dispatch ( blrfOperations.search( {
        endpoint: 'admin/pm/process/search',
        key, 
        data
    }) );

};

const remove = ( key, id ) => async ( dispatch ) => {

    return await dispatch ( blrfOperations.remove( {
        endpoint: `admin/pm/process/${id}`,
        key
    }) );

};

export {
    get, 
    update, 
    create,
    search,
    remove,
    clear
};
