import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import * as Yup from 'yup';

import { openSnackbar } from 'app/components/snackbar';

import { roleOperations } from './../../state/ducks/role';

import RoleAcl from './role/acl';

import ViewEdit from 'app/components/viewedit';

import Grid from '@material-ui/core/Grid';
import ErrorAlert from 'app/components/error';
import LinearProgress from '@material-ui/core/LinearProgress';

import utils from 'app/utils';

const roleValidation = Yup.object().shape({
    name: Yup.string()
            .min(2, 'Name is too short')
            .max(50, 'Name is too long')
            .required('Name is required'),
    description: Yup.string()
            .max(100, 'Description is too long')
});

const emptyRole = {
    id: '',
    name: '',
    description: '', 
    created_at: '',
    created_by: '',
    updated_at: '',
    updated_by: ''
};

/**
 * Extract action and rid from react-router match prop
 */
const RolePage = ( { match: { params: { action, rid } } } ) => {

    /**
     * Are we editing
     */
    const edit = (action === 'add' || action === 'edit');

    /**
     * Dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Get role from Api
     */
    const get = useCallback( id => dispatch( roleOperations.get( 'role', id ) ) , [ dispatch ]);
    /**
     * Update role via Api
     */
    const update = useCallback( ( id, data ) => dispatch( roleOperations.update( 'role', id, data ) ), [ dispatch ] );

    /**
     * Create new role via Api
     */
    const create = useCallback( data => dispatch( roleOperations.create( 'role', data ) ) , [ dispatch ] );
    /**
     * Clean role blrf object
     */
    const clear = useCallback( () => dispatch( roleOperations.clear( 'role' ) ), [ dispatch ] );

    /**
     * Fetch role blrf object. If object is not yet available return default blrf object stored in state.
     */
    const roleObject = useSelector( state => state.app.blrf.objects.role || state.app.blrf.defaultObject );

    /**
     * Get role from blrf object data
     */
    const role = useMemo( () => {
        return ( action === 'add' ? emptyRole : roleObject.data.find(v => v) || emptyRole)
    }, [ roleObject.data, action ]);


    /**
     * Get loading and error from blrf role object
     */
    const { loading, error } = roleObject;

    /**
     * Call get role from Api if rid changed
     *
     * And register unmount function that will clear the role object.
     * This is important because, when we later arrive to this page and
     * rid has changed child components will still see the old role and
     * we will get double queries. One for old and one for new and data
     * will most likely be incorrect.
     */
    useEffect( () => {
        if (rid > 0) {
            get( rid );
        }
        return clear;
    }, [ rid, get, clear ]);

    const fields = [
            {
                label: 'Id',
                name: 'id',
                disabled: true,
                value: role.id,
                width: 1
            },
            {
                label: 'Name',
                name: 'name',
                width: 4,
                value: role.name,
            },
            {
                label: 'Description',
                name: 'description',
                width: 7,
                value: role.description,
            },
            {
                label: 'Created at',
                name: 'created_at',
                value: role.created_at,
                width: 3,
                edit: false
            },
            {
                label: 'Created by',
                name: 'created_by',
                value: role.created_by,
                width: 3,
                edit: false,
            },
            {
                label: 'Updated at',
                name: 'updated_at',
                value: role.updated_at,
                width: 3,
                edit: false,
            },
            {
                label: 'Updated by',
                name: 'updated_by',
                value: role.updated_by,
                width: 3,
                edit: false
            }
        ];

    const onSubmit = ( values ) => {
        let p = null;
        if (action === 'add') {
            p = create(values);
        } else {
            p = update( values.id, values );
        }
        p.then(ret => {
            if (ret.type.indexOf('error') > 0) {
                openSnackbar('Error while saving role', 'error');
            } else {
                openSnackbar('Role saved', 'success');
                window.history.go( -1 );
            }
        } );
        return p;
    }

    return (
        <Fragment>
            {error === false && loading && <LinearProgress />}
            {error !== false && <ErrorAlert error={error} />}
            {role &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ViewEdit
                            header={{ title: (role && role.id > 0 ? (edit ? 'Edit role' : 'View role') : 'Add role') }}
                            onSubmit={onSubmit}
                            fields={fields}
                            validationSchema={roleValidation}
                            loading={loading}
                            edit={edit}
                            editButton={{ component: Link, to: utils.getFullPath(`admin/roles/edit/${role.id}`) }}
                            />
                    </Grid>
                    <Grid item xs={12}>
                        { edit === false &&
                            <RoleAcl role={role} /> }
                    </Grid>
                </Grid>
            }
        </Fragment>
    );
}

RolePage.propTypes = {
    match: PropTypes.object.isRequired
};

export default RolePage;
