import React from 'react';
import { connect } from 'react-redux'

import { themeOperations } from './../../../state/ducks/theme';

import IconButton from '@material-ui/core/IconButton';

import LightbulbFull from './../../../components/icons/LightbulbFull';
import LightbulbOutline from './../../../components/icons/LightbulbOutline';

const AppNavbarTheme = ( { type, switchType } ) => {

    return (
        <IconButton
            color='inherit'
            onClick={ () => switchType() }>
            { type === 'light' ? <LightbulbFull /> : <LightbulbOutline /> }
        </IconButton>
    );

};

const mapStateToProps = ( state, ownProps ) => {
    return {
        type: state.app.theme.palette.type
    };
};

const mapDispatchToProps = {
    switchType: ( id ) => themeOperations.switchType(),
};

export default connect(mapStateToProps, mapDispatchToProps)(AppNavbarTheme);
