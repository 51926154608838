import { default as types } from './types';
import { createReducer } from './../../utils';
import invariant from 'invariant';

export const blrfObject = {
    /**
     * Object key
     */
    key: null,
    /**
     * Object props assigned at action start
     */
    props: {},

    /**
     * Latest query for this data
     */
    query: {
        refs: 0, 
        page: 1
    },

    /**
     * Meta information about data
     */
    meta: null,

    /**
     * Data
     */
    data: [],

    /**
     * Did we receive any messages
     * @note Not really working, we should somehow push (dispatch) and clear messages when received
     */
    messages: [],

    /**
     * Are we performing operation at the moment
     */
    loading: false,

    /**
     * Error
     */
    error: false
};

/**
 * Create new object
 */
const createBlrf = ( key, data ) => {
    return { ...blrfObject, ...data, key }
}


const updateBlrf = ( object, data ) => (
    { ...object, ...data }
);

/**
 * Register new object in state
 */ 
const updateState = ( state, object ) => {
    const key = object.key;
    if (typeof key !== 'string') {
        invariant(false, 'updateState: key is not a string. Maybe you forgot to create BLRF object: ' + (typeof key) );
        return state;
    }
    invariant(object.error !== undefined, 'updateState: Object error is undefined (did you forgot to set error key?): ' + object);
    return {
        ...state, 
        objects: { ...state.objects, [ key ]: object }
    };
};

const actionsMap = {

    [ types.CLEAR ] : ( state, { payload: { key } } ) => (
        updateState ( state, createBlrf ( key, { loading: false } ))
    ),

    [ types.GET ] : ( state, { payload: { api: { key, props = {} } } } ) => (
        (state.objects[key] 
            ?
                updateState( state, updateBlrf( state.objects[key], { loading: true, error: false } ) )
            :   updateState( state, createBlrf ( key, { props, loading: true, error: false } ) )
        )
    ),

    [ types.GET_OK ] : ( state, { payload: { data, messages = [], meta = {}, api: { key, props = {} } } } ) => (
        updateState ( state, updateBlrf ( state.objects[key], { data, messages, loading: false } ) )
    ),

    [ types.GET_ERROR ] : ( state, { payload: { api: { key }, error } } ) => (
        updateState ( state, updateBlrf ( state.objects[key], { loading: false, error } ) )
    ),

    [ types.CREATE ] : ( state, { payload: { api: { key, props = {} } } } ) => (
        updateState ( state, createBlrf( key, { props, loading: true, error: false } ) )
    ),

    [ types.CREATE_OK ] : ( state, { payload: { data, messages = [], meta = {}, api: { key, props = {} } } } ) => (
        updateState ( state, updateBlrf ( state.objects[key], { data, messages, loading: false, error: false } ) )
    ),

    [ types.CREATE_ERROR ] : ( state, { payload: { api: { key }, error } } ) => (
        updateState ( state, updateBlrf ( state.objects[key], { loading: false, error } ) )
    ),

    /**
     * Same as clear. It clears the object from state
     */
    [ types.REMOVE_OK ]: ( state, { payload: { api: { key } } } ) => (
        updateState( state, createBlrf( key, { loading: false } ) )
    ),

    [ types.SEARCH ] : ( state, { payload: { api: { key, props = {}, data = {} } } } ) => (
        (state.objects[key]
            ? 
                updateState( state, updateBlrf( state.objects[key], { props, query: data, error: false, loading: true } ) )
            :
                updateState ( state, createBlrf ( key, { props, query: data, loading: true, error: false } ) )
        )
    ),

    [ types.SEARCH_OK ] : ( state, { payload: { data, meta = {}, api: { key, props = {} } } } ) => (
        updateState ( state, updateBlrf ( state.objects[key], { data, meta, loading: false, error: false } ) )
    ),

    [ types.SEARCH_ERROR ]: ( state, { payload: { error, api: { key, props = {} } } } ) => (
        updateState( state, updateBlrf( state.objects[key], { error, data: [], meta: null, loading: false } ) )
    ),

    [ types.UPDATE ] : ( state, { payload: { api: { key, props = {} } } } ) => (
        (state.objects[key]
            ?
                updateState ( state, updateBlrf( state.objects[key], { props, loading: true } ) )
            :
                updateState( state, createBlrf( key, { props, loading: true }  ) )
        )
    ),

    [ types.UPDATE_OK ] : ( state, { payload: { data, meta = null, api: { key, props = {} } } } ) => (
        updateState ( state, updateBlrf ( state.objects[key], { data, meta, loading: false } ) )
    ),

    [ types.UPDATE_ERROR ]: ( state, { payload: { error, api: { key, props = {} } } } ) => (
        updateState( state, updateBlrf( state.objects[key], { error, loading: false } ) )
    ),

    /**
     * Update single object within blrf object data array. Match by provided prop
     */
    [ types.UPDATE_SINGLE ] : ( state, { payload: { key, data, prop } } ) => {
        let all_keys = [];
        if (Array.isArray(key)) {
            all_keys = key;
        } else {
            all_keys.push(key);
        }

        all_keys.forEach(k => {
            if (state.objects[k]) {
                /**
                 * Now we need to find objects with data[prop] == is same and change them
                 *
                 * @todo Think about how to change a single array entry in data without affecting
                 *       whole data array.
                 */
                let modified = false;
                let new_data = state.objects[k].data.map( d => {
                    if (d[prop] && data[prop] && d[prop] === data[prop]) {
                        modified = true;
                        return { ...d, ...data };
                    } else {
                        return d;
                    }
                });
                if (modified) {
                    state = updateState( state, updateBlrf( state.objects[k], { data: new_data } ) );
                }
            }
        });
        return state;
    },
};

const initialState = {
    /**
     * Our BLRF Objects
     */
    objects: {},
    /**
     * When object is not yet available use this. It's an empty object.
     */
    defaultObject: blrfObject
}

export default createReducer( 
    initialState
 ) ( actionsMap );
