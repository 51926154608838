import React, { Fragment, useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { balanceOperations } from './../../state/ducks/balance';
import { exchangeComponents } from './../../state/ducks/exchange';
import { currencyComponents } from './../../state/ducks/currency';
import { userComponents } from './../../state/ducks/user';

import { Link } from 'react-router-dom';

import DescriptionIcon from '@material-ui/icons/Description';

import { Table } from 'app/components';

import utils from 'app/utils';
import coinUtils from '../../utils';

const BalancePage = () => {

    /**
     * Take dispatch from react-redux
     */
    const dispatch = useDispatch();

    /**
     * Query balance via Api
     */
    const search = useCallback( query => dispatch( balanceOperations.search( 'balance', query ) ), [ dispatch ] );

    /**
     * balance blrf object
     */
    const balanceObject = useSelector( state => state.app.blrf.objects.balance || state.app.blrf.defaultObject );

    const { data, meta } = useMemo( () => {
        return {
            data: balanceObject.data,
            meta: balanceObject.meta
        }
    }, [ balanceObject ]);

    const query = useMemo( () => {
        /**
         * When object is first created query will only contain { refs: 0, page: 1 }
         *
         * We want refs, so we change that. And we want to have default balance.
         * All other aspects of query object is controlled via
         * Table component.
         */
         const hasOrder = Array.isArray(balanceObject.query.balance) && balanceObject.query.balance.length > 0;
         if (balanceObject.query.refs === 0 || !hasOrder) {
            return { ...balanceObject.query, refs: ['User', 'Exchange', 'Currency'], balance: (hasOrder ? [...balanceObject.query.balance ] : ['amount DESC'] ) };
         }
         return balanceObject.query;
    }, [ balanceObject.query ] );

    const {
        loading,
        error
    } = balanceObject;

    const fields = [
        {
            label: 'Exchange',
            name: 'exchange_id',
            width: 2,
            component: exchangeComponents.exchangesSelectField,
            select: {
                blrfKey: 'b_exchange_id_select'
            },
            displayed: false
        },
        {
            label: 'Currency',
            name: 'currency_id',
            width: 2,
            component: currencyComponents.currenciesSelectField,
            select: {
                blrfKey: 'b_currency_id_select'
            }
        },
        {
            label: 'User',
            name: 'user_id',
            width: 2,
            component: userComponents.usersSelectField,
            select: {
                blrfKey: 'b_user_id_select'
            },
            displayed: false
        },
        {
            label: 'Amount',
            name: 'amount',
            width: 2,
            type: 'number',
            searchable: false
        },
        {
            label: 'Eur',
            name: 'amount_eur',
            searchable: false
        },
        {
            label: 'Change (24hrs)',
            name: 'rate_eur_change_24h'
        }
    ];

    const EUR = {
        sid: 'EUR',
        decimals: 2
    }

    const renderRow = ({ Currency, amount, amount_eur, rate_eur_change_24h }, i) => ({
        key: i,
        cells: [
            Currency.name,
            coinUtils.formatCurrency(Currency,amount),
            coinUtils.formatCurrency(EUR, amount_eur),
            coinUtils.formatPercent(rate_eur_change_24h)
        ]
    });

    const actions = [
        {
            icon: <DescriptionIcon />,
            component: Link,
            to: utils.getFullPath('coin/balance/currency/:Currency.id')
        }
    ];

    return(
        <Table
            fields={fields}
            actions={actions}
            renderRow={renderRow}
            loading={loading}
            search={search}
            query={query}
            data={data}
            meta={meta}
            error={error}
        />
    );
};

export default BalancePage;
