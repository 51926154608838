import { default as moduleActions } from 'app/state/ducks/module/types';
import { default as authActions } from 'app/state/ducks/auth/types';

import { pmWsOperations } from './../../ducks/pm/ws';

const getWsUrl = ( state ) => {
    const config  = state.app.module.all.filter( m => m.name === 'admin' ).find( m => m ).config;

    if ( config.pm && config.pm.ws ) {
        return config.pm.ws;
    } else {
        /*console.warn('Admin module is missing config pm.ws');*/
        return undefined;
    }
}

const dispatchConnect = ( store ) => {
    store.dispatch(
        pmWsOperations.connect( getWsUrl( store.getState() ) )
    );
}

/**
 * First action we're going to receive is app/module/init
 *
 * We need to react to several app messages.
 *
 * When application is initially loaded, user may already be authenticated.
 * so moduleActions.INIT is a good starting point to check if we may connect.
 *
 * But we do need to check if state.app.auth.user is set and maybe even if 
 * state.app.auth.user.access.Module is set (if it has access).
 *
 * Another action to check is authActions.LOGIN_DONE - user has logged in or changed the
 * access token. Received when authentication changes to authenticated.
 *
 * We also have to check if user has access. We should probably authenticate again
 * to the WS server.
 *
 * Another action to check is authActions.LOGOUT_OK. We should close the connection.
 * We cannot disconnect on logout. WS socket doesn't do anything. BLRF WS Server
 * will disconnect us when access token is not found in db.
 * 
 */
const pmWsMiddleware = ( store ) => ( next ) => async ( action ) => {


    const pmWs = getWsUrl(store.getState());

    /**
     * ModuleInit was received. User may already be authenticated.
     */
    if (pmWs && (action.type === moduleActions.INIT || action.type === authActions.LOGIN_DONE)) {
        const state = store.getState();
        const user = state.app && state.app.auth && state.app.auth.user ? state.app.auth.user : null;
        const access = user && user.access ? user.access : null;
        if (access && access.admin) {
            dispatchConnect( store );
        }
    }
    return next( action );
};

export default pmWsMiddleware;
