import React, { Fragment } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from './../button';

import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Alert from '@material-ui/lab/Alert';

import CreateIcon from '@material-ui/icons/Create';
import UndoIcon from '@material-ui/icons/Undo';
import HistoryIcon from '@material-ui/icons/History';
import SaveIcon from '@material-ui/icons/Save';

import { withAppForm, Field, TextField, checkboxField, switchField, selectField, StaticField } from './../form';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5)
    },
  },
}));

const ViewEditForm = (
    {
        edit = false,
        loading = false,
        header = null,
        fields,
        saveButton = null,
        resetButton = null,
        editButton = null,
        backButton = null,
        addlButtons = [],
        /**
         * You can set this to outlined
         */
        variant = undefined,
        ...props
    }
) => {

    const classes = useStyles();

    const getFieldComponent = ( field ) => {
        if ( edit === false || field.edit === false ) {
            return StaticField;
        }
        if ( field.component ) {
            return field.component;
        }
        if ( field.checkbox ) {
            return checkboxField;
        }
        if ( field.switchConfig) {
            return switchField;
        }
        if ( field.select ) {
            return selectField;
        }
        return TextField;
    };

    const renderHeaderField = ( { header, ...rest } ) => {
        return (<Fragment><CardHeader subheader={header} {...rest}/><Divider light/></Fragment>);
    };

    const getGridWidth = (width) => {
        if (!isNaN(parseFloat(width)) && isFinite(width)) {
            // it's numeric
            return {
                xs: 12,
                md: (width * 2 > 12 ? 12 : width * 2),
                lg: width
            };
        } else {
            return width; // should be object
        }
    };

    const renderField = ( { width = 2, sortable = true, ...field} ) => (
        field.header ?
            <Grid item key={field.header} xs={12}>
                {renderHeaderField(field)}
            </Grid>
        :
            <Grid item key={field.name} {...getGridWidth(width)}>
                {edit === false || field.edit === false ? <StaticField field={field} {...field} loading={loading} />:
                <Field
                    id={field.id ? field.id : field.name}
                    component={getFieldComponent(field)}
                    loading={loading}
                    {...field}
                    />
                }
            </Grid>
    );

    const getSaveButton = ( ) => {
        const { isSubmitting, isValid } = props;
        if (props.onSubmit === undefined) {
            return null;
        }
        const defaultButton = {
            key: 'submit_button',
            type: 'submit',
            content: 'Save',
            color: 'primary',
            startIcon: <SaveIcon />,
            disabled: !isValid,
            loading: isSubmitting || loading
        };
        if (saveButton === false) {
            return null;
        } else if (saveButton === null) {
            return defaultButton;
        } else if (typeof saveButton === 'string') {
            return { ...defaultButton, content: saveButton };
        } else {
            return { ...defaultButton, ...saveButton };
        }
    }

    const getResetButton = ( ) => {
        const { isSubmitting } = props;
        const defaultButton = {
            key: 'reset_button',
            type: 'reset',
            content: 'Reset',
            startIcon: <UndoIcon />,
            loading: isSubmitting || loading

        };
        if (resetButton === false) {
            return null;
        } else if (resetButton === null) {
            return defaultButton;
        } else if (typeof resetButton === 'string') {
            return { ...defaultButton, content: resetButton };
        } else {
            return { ...defaultButton, ...resetButton };
        }
    }

    const getBackButton = ( ) => {
        const { isSubmitting } = props;
        const defaultButton = {
            key: 'back_button',
            type: 'button',
            content: 'Back',
            startIcon: <HistoryIcon />,
            onClick: () => window.history.go(-1),
            loading: isSubmitting || loading
        };
        if (backButton === false) {
            return null;
        } else if (backButton === null) {
            return defaultButton;
        } else if (typeof backButton === 'string') {
            return { ...defaultButton, content: backButton };
        } else {
            return { ...defaultButton, ...backButton };
        }
    }

    const getEditButton = ( ) => {
        const defaultButton = {
            key: 'edit_button',
            type: 'button',
            content: 'Edit',
            startIcon: <CreateIcon />,
            loading: loading
        };
        if (editButton === null) {
            return null;
        } else if (typeof editButton === 'string') {
            return { ...defaultButton, content: editButton };
        } else {
            return { ...defaultButton, ...editButton };
        }
    }

    const renderButton = ( { content, icon, ...props } ) => (
        <Button {...props}>
            {icon}
            <Hidden xsDown>
                {content}
            </Hidden>
        </Button>
    );

    const renderButtons = ( ) => {
        const buttons = addlButtons.slice();
        if ( edit ) {
            const _s = getSaveButton();
            if (_s) {
                buttons.push(_s);
            }
            const _b = getBackButton();
            if (_b) {
                buttons.push(getBackButton());
            }
            const _r = getResetButton();
            if (_r) {
                buttons.push(getResetButton());
            }
        } else {
            const _b = getBackButton();
            const _e = getEditButton();
            if (_b) {
                buttons.push(_b);
            }
            if (_e) {
                buttons.push(_e);
            }
        }

        return (
            <Grid container>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }} className={classes.root}>
                    {buttons.map(renderButton)}
                </Grid>
            </Grid>
        );
    };

    const renderStatus = ( ) => (
        props.status && <CardContent><Alert severity='error'>{props.status.message}</Alert></CardContent>
    )

    const renderHeader = () => (
        header && <Fragment><CardHeader {...header}/><Divider light/></Fragment>
    )

    return (
        <Card variant={variant}>
            { renderHeader() }
            <CardContent style={{ paddingTop: 0 }}>
                <Grid container spacing={2}>
                    { fields.map(renderField) }
                </Grid>
            </CardContent>
            <Divider variant='inset' />
            <CardActions style={{ padding: 0}}>
                { renderButtons() }
            </CardActions>
            { renderStatus() }
        </Card>
    );
};

export default withAppForm(ViewEditForm);