import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';

import PersonIcon from '@material-ui/icons/Person'
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import AppNavbarLink from './link';
import utils from './../../../utils';

export default class AppNavbarUser extends Component {

    static propTypes = {
        user: PropTypes.object,
        authenticating: PropTypes.bool.isRequired,
        logout: PropTypes.func.isRequired
    };

    static defaultProps = {
        user: null
    };

    state = {
        anchorEl: null
    };

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    logout = () => {
        const { logout } = this.props;
        this.handleClose();
        logout();
    };

    render() {
        const { user, authenticating } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return(
            <Fragment>
                <Tooltip
                    title={user ? user.name : ''}>
                    <IconButton
                        disabled={user === null}
                        aria-owns={open ? 'menu-user' : null}
                        aria-haspopup="true"
                        onClick={this.handleMenu}
                        color="inherit"
                    >
                        <AccountCircle color={ authenticating ? 'disabled' : 'inherit' } />
                    </IconButton>
                </Tooltip>
                <Menu
                    id="menu-user"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={this.handleClose}
                >
                    <MenuItem component={AppNavbarLink} to={utils.getFullPath('profile')} onClick={this.handleClose}>
                        <ListItemIcon>
                            <PersonIcon />
                        </ListItemIcon>
                        <ListItemText inset primary='Profile' />
                    </MenuItem>
                    <MenuItem onClick={this.logout}>
                        <ListItemIcon>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText inset primary='Logout' />
                    </MenuItem>
                </Menu>
            </Fragment>
        );
    }
}
