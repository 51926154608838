import { default as types } from './types';
import config from './../../../../config';
import { createReducer } from './../../utils';
import * as utils from './utils';

const actionsMap = {

    /**
     * Tap into connected-react-router to detect current module
     */
    '@@router/LOCATION_CHANGE' : ( state, { type, payload } ) => {
        /**
         * connected-react-router seem to send different payload.
         */
        const pathname = payload.location.location ? payload.location.location.pathname : payload.location.pathname;

        return {
            ...state,
            current: utils.detectCurrentModule(state.all, pathname),
            path: pathname
        };
    },

    /**
     * Load all modules
     */
    [ types.LOAD ] : ( state ) => {
        if (state.all === null) {
            const modules = utils.loadModules(config.modules);
            return {
                ...state,
                initializing: true,
                all: modules,
                current: utils.detectCurrentModule(modules, state.path),
            };
        }
        return state;
    },

    [ types.INIT ] : ( state ) => {
        return {
            ...state,
            initializing: false,
        };
    }

};

const initialState = {
    /**
     * Are we initializing?
     * This is true untill module reducers are all loaded.
     */
    initializing: true,
    /**
     * All modules loaded
     */
    all: null,
    /**
     * Currently detected module
     */
    current: null,
    /**
     * Current path (uri)
     */
    path: null,
};

export default createReducer( initialState ) ( actionsMap );