import React, { Fragment, useState, useMemo } from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import TableIconButton from './iconbutton';

import Confirm from './../confirm';

import utils from './../../utils';

const TableAction = ( { onClick, data, to, icon, tooltip = '', disabled = false, confirm, ...props } ) => {

    const [ open, setOpen ] = useState(false);

    return useMemo( () => {

        const handleShow = () => setOpen( true );
        const handleCancel = () => setOpen( false );
        const handleConfirm = () => {
            setOpen( false );
            onClick(data);
        };
        const onActionClick = () => onClick(data);

        let myIcon = icon;
        let myDisabled = disabled;
        if (typeof icon === 'function') {
            myIcon = icon ( data );
        }

        if (typeof disabled === 'function') {
            myDisabled = disabled ( data );
        }

        if (confirm) {
            const {
                content = 'Are you sure?', 
                cancelButton = 'No',
                confirmButton = 'Yes',
                ...restConfirm
            } = confirm;
    
            return (
                <Fragment>
                    <Tooltip title={tooltip}>
                        <TableIconButton key='btn' onClick={handleShow} disabled={myDisabled} {...props}>{myIcon}</TableIconButton>
                    </Tooltip>
                    <Confirm key='confirm' 
                        open={open}
                        onClose={handleCancel}
                        onCancel={handleCancel}
                        onConfirm={handleConfirm}
                        content={utils.parseObjectProperties(content, data)}
                        cancelButton={cancelButton}
                        confirmButton={confirmButton}
                        {...restConfirm}
                        />
                </Fragment>
            );
        }


        return (
            <Tooltip title={tooltip}>
                <TableIconButton
                    onClick={onClick ? onActionClick : null}
                    to={to ? utils.parseObjectProperties(to, data) : null}
                    disabled={myDisabled}
                    {...props}>{myIcon}</TableIconButton>
            </Tooltip>
        );

    }, [ confirm, onClick, data, to, icon, tooltip, disabled, props, open ] );
}

export default TableAction;