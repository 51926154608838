import React, { createContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

const OrderWizardContext = createContext();

const OrderWizardContextProvider = ( { order, get, update, create, addUser, removeUser, fillUsers, process, unprocess, children, history, step } ) => {

    /**
     * Redux dispatch for BLRF operations
     */
    const dispatch = useDispatch();

    /**
     * Are we loading anything ?
     */
    const loading = useSelector( state => state.app.loading > 0 );

    /**
     * Move to next step
     */
    const nextStep = () => {
        const nextStep = step + 1;
        history.push(`#${nextStep}`);
    };

    /**
     * Move to previous step
     */
    const previousStep = () => {
        if (step > 0) {
            const nextStep = step - 1;
            history.push(`#${nextStep}`);
        }
    }

    return (
        <OrderWizardContext.Provider
            value={{
                /**
                 * Basic
                 */
                dispatch,
                loading,
                /**
                 * Steps
                 */
                step,
                nextStep,
                previousStep,
                /**
                 * Order
                 */
                order,
                getOrder: get,
                createOrder: create,
                updateOrder: update,
                addOrderUser: addUser,
                removeOrderUser: removeUser,
                fillOrderUsers: fillUsers,
                processOrder: process,
                unprocessOrder: unprocess
            }}>
            {children}
        </OrderWizardContext.Provider>
    );
}

OrderWizardContextProvider.propTypes = {
    order: PropTypes.object.isRequired,
    get: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    create: PropTypes.func.isRequired,
    addUser: PropTypes.func.isRequired,
    fillUsers: PropTypes.func.isRequired,
    process: PropTypes.func.isRequired,
    unprocess: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    history: PropTypes.object.isRequired,
    step: PropTypes.number.isRequired
};

export default OrderWizardContext;

export {
    OrderWizardContextProvider   
}
